import './checkIn.css';
import { Whisper, Popover, Dropdown, Loader, IconButton } from 'rsuite';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment'
import { ModalConcluirCheckIn } from '../../components/modalConcluirCheckIn';
import DocumentView from '../../components/documentView';
import { IoDocumentOutline } from "react-icons/io5";
import MoreIcon from '@rsuite/icons/More';
import { useAxios } from '../../utils/api/api';


const CheCkInScreen = () => {
    const userAuth = useSelector(state => state.auth)
    const api = useAxios()
    const[documentos, setDocumentos] = useState([])
    const[open, setOpen] = useState(false)
    const triggerRef = React.useRef();
    const close = () => triggerRef.current.close();
    const[loading, setLoading] = useState(false)
    const[selectDocument, setSelectDocument] = useState(null)
    const[showDocument, setShowDocument] = useState(false)
    const[documentId, setDocumentId] = useState({
        id: null,
        title: null,
        name: null
    })


    useEffect(() => {
        handleGetShared()
    },[])


    const handleOpenDocument = (rowData) => {
        setDocumentId({
            id: rowData?.documento?.id,
            title: rowData?.documento?.titulo,
            name: rowData?.documento?.codigo
        })
        setShowDocument(true)
        
    }


    const handleGetShared = async () => {
        setLoading(true)
        try {
            const response = await api.get(`/api/v1/documento/checkin/?user_id=${userAuth.userId}`)
            setDocumentos(response.data)
            setLoading(false)
        }catch(error){
            console.log(error)
            setLoading(false)
        }
    }


    const handleDelete = async (id) => {
        setLoading(true)
        try {
            const response = await api.delete(`/api/v1/documento/checkin/${id}/`)
            handleGetShared()
            setLoading(false)
        }catch(error){
            console.log(error)
            setLoading(false)
        }
    }





    const MenuPopover = React.forwardRef(({ data, onClose, onSelect, ...rest }, ref) => (
        <Popover ref={ref} {...rest} full>
          <Dropdown.Menu onSelect={onSelect}>
            <Dropdown.Item eventKey="1" onClick={()=>{onClose();setSelectDocument(data); setOpen(true)}}>
              Concluir Check-In
            </Dropdown.Item>

            <Dropdown.Item eventKey="2" onClick={()=>{onClose();handleDelete(data.id)}}>
              Cancelar Check-In
            </Dropdown.Item>

            <Dropdown.Item eventKey="3" onClick={()=>{onClose();handleOpenDocument(data)}}>
              Abrir Documento
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
    ));


    useEffect(() => {
        const handleScroll = (event) => {
            const stickyHeader = document.querySelector('.input-table-folder-01');
            const stickyList = document.querySelector('.list-document-titles.shared');
            const headerRect = stickyHeader.getBoundingClientRect();
            if (headerRect.top < 0){
              stickyList.classList.add('sticky-top');
            }else{
              stickyList.classList.remove('sticky-top')
            }
    
        };
    
        const scrollableElements = document.querySelectorAll('.layout-main');
    
        scrollableElements.forEach(element => {
            element.addEventListener('scroll', handleScroll);
        });
    
        return () => {
            scrollableElements.forEach(element => {
                element.removeEventListener('scroll', handleScroll);
            });
        };
    }, []);

    



  

    


    return(
        <div>
            <div className="folder-row-2 compartilhados-container aprovacao" id="my-first-step-04">
            <div className="containter-documents-compartilhados">
            
            {loading ? (
                <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    marginTop: "10%",
                }}
                >
                <Loader size="sm" content='loading...' />
                </div>
            ) : documentos?.length > 0 ? (
                
                <div className="containter-table-documents">
                    <div className="list-document-titles shared">
                    <div className="list-document-title-item codigo">DOCUMENTO</div>
                    <div className="list-document-title-item">AUTOR</div>
                    <div className="list-document-title-item">DATA DO CHECK-IN</div>
                    <div className="list-document-title-item">VERSÃO</div>
                    <div className="list-document-title-item">...</div>
                </div>
                <ul className="list-documents">
                    {documentos.map((document) => (
                    <li className="list-documents-data">
                        <div className='list-documentos-content shared'>
                        <div className="list-documents-item custom">
                            <IoDocumentOutline className="list-documents-icon" style={{marginRight: 10}}/>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                            <span className="list-documents-title" style={{color: '#000'}}>{document?.documento?.titulo}</span>
                            <span className="list-documents-title" style={{fontSize: 11}}>{document.documento?.codigo}</span>
                            </div>
                        </div>
                        
                        <div className="list-documents-item shared">
                            <span className="list-documents-title">{document?.documento?.autor?.first_name} {document?.documento?.autor?.last_name}</span>
                        </div>
                        <div className="list-documents-item shared">
                            <span className="list-documents-title">{moment(document?.data_checkin).format('L')}</span>
                        </div>
                        <div className="list-documents-item shared">
                            <span className="list-documents-title">{document?.documento?.versao}</span>
                        </div>

                        <div className="list-documents-item shared">
                        <Whisper placement="bottomEnd" trigger="click" ref={triggerRef} speaker={<MenuPopover data={document}  onClose={close}/>}>
                            <IconButton style={{backgroundColor: '#00664e', marginLeft: 5}} icon={<MoreIcon style={{color: '#fff'}}/>} id="btn-filter" appearance='primary'></IconButton> 
                        </Whisper>
                        </div>
                        
                        </div>
                    </li>
                    
                    ))}
                </ul>
                </div>
                
            ): (
                <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}
                >
                <span>Nenhum documento encontrado</span>
                </div>
            
            )}
            </div>
            </div>
            <DocumentView open={showDocument} setOpen={setShowDocument} id={documentId.id} documentName={documentId.name} documentTitle={documentId.title}/>
            <ModalConcluirCheckIn setShowModal={setOpen} showModal={open} checkInData={selectDocument} getDocuments={handleGetShared} setSelectDocument={setSelectDocument}/>
        </div>

    )
}
export default CheCkInScreen;