
import React, { useEffect, useState, useMemo, forwardRef, useImperativeHandle } from 'react';
import './vizualizador.css';
import { DocumentEditor } from "@onlyoffice/document-editor-react";
import { useSelector } from 'react-redux';
import { ONLYOFFICE_URL } from '../../utils/api/base';

const VisualizadorComponent = ({documento}) => {
    const userAuth = useSelector(state => state.auth);
    const [refreshKey, setRefreshKey] = useState(0); 
    const [documentData, setDocumentData] = useState({
        id: null,
        link: null,
        title: null,
        link: null,
        fileType: null,
        tipoVisualizador: null,
    });
    
    function getFileExtension(url) {
        if (!url) return null;
        const parts = url.split('.');
        if (parts.length === 1) return null;
        return parts.pop().split('?')[0];
    }


    const renderFileTipe = (fileType) => {
        if (fileType === 'xlsx' || fileType === 'xls'){
            return "cell";
        }else if (fileType === 'DOCX' || fileType === 'docx'){
            return "word";
            
        }else if (fileType === 'PPTX' || fileType === 'pptx'){
            return "slide";
        }else if (fileType === 'PDF ' || fileType === 'pdf'){
            return "pdf";
        }else if (fileType === 'DOC' || fileType === 'doc'){
            return "word";
        }
    
    }


    const onRequestHistory = () => {
        const editorInstance = window.DocEditor.instances.docxEditor;
        const currentVersion = parseInt(documento.active_version.versao)

        const sortedHistory = documento.document_history.sort((a, b) => {
            return parseInt(a.versao, 10) - parseInt(b.versao, 10);
        });

        const filteredHistory = sortedHistory.map((item) => {
            return {
                created: new Date(item.data_atualizacao).toLocaleString(),
                key: `${item.documento_id}`,
                user: {
                    id: item.autor_id,
                    name: `${item.autor_first_name} ${item.autor_last_name}`
                },
                version: parseInt(item.versao, 10), 
                url: item.link || documento.active_version.link
            };
        });


        editorInstance.refreshHistory({
            "currentVersion": currentVersion,
            "history": filteredHistory
        });
        console.log({
            "currentVersion": currentVersion,
            "history": filteredHistory
        })
    };
    


    


    useEffect(() => {
        setDocumentData({
            id: documento?.id,
            link: documento?.link,
            title: documento?.titulo,
            fileType: getFileExtension(documento?.link),
            tipoVisualizador: renderFileTipe(getFileExtension(documento?.link))
        });
    }, [documento]);
   



   
    const config = useMemo(() => {
        if (!documentData.id || !documentData.fileType || !documentData.link) {
          return null;
        }
    
        return {
          document: {
            height: "100%",
            fileType: `${documentData.fileType}`,
            key: `${documentData.id}2024`,
            title: `${documentData.title}`,
            url: `${String(documentData.link)}`,
            permissions: {
              comment: false,
              download: false,
              edit: true,
              print: false,
            },
            info: {
              author: `${documento?.autor?.first_name} ${documento?.autor?.last_name}`,
              created: documento?.data_criacao,
            },
          },
          type: "embedded",
          documentType: `${documentData.tipoVisualizador}`,
          editorConfig: {
            mode: "view",
            lang: "pt-BR",
            print: false,
            user: {
              group: userAuth?.groups[0]?.nome,
              id: userAuth?.userId,
              name: `${userAuth?.name} ${userAuth?.last_name}`,
            },
            customization: {
              autosave: true,
              comments: true,
              plugins: false,
              uiTheme: "theme-classic-light",
              macros: false,
            },
          },
        };
      }, [documentData, documento, userAuth]);




      
    return (
        <div style={{width: '100%', height: '100%'}} key={refreshKey}>
            {config && (
                <DocumentEditor
                    id="docxEditor"
                    documentServerUrl={ONLYOFFICE_URL}
                    config={config}
                />
            )}
        </div>
    );
}

export default VisualizadorComponent;
