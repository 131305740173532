import './login.css'
import lms from '../../assets/microsoft.png'
import Logo from '../../assets/logo.png'
import { Input, Button, IconButton, InputGroup} from 'rsuite'
import { useMsal } from "@azure/msal-react";
import CompleteCadastro from '../cadastro';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { changeAuth } from '../../utils/store/auth';
import { useDispatch } from 'react-redux';
import {useAxios} from '../../utils/api/api';
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';


const LoginPage = () =>{
    const { instance } = useMsal();
    const api = useAxios();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const[show, setShow] = useState(false)
    const[loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false);
    const [password, setPassword] = useState('')
    const [username, setUsername] = useState('')
    const[user, setUser] = useState({
        name: '',
        token: '',
        officeLocation: '',
    })




    

    const handleLoginMs = async () => {  
        setLoading(true);
    
        try {
            const response = await instance.loginPopup();
            
            const apiResponse = await api.post('/api/v2/auth/microsoft/', {
                accessToken: response?.accessToken
            });
            if(apiResponse.status === 201){
                setUser({...user, name: apiResponse.data.givenName, email: apiResponse.data.email, token: apiResponse.data.token, officeLocation: apiResponse.data.officeLocation, })
                setLoading(false)
                setShow(true)
            }else if(apiResponse.status === 200){
                setLoading(false)
                navigate('/')
                dispatch(changeAuth(apiResponse.data))
            }
    
    
        } catch (error) {
            setLoading(false);
            enqueueSnackbar(`${error}`, {
                variant:'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            })
            
        } finally {
            setLoading(false);
        }
    };




   
    const handleLogin = async () => {
        if (!username || !password) {
            enqueueSnackbar('Preencha todos os campos', {
                variant:'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            })
            return;
        }


        try {
            setLoading(true)
            const response = await api.post('/api/v2/auth/token/', {
                username: username,
                password: password
            });
            if(response.status === 200){
                navigate('/')
                dispatch(changeAuth(response.data))
                setLoading(false)

            }

        }catch(error){
            setLoading(false)
            enqueueSnackbar(`${error}`, {
                variant:'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            })
        }
    }






    return(
        <div className="container-login" tabIndex={0} onKeyDown={(e)=> e.key === 'Enter' ? handleLogin(): null}>
            <SnackbarProvider maxSnack={3}/>
            <div className='login-content'>
                {show ? (
                    <CompleteCadastro user={user}/>
                ):(
                    <>
                    <div className='login-top'>
                       <img src={Logo} alt='img' style={{width: '50%', height: '45%'}} loading='lazy'/>
                    </div>
                    <div className='login-body'>
                        <div className='login-inputs row-01'>
                            <label>Usuário</label>
                            <Input re type="text" value={username} autoComplete="new-password" onChange={(value)=>setUsername(value)} placeholder="Usuário"/>
                        </div>
                        <div className='login-inputs row-01'>
                            <label>Senha</label>
                            
                            <InputGroup inside>
                                <Input value={password} autoComplete="new-password" onChange={(value)=>setPassword(value)} type={visible ? 'text' : 'password'}  placeholder="Senha"/>
                                <InputGroup.Button onClick={()=>setVisible(prev=>!prev)}>
                                    {visible ? <EyeIcon /> : <EyeSlashIcon />}
                                </InputGroup.Button>
                            </InputGroup>
                        </div>
                        <div className='login-recoveri'>
                            <Button appearance='link'>Esqueceu a senha?</Button>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5%'}}>
                            <IconButton onClick={()=>handleLoginMs()} placement="left" icon={<img src={lms} alt='img' style={{width: 25, height: 25, marginLeft: '-3em'}} loading='lazy'/>} style={{border: '1px solid #dddbdb', backgroundColor: '#fff'}}><spans style={{fontSize: 12}}>Entre com sua conta office</spans></IconButton>
                        </div>
                        <div className='login-buttom'>
                            <Button loading={loading} onClick={()=>handleLogin()}  appearance='primary' block style={{backgroundColor: '#00664e'}}>Entrar</Button>
                        </div>
                    </div>
                    </>
                )}
            </div>
        </div>
    )
}
export default LoginPage;