import './list-import.css';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { Input, InputGroup } from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import { useAxios } from '../../../../../../utils/api/api';
const ListImport = ({listSelected, setListSelected, fornecedor}) => {
    const api = useAxios();
    const [solicitacoes, setSolicitacoes] = useState([]);
    const [loading, setLoading] = useState(false);
    
    const [filterValue, setFilterValue] = useState({
        solicitante: '',
        data_inicio: '',
        data_fim: '',
        numero: '',
        centro_custo: '',
        status: ''
    });

    const handleFilterSolicitacoes = async () => {
        setLoading(true);
        try {
                const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/solicitacoes_filtered',{
                    params: {
                        is_pedido: 'false',  
                    }
                });
                setSolicitacoes(response.data.results);
                setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }



    useEffect(() => {
        handleFilterSolicitacoes();
    }, []);



    const handleSelect = (item) => {
        const list = listSelected;
        const index = list.indexOf(item);
        if (index === -1){
            list.push(item);
        } else {
            list.splice(index, 1);
        }
        setListSelected([...list]);
    }


    return(
        <div className="purchase-list-container" style={{position: 'relative', padding: 0}}>
            <div style={{position: 'sticky', top: 0, zIndex: 9999, marginBottom: 20, marginLeft: 5, marginRight: 5}}>
            <InputGroup style={{height: 36}}>
                    <InputGroup.Addon style={{padding: 0, margin: 0}}>
                    <select className="input-select" defaultValue="1" style={{fontSize: 10, height: '100%',  width: 75, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        <option value="1">Numero</option>
                        <option value="2">Solicitante</option>
                        <option value="3">Produto</option>
                    </select>
                    </InputGroup.Addon>
                <Input   placeholder="Pesquisar" />
            </InputGroup>
            </div>
            <div className="purchase-list-titles-import">
                <div className="purchase-list-title-item" style={{textAlign: 'center'}}>...</div>
                <div className="purchase-list-title-item" style={{textAlign: 'center'}}>NUMERO DA SC</div>
                <div className="purchase-list-title-item" style={{textAlign: 'center'}}>ITEM DA SC</div>
                <div className="purchase-list-title-item item-center" style={{textAlign: 'left'}}>SOLICITANTE</div>
                <div className="purchase-list-title-item item-center">PRODUTO</div>
                <div className="purchase-list-title-item item-center">QTD.</div>
                <div className="purchase-list-title-item item-center">EMISSÃO</div>
            </div>
            {solicitacoes?.map((processos, index) => {
                return (
                    <ul key={index} className='purchase-list-items'>
                        <li>
                            <div className='pushase-item-container'>
                                <div className="purchase-list-item-import">
                                    <div className="purchase-list-item custom">
                                        <span className="item-manager" onChange={()=>handleSelect(processos)}>
                                            <input type="checkbox"/>
                                        </span>
                                    </div>

                                    <div className="purchase-list-item" style={{textAlign: 'center'}}>
                                        <span className="item-title-sm">
                                            NUMERO
                                        </span>
                                        <span className="item-manager">
                                            {`${processos?.c1_num}`}
                                        </span>
                                    </div>
                                        
                                    <div className="purchase-list-item">
                                        <span className="item-manager">
                                            {processos?.c1_item}
                                        </span>
                                    </div>
                                    
                                    <div className="purchase-list-item custom">
                                        

                                        <span className="item-manager" title={processos?.c1_solicit} style={{width: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                            {processos?.c1_solicit?.toUpperCase()}
                                        </span>
                                    </div>

                                    

                                    <div className="purchase-list-item">
                                        <span className="item-manager" title={processos?.produto} style={{width: 200, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                            {processos?.produto}
                                        </span>
                                    </div>


                                    <div className="purchase-list-item">
                                        <span className="item-title-sm">
                                            QTD. ITENS
                                        </span>
                                        <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            {processos?.c1_quant}
                                        </span>
                                    </div>

                                    <div className="purchase-list-item">
                                        <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            {moment(processos.c1_emissao).format('DD/MM/YYYY')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        
                    </ul>
                );
            })}
               
        </div>
    )
}
export default ListImport;