import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { BASE_URL } from '../../utils/api/base';
import { useSelector } from 'react-redux';
import axios from 'axios';

const RadialChart = ({prop, dt1, dt2, tipo1, tipo2}) => {
  const selector = useSelector(state => state.auth);
  const [chartData, setChartData] = useState({
    series: [dt1, dt2],
    labels: [tipo1, tipo2],
  });

  useEffect(() => {
    setChartData({
      series: [dt1, dt2],
      labels: [tipo1, tipo2],
    })
  }, [ dt1, dt2, tipo1, tipo2 ]);

//   const handleGetData = () => {
//     axios({
//       url: `${BASE_URL}/api/v1/relatorios/documents_summary`,
//       method: 'GET',
//       headers: {
//         'Authorization': `Bearer ${selector.token.access}`
//       }
//     }).then(response => {
//       const data = response.data;

//       // Supondo que o endpoint retorna um objeto com os totais de documentos atualizados e vencidos
//       const series = [data.documentos_atualizados, data.documentos_vencidos];
//       const labels = ['Documentos Atualizados', 'Documentos Vencidos'];

//       setChartData({ series, labels });
//     });
//   };

  const chartOptions = {
    chart: {
      height: 350,
      type: 'radialBar',
    },
    colors: ['#00664e', '#01795da4'],
    plotOptions: {
      radialBar: {
        hollow: {
            size: '40%',
        },
        dataLabels: {
          name: {
            fontSize: '10px',
          },
          value: {
            fontSize: '20px',
          },
          total: {
            show: true,
            label: 'Total',
            formatter: function (w) {
              // Retorna a soma de todas as séries
              return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
            }
          }
        }
      }
    },
    labels: chartData.labels,
  };

  return (
    <div id="chart" style={{ height: '90%', position: 'absolute', width: '100%', marginTop: `${prop}`}}>
    <ReactApexChart
        options={chartOptions}
        series={chartData.series}
        type="radialBar"
        height='100%'
    />
    </div>
  );
};

export default RadialChart;
