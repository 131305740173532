import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userId: '',
    username: '',
    name: '',
    uuid: '',
    email: '',
    photo: '',
    last_name: '',
    is_updated: false,
    groups: [],
    permissions: [],
    userStatus: [],
    isAuthenticated: false,
    token: {
        access: '',
        refresh: '',
    },
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        changeAuth: (state, { payload }) => {
            state.isAuthenticated = !!payload.access;
            state.userId = payload.user.id.toString() || '';
            state.username = payload.user.username || '';
            state.name = payload.user.name || '';
            state.uuid = payload.user.uuid || '';
            state.last_name = payload.user.last_name || '';
            state.photo = payload.user.photo || '';
            state.email = payload.user.email || '';
            state.is_updated = payload.user.is_updated || false;
            state.groups = payload.user.groups || [];
            state.permissions = payload.user.permissions || [];
            state.token.access = payload.access || '';
            state.token.refresh = payload.refresh || '';
        },
        logout: (state) => {
            return { ...initialState };
        },
        
    },
});

export const { changeAuth, logout } = userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;
