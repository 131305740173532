import './data-card.css';
import { useAxios } from '../../../../../../utils/api/api';
import { useEffect, useState } from 'react';


const DataCardsAmz = () => {
    const api = useAxios();
    const [data, setData] = useState({
        total: 0,
        pendentes: 0,
    });



    

    return(
        <div className='container-data-cards'>
            <div className='data-card-item'>
                <span className='data-card-title'>Pendentes</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>05</span>
                </div>
            </div>

            <div className='data-card-item'>
                <span className='data-card-title'>Convertida em (SC)</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>10</span>
                </div>
            </div>

            <div className='data-card-item'>
                <span className='data-card-title'>Em transporte</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>65</span>
                </div>
            </div>
            <div className='data-card-item'>
                <span className='data-card-title'>Disponível Para Retirada</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>12</span>
                </div>
            </div>
            
            <div className='data-card-item'>
                <span className='data-card-title'>Total</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>92</span>
                </div>
            </div>

        </div>
    )
}

export default DataCardsAmz;