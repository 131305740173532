import './gestao-amz.css'
import { useNavigate } from 'react-router-dom';


const GestaoArmazen = () => {
    const navigate = useNavigate();
    
    return(
        <div className="folder-content">
            <div className="folder-row-2">
                
                <div className='table-documents main'>
                    <div className='cards-container-gestao-compras'>
                        {/* <div className="card-gestao-compras" onClick={()=>navigate('chegada-pedidos')} style={{border: '1px solid gray', borderRadius: 5}}>
                            <span>Atualização de Pedidos</span>
                        </div> */}
                        <div className="card-gestao-compras" onClick={()=>navigate('solicitacao-armazem')} style={{border: '1px solid gray', borderRadius: 5}}>
                            <span>Solicitação Armazém</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GestaoArmazen;
