import './data-card.css';
import { useAxios } from '../../../../../../utils/api/api';
import { useEffect, useState } from 'react';


const DataCards = ({solicitacoes}) => {
    const api = useAxios();
    const [data, setData] = useState({
        'aberto': 0,
        'andamento': 0,
        'aprovacao': 0,
        'transporte': 0,
        'total': 0
    });

    useEffect(() => {
        const newData = {
            'aberto': 0,
            'andamento': 0,
            'aprovacao': 0,
            'transporte': 0,
            'total': 0
        };
    
        solicitacoes.forEach((solicitacao) => {
            solicitacao.data.forEach((item) => {
                if (item.status === 'PD') {
                    newData.aberto += 1;
                } else if (item.status === 'AT') {
                    newData.andamento += 1;
                } else if (item.status === 'AP' || item.status === 'EA') {
                    newData.aprovacao += 1;
                }
            });
        });
    
        newData.total = solicitacoes.reduce((sum, group) => sum + group.data.length, 0);
    
        setData(newData);
    }, [solicitacoes]);


    return(
        <div className='container-data-cards'>
            <div className='data-card-item'>
                <span className='data-card-title'>Pendente</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.aberto}</span>
                </div>
            </div>


            <div className='data-card-item'>
                <span className='data-card-title'>Em Atendimento</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.andamento}</span>
                </div>
            </div>

            <div className='data-card-item'>
                <span className='data-card-title'>Aguardando aprovação</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.aprovacao}</span>
                </div>
            </div>

            <div className='data-card-item'>
                <span className='data-card-title'>Em transporte</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.transporte}</span>
                </div>
            </div>
            
            
            <div className='data-card-item'>
                <span className='data-card-title'>Total</span>
                <hr className='data-card-divider'/>
                <div className='data-card-value'>
                    <span>{data.total}</span>
                </div>
            </div>

        </div>
    )
}

export default DataCards;