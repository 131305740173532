import './cards-os.css';
import { useState, useEffect } from 'react';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import RemindOutlineIcon from '@rsuite/icons/RemindOutline';
import CopyIcon from '@rsuite/icons/Copy';
import { CiBoxes } from "react-icons/ci";

const CardsOs = () => {
    const [value, setValues] = useState({
        concluidas: 0,
        aberta: 0,
        total: 0,
        atradas: 0
    });


    useEffect(() => {
        handleGetTotal()
    },[])


    const handleGetTotal = () => {
        // var selectdata = new Array("/jdbc/PROTHEUSQUERY", `
        // SELECT 
        //     COUNT(CASE WHEN TJ_TERMINO = 'S' THEN 1 END) AS S_COUNT,
        //     COUNT(CASE WHEN TJ_TERMINO = 'N' THEN 1 END) AS N_COUNT,
        //     COUNT(TJ_TERMINO) AS T_COUNT
        // FROM STJ010;
        // `);
        // var data = {
        //     name: 'DOCGED',
        //     fields: selectdata
        // }
        // fetchIntegration(`/api/public/ecm/dataset/datasets/`, {}, 'POST',data)
        // .then(function(data){
        //     setValues({...value, concluidas: data.content.values[0]['S_COUNT'], aberta: data.content.values[0]['N_COUNT'], total: data.content.values[0]['T_COUNT']})
        // }).catch(function(error){
        //     console.log(error)
        // })

    }





    return (
        <div className='cards-container cards-os'>
            <div className="card cards-os">
                <div className='card-01'>
                    <div className='cart-info'>
                    <CheckOutlineIcon style={{fontSize: '4.5em'}}/>
                    <div className='card-text'>
                    <span>CONCLUIDAS</span>
                    <span>{value.concluidas}</span>
                    </div>
                    </div>
                </div>
            </div>
            <div className="card cards-os">
                <div className='card-01'>
                    <div className='cart-info'>
                    <CopyIcon style={{fontSize: '4.5em'}}/>
                    <div className='card-text'>
                    <span>ABERTAS</span>
                    <span>{value.aberta}</span>
                    </div>
                    </div>
                </div>
            </div>
            <div className="card cards-os">
                <div className='card-01'>
                    <div className='cart-info'>
                        <RemindOutlineIcon style={{fontSize: '4.5em'}}/>
                        <div className='card-text'>
                        <span>EM ATRASO</span>
                        <span>{value.atradas}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card cards-os">
                <div className='card-01'>
                    <div className='cart-info'>
                    <CiBoxes style={{fontSize: '5em'}}/>
                    <div className='card-text'>
                    <span>TOTAL</span>
                    <span>{value.total}</span>
                    </div>
                
                    </div>
                </div>
            </div>
        </div>   
    )
}
export default CardsOs;