import './user-space.css';
import { Button, Loader, IconButton, InputGroup, Whisper, Popover, Dropdown, Input, Badge } from 'rsuite';
import SharedScreen from '../compartilhadosScreen';
import AprovScreen from '../AprovacaoScreen';
import ProcessoScreen from '../ProcessoScreen';
import CheCkInScreen from '../checkInScreen';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MenuIcon from '@rsuite/icons/Menu';
import SearchIcon from '@rsuite/icons/Search';



const UserSpaceScreen = () => {
    const [selected, setSelected] = useState(1);
    const[loadingSpinner, setLoadingSpinner] = useState(false);
    const location = useLocation();
    const { open } = location.state || {};
    const [tipo, setTipo] = useState(1);
    const[sharedType, setSharedType] = useState(1);


    useEffect(() => {
        if(open == 4){
            setSelected(4);
        }
    }, [open]);



    

    



    return(
        <div className="folder-content">
            <div className="folder-row-2">
                <div className="input-table-folder-01 user-space"> 
                    <div className='div-user-menu'>
                        <button onClick={()=>setSelected(1)} className={selected === 1 ? 'active': ''}>
                            COMPARTILHADOS
                        </button> 
                        
                        
                        <button onClick={()=>setSelected(2)} className={selected === 2 ? 'active': ''}>
                            APROVAÇÕES 
                        </button> 
                        
                        
                        <button onClick={()=>setSelected(5)} className={selected === 5 ? 'active': ''}>
                            DOCUMENTOS EM CHECK-IN
                        </button> 

                        <button onClick={()=>setSelected(4)} className={selected === 4 ? 'active': ''}>
                            MINHA AREA
                        </button> 
                        
                    </div> 
                    {selected == 2 && (
                        <div className='aprov-filter' style={{zIndex: 1}}>
                            <div className="rs-input-group">
                                <input type="text" placeholder="Pesquisar" id="reserva-input" className="rs-input" />
                                <select className="input-select" onChange={(e)=>setTipo(e.target.value)} defaultValue="1" style={{fontSize: 9}}>
                                    <option value="1">Recebidos</option>
                                    <option value="2">Enviados</option>
                                </select>
                            </div>
                        </div>
                    )}


                    {selected == 1 && (
                        <div className='aprov-filter' style={{zIndex: 1}}>
                            <div className="rs-input-group">
                                <input type="text" placeholder="Pesquisar" id="reserva-input" className="rs-input" />
                                <select className="input-select" onChange={(e)=>setSharedType(e.target.value)} defaultValue="1" style={{fontSize: 9}}>
                                    <option value="1">Recebidos</option>
                                    <option value="2">Enviados</option>
                                </select>
                            </div>
                        </div>
                    )}
                    
                    {loadingSpinner && selected == 4 && (
                        <div>
                            <Loader center content="Carregando..." size="xs" speed="slow" style={{position: 'relative'}}/>
                        </div>
                    )}

                </div>
                <div className='table-documents user-space'>
                    {selected === 1 && <SharedScreen sharedType={sharedType}/>}
                    {selected === 2 && <AprovScreen tipo={tipo} setTipo={setTipo}/>}
                    {selected === 4 && <ProcessoScreen loadingSpinner={loadingSpinner} setLoadingSpinner={setLoadingSpinner}/>}
                    {selected === 5 && <CheCkInScreen/>}
                </div>
                
            </div>
        </div>
    )
}
export default UserSpaceScreen;