import { useState } from "react";
import { Button, Input,  InputGroup, Divider, Whisper, Tooltip} from "rsuite";
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import './cadastro.css';
import { changeAuth } from "../../utils/store/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../utils/api/api";




const CompleteCadastro = ({user}) => {
    const api = useAxios();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const[loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false);
    const[error, setError] = useState(false)
    const [form, setForm] = useState({
        username: '',
        password: '',
        confirmPassword: ''
    })



    const confirmPassword = (value) =>{
        if(value != form.password){
            setError(true)
        }else{
            setError(false)
            setForm({...form, confirmPassword: value})
        }
    }



    const handleCadastro = async () => {
        setLoading(true)
        if(form.username === '' || form.password === '' || form.confirmPassword === ''){
            setLoading(false)
            return alert('Preencha todos os campos')
        }
        try{
            const response = await api.post(`/api/v2/auth/create-ms-account/`, {
                username: form.username,
                password: form.password,
                email: user.email,
                token: user.token
            })
            setLoading(false)
            dispatch(changeAuth(response.data))
            navigate('/')
        }catch(error){
            console.log(error)
            setLoading(false)
        }
    }



    return (
        <div className="container-cadastro">
            <div className="cadastro-colum1">
                <div className="cadastro-colum1-row1">
                    <strong style={{marginRight: 5}}>Cadastro</strong>
                </div>
                <span style={{marginTop: -5}}>Preecha algumas Informações..</span>
            </div>
            <Divider/>
            <div className="cadastro-colum2">
                <div className="cadastro-input row-1">
                    <label>Usuario</label>
                    <Input type="text" value={form.username} onChange={(value)=>setForm({...form, username: value})} placeholder="Escolha o nome de usuario"  name="x-field-1" autoComplete="false"/>
                </div>
                <div className="cadastro-input row-2">
                    <label>Senha</label>
                    <InputGroup inside >
                    <Input value={form.password} onChange={(value)=>setForm({...form, password: value})} placeholder="Escolha sua senha" autoComplete="false" type={visible ? 'text' : 'password'} />
                    <InputGroup.Button onClick={()=>setVisible(prev=>!prev)}>
                    {visible ? <EyeIcon /> : <EyeSlashIcon />}
                    </InputGroup.Button>
                    </InputGroup>
                </div>
                <div className="cadastro-input row-3">
                    <label>Confirmar Senha</label>
                    <Whisper trigger="focus" open={error === true ? true : false} speaker={<Tooltip>As senhas não conferem</Tooltip>}>
                    <Input type="password" placeholder="Confirme sua senha" autoComplete="false" onChange={(value)=>confirmPassword(value)}/>
                    </Whisper>
                </div>
                <div className="cadastro-buttom">
                    <Button appearance="primary" loading={loading} onClick={()=>handleCadastro()} disabled={error ? true: false} style={{marginTop: 10, width: '50%', backgroundColor: '#046333b7'}}>Finalizar</Button>
                </div>
            </div>


        </div>
    );
}
export default CompleteCadastro;
