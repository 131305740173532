
import {persistStore,persistReducer,} from 'redux-persist';
import {combineReducers,configureStore} from '@reduxjs/toolkit';
import userReducer from './auth'
import dataScReducer from './datasc'
import storage from 'redux-persist/lib/storage';

const reducer = combineReducers({
    auth: userReducer,
    datasc: dataScReducer
    
})

const persistConfig = {
    key: 'aplicationGedTraterra',
    version: 1,
    storage: storage
}

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    })
})
const persistor = persistStore(store);
export {persistor, store}
