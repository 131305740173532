import './processo.css';
import { Loader, Modal, Button, Avatar, Input,  IconButton, Tooltip, Whisper, Popover, Progress, RadioGroup, Radio, InputPicker, Nav} from 'rsuite';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BranchIcon from '@rsuite/icons/Branch';
import { BiArea } from "react-icons/bi";
import { PiUserCirclePlusLight } from "react-icons/pi";
import { IoIosApps } from "react-icons/io";
import { FiFile } from "react-icons/fi";
import { FaArrowDownWideShort } from "react-icons/fa6";
import { FaArrowUpWideShort } from "react-icons/fa6";
import DocumentView from '../../components/documentView';
import { AiOutlineControl } from "react-icons/ai";
import { FaBoxOpen } from "react-icons/fa";
import GerenciarArea from '../../components/genArea';
import { useAxios } from '../../utils/api/api';


const filterAccessibleProcesses = (processosList, userId) => {
  return processosList.filter(processo => {
    const isUserInParent = processo.usuarios.some(user => user.id === userId);
    const isGestor = processo.gestor === userId;
    const isDiretor = processo.diretor === userId;
    const isAutor = processo.autor.id === userId;

    // Se o usuário estiver no pai ou for gestor, diretor ou autor, inclui todos os filhos
    if (isUserInParent || isGestor || isDiretor || isAutor) {
      if (processo.children && processo.children.length > 0) {
        const totalProgress = processo.children.reduce((acc, child) => acc + (child.processo_progress[0] || 0), 0);
        processo.processo_progress[0] = totalProgress / processo.children.length;
      }
      return true;
    }

    // Filtra os filhos acessíveis
    const accessibleChildren = processo.children.filter(child => 
      child.usuarios.some(user => user.id === userId) || 
      child.gestor === userId || 
      child.diretor === userId || 
      child.autor.id === userId
    );

    // Atualiza os filhos acessíveis no processo
    if (accessibleChildren.length > 0) {
      processo.children = accessibleChildren;
      if (accessibleChildren.length > 0) {
        const totalProgress = accessibleChildren.reduce((acc, child) => acc + (child.processo_progress[0] || 0), 0);
        processo.processo_progress[0] = totalProgress / accessibleChildren.length;
      }
      return true;
    }

    return false;
  });
};





const ProcessoScreen = () => {
  const userAuth = useSelector(state => state.auth)
  const api = useAxios();
  const[documentos, setDocumentos] = useState([])
  const navigate = useNavigate();
  const[loading, setLoading] = useState(false)
  const[showModal, setShowModal] = useState(false)
  const[selected, setSelected] = useState(null)
  const[processosList, setProcessosList] = useState([])
  const[step, setStep] = useState(0)
  const[area, setArea] = useState('')
  const[checked, setChecked] = useState([])
  const[usuarios, setUsuarios] = useState([])
  const[openArea, setOpenArea] = useState(false)
  const[expand, setExpand] = useState({
    open: false,
    index: null
  })
  const[openDocument, setOpenDocument] = useState({
    document: null, 
    documentName: null, 
    documentTitle: null,
  })
  const[openDocumentView, setOpenDocumentView] = useState(false)
  const[allUsers, setAllUsers] = useState([])
  const [loadingS, setLoadingSpinner] = useState(false)	
  

  useEffect(() => {
      handleGetAreas()
      handleGetUsers()
  },[])


  useEffect(() => {
    setChecked([])
  },[showModal])



  useEffect(() => {
    const handleScroll = (event) => {
        const stickyHeader = document.querySelector('.input-table-folder-01');
        const stickyList = document.querySelector('.list-titles');
        const headerRect = stickyHeader.getBoundingClientRect();
        if (headerRect.top < 0){
          stickyList.className = 'list-titles sticky-top';
        }else{
          stickyList.className = 'list-titles';
        }

    };

    const scrollableElements = document.querySelectorAll('.layout-main');

    scrollableElements.forEach(element => {
        element.addEventListener('scroll', handleScroll);
    });

    return () => {
        scrollableElements.forEach(element => {
            element.removeEventListener('scroll', handleScroll);
        });
    };
  }, []);



  const handleGetUsers = async () => {
      try{
          const response = await api.get('/api/v2/membros/users/get_users/')
          setAllUsers(response.data)
          setLoading(false)
      }catch(error){
          console.log(error)
          setLoading(false)
      }
  }


  const handleGetAreas = async () => {
    setLoadingSpinner(true)
    try{
      const response = await api.get('/api/v1/processo/get_areas/')
      setDocumentos(response.data);
      setLoadingSpinner(false)
      setLoading(false);

    }catch(error){
      console.log(error)
      setLoading(false)
      setLoadingSpinner(false)
    }
  }




  const handleGetProcessos = async (area_id) =>{
    setLoadingSpinner(true)
    try{
      const response = await api.get('/api/v1/processo/get_processos/', {
        params: {
          area_id: area_id
        }
      });
      setExpand({open: true, index: area_id})
      let us = filterAccessibleProcesses(response.data, parseInt(userAuth.userId))
      setProcessosList(us)
      setLoadingSpinner(false)
    }catch(error){
      console.log(error)
      setLoadingSpinner(false)
    }
  }




  const handleUpdateProcesso = async (id) => {
    setLoading(true)
    try{
      const response = await api.patch(`/api/v1/processo/${id}/`, {descricao: selected?.descricao})
      handleGetAreas()
      setLoading(false)
      setShowModal(false)
    }catch(error){
      console.log(error)
      setLoading(false)
    }
  }



  


  
  const handleChange = (newValues) => {
    setChecked([newValues]);
  };

  

  const handleOpenProcesso = (area_id) =>{
    
    if(expand.open){
      setExpand({
        open: false,
        index:null
      })
    }else{
      handleGetProcessos(area_id)
      setProcessosList([])
    }
    
    
    
  } 


  const suggestions = allUsers.filter(user => !usuarios?.some(assigned => assigned.id === user.id));

  const RenderMenu = React.forwardRef(({ data, onSelect, left, top, ...rest }, ref) => {
    const [groupedData, setGroupedData] = useState([]);

    useEffect(() => {
      const assignedData = data.map(user => ({ ...user, group: 'Atribuídos' }));
      const suggestionData = suggestions.map(user => ({ ...user, group: 'Sugestões' }));
      setGroupedData([...assignedData, ...suggestionData]);
    }, [data]);
    

  const RemoveUser = async (user_id) => {
    try{
      const response = await api.patch(`/api/v1/processo/${selected.id}/remove_user/`, {user_id: user_id})
      setUsuarios(usuarios.filter(user => user.id !== user_id))
      handleGetProcessos(expand.index)
    }catch(error){
      console.log(error)
    }
  }


  const handleAddUser = async (user_id) =>{
    try{
      const response = await api.patch(`/api/v1/processo/${selected.id}/add_user/`, {user_id: user_id})
      setUsuarios([...usuarios, allUsers.find(user => user.id === user_id)])
      handleGetProcessos(expand.index)
    }catch(error){
      console.log(error)
    }
  } 

  const renderMenuItem = (label, item) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{label} {item.last_name}</span>
        {item.group === 'Atribuídos' && (
          <Button size="xs" style={{color: '#000'}}  onClick={() =>RemoveUser(item.id)}>X</Button>
        )}
      </div>
    );
  };

  return (
    <Popover ref={ref} {...rest} style={{ left, top }} full>
      <div>
      <InputPicker
        data={groupedData}
        groupBy="group"
        labelKey="first_name"
        valueKey="id"
        onSelect={(value)=>handleAddUser(value)}
        style={{ width: 200 }}
        placeholder="Selecione um usuário"
        menuMaxHeight={200}
        renderMenuItem={renderMenuItem}
      />
      </div>
    </Popover>
  );
  });






const getAllDocuments = (process) => {
    let documents = process?.documents || [];

    if (process?.children && process?.children?.length > 0) {
      process?.children.forEach((child) => {
        if (child?.documents && child?.documents?.length > 0) {
          const documentsWithProcessId = child?.documents?.map(doc => ({ ...doc, processo_id: child.id, processo_progress: child?.processo_progress[0] }));
          documents = documents?.concat(documentsWithProcessId);
        }
      });
    }

    return documents;
};

const documentsProcess = getAllDocuments(selected);


const openFile = (data) => {
  setOpenDocument({document: data?.deip?.id, documentName: data?.deip?.codigo, documentTitle: data?.deip?.titulo}) 
  setOpenDocumentView(true)
}


const calculateProgress = () => {
  if (checked.length > 0) {
    const selectedSubProcesses = selected.children.filter(child => checked.includes(child.id));
    const totalProgress = selectedSubProcesses.reduce((total, child) => total + child.processo_progress[0], 0);
    return Math.round((totalProgress / selectedSubProcesses.length) * 10) / 10;
  }
  return Math.round(selected?.processo_progress[0] * 10) / 10;
};

const progressSub = calculateProgress();
const strokeColor = progressSub <= 25 ? '#eb9b34' : '#00664e';










return(
    <div>
        <div className="folder-row-2 compartilhados-container aprovacao">
          
          <div className="containter-process-list">  
            {documentos.length === 0 ? (
              <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Loader size="xs" content="Carregando.."/>
              </div>
            ):(
              <div className="list-container">
                <div className="list-titles">
                    <div className="list-title-item area">ÁREA</div>
                    <div className="list-title-item">GESTOR/COORDENADOR</div>
                    <div className="list-title-item">DEIP DA ÁREA</div>
                    <div className="list-title-item">PROCESSOS</div>
                    <div className="list-title-item">SUBPROCESSOS</div>
                    <div className="list-title-item">AVANÇO</div>
                    <div className="list-title-item"></div>
                </div>
              <>
              {documentos.length === 0 ? (
                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5%'}}>
                  <span style={{fontSize: 12, color: '#000'}}>O Usuario Não Esta Incluido em nehuma Área.</span>
                </div>
              ):(
                <>
                {documentos.map((processos, index) => (
                  <ul key={index} className='list-processos'>
                      <li>
                          <div className="list-item-content">
                              <div className="list-processos-item custom">
                                  <div>
                                      <IconButton icon={<BiArea style={{ color: '#fff' }} />} appearance='primary' size="lg" className="icon-button" />
                                  </div>
                                  <div className="item-text">
                                      <span className="item-name">{processos?.nome}</span>
                                  </div>
                              </div>
                              <div className="list-processos-item">
                                  <span className="item-manager">
                                      {processos.gestor === null ? "Sem gestor" : `${processos?.gestor?.first_name} ${processos?.gestor?.last_name}`}
                                  </span>
                              </div>

                              <div className="list-processos-item">
                                  <a className="item-manager" style={{cursor: 'pointer'}} onClick={() => processos.deip !== null ? openFile(processos) : null}>
                                      {processos.deip === null ? "Sem deip" : `${processos?.deip?.codigo}`}
                                  </a>
                              </div>

                              <div className="list-processos-item">
                                  <span className="item-count">{processos?.processos_count}</span>
                              </div>

                              <div className="list-processos-item">
                                  <span className="item-count">{processos?.subprocessos_count}</span>
                              </div>
                              
                              <div className="list-processos-item">
                                
                                <Progress.Line showInfo={false} title='progresso' percent={Math.round(processos?.progress?.progress_percent * 10) / 10} style={{ fontSize: 12}} strokeColor={Math.round(processos?.progress?.progress_percent * 10) / 10 <= 25 ? '#eb9b34' : '#00664e'} />  
                                <span style={{fontSize: 12, position: 'absolute', pointerEvents: 'none', color: '#fff', fontWeight: 'bold'}}>{Math.round(processos?.progress?.progress_percent * 10) / 10}%</span>
                              </div>

                              <div className="list-processos-item" style={{display: 'flex', flexDirection: 'row'}}>
                                  <IconButton title='gerenciar area' onClick={() => {setArea(processos); setOpenArea(true)}} icon={<FaBoxOpen style={{ color: '#00664e', fontSize: 20 }}/>} appearance='default' size="lg" style={{marginRight: 10}} />
                                  <IconButton title='listar processos' onClick={() => {handleOpenProcesso(processos.id)}} icon={expand.open && expand.index === processos.id ? <FaArrowUpWideShort style={{ color: '#00664e', fontSize: 20 }}/>  :  <FaArrowDownWideShort style={{ color: '#00664e', fontSize: 20 }}/>} appearance='default' size="lg" />
                              </div>
                          </div>
                      </li>
                      {expand.open && expand.index === processos.id && (
                          <ul className='list-expand'>
                              {processosList.length === 0 ? (
                                <span style={{fontSize: 12, fontWeight: 'bold', color: '#000'}}>Nenhum Processo Cadastrado.</span>
                              ):(
                                <>
                                {processosList?.map((processo, index) => (
                                <>
                                <li>
                                <div className="list-item-content custom">
                                    <div className="list-processos-item custom">
                                        <div>
                                          <IconButton icon={<BranchIcon style={{ color: '#fff', fontSize: 12 }} />} appearance='primary' size="lg" style={{ background: '#00664e', color: '#fff', marginRight: 10 }} />
                                        </div>
                                        <div className="item-text">
                                          <span style={{ fontSize: 11, fontWeight: 'bold' }}>{processo?.nome?.toUpperCase()}</span>
                                          <span style={{ fontSize: 12, color: 'gray' }}>{processo?.descricao}</span>
                                        </div>
                                    </div>
                                    

                                    <div className="list-processos-item">
                                        <span className="item-count">{processo?.children?.length}</span>
                                    </div>
                                    
                                    <div className="list-processos-item">

                                      <Progress.Line showInfo={false} title='progresso' percent={Math.round(processo?.processo_progress[0] * 10) / 10} style={{ fontSize: 12}} strokeColor={Math.round(processo?.processo_progress[0] * 10) / 10 <= 25 ? '#eb9b34' : '#00664e'} />  
                                      <span style={{fontSize: 12, position: 'absolute', pointerEvents: 'none', color: '#fff', fontWeight: 'bold'}}>{Math.round(processo?.processo_progress[0] * 10) / 10}%</span>
                                    </div>

                                    <div className="list-processos-item">
                                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                                        <IconButton title='gerenciar processo' onClick={() => { setSelected(processo); setShowModal(true); setUsuarios(processo?.usuarios) }}  icon={<AiOutlineControl style={{ color: '#00664e', fontSize: 20 }} />} appearance='default' size="lg"  style={{marginRight: 10}}/>
                                        </div>
                                    </div>
                                </div>
                                </li>
                                </>
                              ))}
                                </>
                              )}
                          </ul>
                      )}
                  </ul>
                ))}
                </>
              )}
              
              </>
              </div>
          
            )}
        
          </div>
        </div>
        <Modal open={showModal} onClose={()=>{setShowModal(false); setChecked([])}} size='md'>
          <Modal.Header>
            <Modal.Title style={{fontSize: 14, fontWeight: 'bold'}}>
              {selected?.nome}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{minHeight: 300}} className='processos-list'>
         
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: '100%', justifyContent: 'flex-start', paddingLeft: '1%', paddingRight: '1%', marginTop: 20}}>
          <div style={{marginBottom: '5%' }}>
            <span style={{fontSize: 12, fontWeight: 'bold', marginLeft: 5}}>Usuarios</span>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', cursor: 'pointer'}}>
              {parseInt(selected?.gestor) === parseInt(userAuth.userId) ? (
                <Whisper
                placement="bottomStart"
                trigger="click"
                speaker={<RenderMenu data={usuarios}/>}
              >
                <div  style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: 5, width: '100%'}}>
                  {usuarios.length > 0 ? (
                    <>
                      {usuarios?.map((usuario, index) => (
                        
                          <>
                            {index === 0 ? <PiUserCirclePlusLight style={{fontSize: 30, marginRight: 4}}/> : null}
                            <Whisper followCursor placement="bottom" speaker={<Tooltip>{usuario?.first_name} {usuario?.last_name}</Tooltip>}>
                              <Avatar circle size='sm' style={{ background: usuario?.avatar_color, marginRight: 5, cursor: 'pointer'}}>
                                {usuario.first_name[0]}{usuario?.last_name[0]}
                              </Avatar>
                            </Whisper>
                            </>
                        
                    ))}
                    </>
                  ):(<span>Nenhum Usuario</span>)}
                </div>
                </Whisper>
              ): (
                <div  style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginTop: 5, width: '100%'}}>
                  {usuarios.length > 0 ? (
                    <>
                      {usuarios?.map((usuario, index) => (
                        
                          <>
                            {index === 0 ? <PiUserCirclePlusLight style={{fontSize: 30, marginRight: 4}}/> : null}
                            <Whisper followCursor placement="bottom" speaker={<Tooltip>{usuario?.first_name} {usuario?.last_name}</Tooltip>}>
                              <Avatar circle size='sm' style={{ background: usuario?.avatar_color, marginRight: 5, cursor: 'pointer'}}>
                                {usuario.first_name[0]}{usuario?.last_name[0]}
                              </Avatar>
                            </Whisper>
                            </>
                        
                    ))}
                    </>
                  ):(<span>Nenhum Usuario</span>)}
                </div>
              )}
              
              <div style={{width: 200, marginTop: '-2%'}}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    {checked.length > 0 ? (
                      <div style={{width: 50,display: 'inline-block'}}>
                      <Progress.Circle showInfo={true} percent={progressSub} style={{ fontSize: 12, cursor: 'pointer' }} strokeColor={strokeColor} />
                      </div>
                    ) : (
                      <div style={{width: 50,display: 'inline-block'}}>
                        <Progress.Circle showInfo={true} percent={Math.round(selected?.processo_progress[0] * 10) / 10} style={{ fontSize: 12, cursor: 'pointer' }} strokeColor={Math.round(selected?.processo_progress[0] * 10) / 10 <= 25 ? '#eb9b34' : '#00664e'} />
                      </div>
                    )}                                             
                </div>
              </div>
            </div>
          </div>


          <span style={{fontSize: 12, fontWeight: 'bold', marginLeft: 5}}>Subprocessos</span>
            <div style={{padding: 5}}>
              <div style={{marginTop: 5}}>
                {selected?.children?.length > 0 ? (
                  <>
                  <RadioGroup name="checkbox-group" value={checked[0]} onChange={handleChange}>
                  {selected?.children?.map((subprocesso, index) => (
                      <Radio key={index} value={subprocesso?.id}>{subprocesso?.nome}</Radio>
                  ))}
                  </RadioGroup>
                  </>
                ): <span>Nenhum sub-processo</span>}
              </div>
            </div>

            <div style={{marginTop: '5%'}}>
              <span style={{fontSize: 12, fontWeight: 'bold', marginLeft: 5}}>Descrição</span>
              <Input as="textarea" rows={3} placeholder="Descrição" defaultValue={selected?.descricao || ''} onChange={(value)=>setSelected({...selected, descricao: value})} style={{marginTop: 5, borderLeft: '2px solid #00664e'}}/>
            </div>
            



            <div style={{marginTop: '5%', marginBottom: '5%'}}>
              <span style={{fontSize: 12, fontWeight: 'bold', marginLeft: 5}}>Documentos</span>
              <div className='cards-documentos'>
              {documentsProcess?.map((doc, index) => (
                <div className={`card-documento ${checked?.includes(doc?.processo_id) ? 'active' : ''}`} onClick={() => {setOpenDocument({document: doc.id, documentName: doc.codigo, documentTitle: doc.titulo}); setOpenDocumentView(true)}}>
                  <IconButton icon={<FiFile style={{ color: '#fff' }} />} appearance='primary' size="lg" className="icon-button" />
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, alignItems: 'flex-start', justifyContent: 'flex-start', maxWidth: '250px'}}>
                    <span style={{ fontSize: 11, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>{doc?.codigo}</span>
                    <span title={doc?.titulo} style={{ fontSize: 11, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>{doc?.titulo}</span>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', fontSize: 11 }}>{doc?.autor?.first_name} {doc?.autor?.last_name}</span>
                  </div>
                </div>
              ))} 
              </div>
            </div>

            <div style={{marginTop: '5%', marginBottom: '5%'}}>
              <span style={{fontSize: 12, fontWeight: 'bold', marginLeft: 5}}>Aplicativos</span>
              <div className='cards-documentos'>
              {selected?.frames.map((doc, index) => (
                    <div className={`card-documento`} key={index} onClick={() => navigate(`/aplicativos/gestao-patrimonial/${doc?.id}`, { state: { appName: doc?.nome } })}>
                        <IconButton icon={<IoIosApps style={{ color: '#fff' }} />} appearance='primary' size="lg" className="icon-button" />
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, alignItems: 'flex-start', justifyContent: 'flex-start', fontSize: 12 }}>
                            <span>{doc?.nome}</span>
                        </div>
                    </div>
                    ))
                  }
              </div>
            </div>
          </div>
          </Modal.Body>
          <Modal.Footer>
            {parseInt(selected?.gestor) === parseInt(userAuth.userId) ||  parseInt(selected?.autor?.id) === parseInt(userAuth.userId) && (
              <Button onClick={()=>handleUpdateProcesso(selected?.id)} loading={loading} appearance="primary">
                Atualizar
              </Button>
            )}
            <Button onClick={()=>setShowModal(false)} appearance="subtle">
              Sair
            </Button>
          </Modal.Footer>
        </Modal>
        <GerenciarArea showModal={openArea} setShowModal={setOpenArea} area={area}/>
        <DocumentView  open={openDocumentView} setOpen={setOpenDocumentView} id={openDocument?.document} documentTitle={openDocument?.documentTitle} documentName={openDocument?.documentName} />
    </div>

  )
}
export default ProcessoScreen;
