import React from 'react';
import { useState, useEffect } from 'react';
import { FiFolder } from "react-icons/fi";
import { Sidenav, Nav} from 'rsuite';
import logoIcon from '../../assets/Logotipo.png';
import logo from '../../assets/mark.png';
import './sidebar.css';
import styles from './sidebar.module.css';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { RxDashboard } from "react-icons/rx";
import { AiTwotoneFolderOpen } from "react-icons/ai";
import OperatePeopleIcon from '@rsuite/icons/OperatePeople';
import { IoIosApps } from "react-icons/io";
import { RiReservedLine } from "react-icons/ri";

const Sidebar = ({setOpen}) => {
    const state = useSelector(state=> state.auth)
    const location = useLocation();
    const[expand, setExpand] = useState(true);
    const navigate = useNavigate();
    const[pathActive, setPathActive] = useState('/')

    useEffect(()=>{
        setPathActive(location.pathname)
    }, [location])
    

    

    return (
        <Sidenav expanded={expand} appearance='subtle' className={expand ? styles.sidebarContent : `${styles.sidebarContent} ${styles.sidebarContentActive}`}>
            <div className={styles.sidebarLogoContent}>
                {expand ? (
                    <img src={logo} alt='logo' className={styles.sidebarLogo} loading='lazy'/>
                    
                ) : (
                    <img src={logoIcon} alt='logo' className={styles.sidebarLogoIcon} loading='lazy'/>
                    
                )}
            </div>
            <Sidenav.Body>
                <Nav activeKey="1">
                    <Nav.Item className={`rs-nav ${pathActive === '/' ? 'active' : ''}`} onClick={() => {setOpen(false);navigate('/')}} eventKey="1" icon={<AiTwotoneFolderOpen className={styles.iconRs} />}>
                        Biblioteca Pública
                    </Nav.Item>
                    {state.isAuthenticated !== false && (
                        <Nav.Item eventKey="2" className={`rs-nav ${pathActive === '/dashboard' ? 'active' : ''}`} onClick={()=>{setOpen(false);navigate('/dashboard')}} icon={<RxDashboard className={styles.iconRs} />}>
                            Dashboard
                        </Nav.Item>
                    )}
                    {state.isAuthenticated !== false && (
                        <Nav.Item eventKey="3" className={`rs-nav ${pathActive === '/meu-espaco' ? 'active' : ''}`} id="my-first-step-02" onClick={() => {setOpen(false);navigate('/meu-espaco')}} icon={<OperatePeopleIcon className={styles.iconRs} />}>
                            Meu Espaço
                        </Nav.Item>
                    )}
                    {state.isAuthenticated !== false && (
                        <Nav.Item eventKey="4" className={`rs-nav ${pathActive.includes('/aplicativos') ? 'active' : ''}`} onClick={() => {setOpen(false);navigate('/aplicativos')}} icon={<IoIosApps className={styles.iconRs} />}>
                            Aplicativos
                        </Nav.Item>
                    )}
                    {state.isAuthenticated !== false && (
                        <>
                        
                        {expand && (
                            <>
                            <Nav.Item divider style={{backgroundColor: '#fff', height: 1}}/>
                            <Nav.Item panel style={{padding: '15px 20px', color: '#aaa'}}>
                            GED
                            </Nav.Item>
                            </>
                        )}
                        <Nav.Item eventKey="2-1" className={`rs-nav ${pathActive.includes('/diretorios') ? 'active' : ''}`} onClick={() => {setOpen(false);navigate('/diretorios')}} icon={<FiFolder className={styles.iconRs} />}>Diretórios</Nav.Item>
                        <Nav.Item eventKey="2-2" className={`rs-nav ${pathActive === '/reservas' ? 'active' : ''}`} onClick={() => {setOpen(false);navigate('/reservas')}} icon={<RiReservedLine className={styles.iconRs}/>}>Reservas</Nav.Item>
                        </>
                    )}
                </Nav>
            </Sidenav.Body>
            <div className={styles.sidebarButtom}>
                <div style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'center', }}>
                    <Sidenav.Toggle expanded={expand} className='rs-nav' onToggle={() => setExpand(prev => !prev)} appearance='inverse' style={{ color: '#ffff', backgroundColor: '#f7f7f7' }}  />
                </div>
                
            </div>
        </Sidenav>
    );
};


export default Sidebar;
