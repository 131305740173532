import { Steps, IconButton } from 'rsuite';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@rsuite/icons/Close';
import './timeline.css';
import { useState, useEffect } from 'react';
import moment from 'moment';


import { FaCheckCircle } from "react-icons/fa";
import { TbCircleNumber1 } from "react-icons/tb";
import { TbCircleNumber2 } from "react-icons/tb";
import { TbCircleNumber3 } from "react-icons/tb";
import { TbCircleNumber4 } from "react-icons/tb";
import { TbCircleNumber5 } from "react-icons/tb";
import { TbCircleNumber6 } from "react-icons/tb";



const TimeLineSc = ({ data, setOpen }) => {

    const [expanded, setExpanded] = useState('');
    const [current, setCurrent] = useState(1);
    const [isReprov, setIsRepov] = useState(false);
    const [currentStatus, setCurrentStatus] = useState('process');

    


    const DesNova = ({description, currentStep}) => {
        return (
            <div  className={currentStep >= 1 ? 'item-data active' : 'item-data'}>
                <span>
                    {description.c1_solicit
                    ? description.c1_solicit.charAt(0).toUpperCase() + description.c1_solicit.slice(1).toLowerCase()
                    : ''}
                </span>
                <span>N°: {description?.c1_num}</span>
                <span>Solicitado em: {moment(description.c1_emissao).format('DD/MM/YYYY')}</span>
            </div>
        )
    }



    const DesForne = ({description, currentStep}) => {
        if(description.pedido_compra){
            return (
                <div  className={currentStep >= 3 ? 'item-data active' : 'item-data'} style={{height: 100}}>
                    <p>N°: {description.pedido_compra?.c7_num}</p>
                    <p>Fornecedor: {description.fornecedor?.toLowerCase()}</p>
                    <p>Data: {moment(description.pedido_compra?.c7_datprf).format('DD/MM/YYYY')}</p>
                </div>
            )
        }else{
            return (
                <div  className={currentStep >= 3 ? 'item-data active' : 'item-data'}>
                    <p></p>
                </div>
            )
        }
    }

    const DesPend = ({items, currentStep}) => {
        if(items.status === 'PD'){
            const timeone = moment(items.c1_datprf, 'YYYYMMDD');
            const timetwo = moment();
            let difference;

            if (timeone.isAfter(timetwo)) {
                difference = 0;
            } else {
                difference = timetwo.diff(timeone, 'days'); 
            }

            return (
                <div className={currentStep >= 1 ? 'item-data active' : 'item-data'}>
                    {difference === 0 ? (
                        <p>A Solicitação está a {difference} Dias em Aguardando</p>
                    ) : (
                        <p>A Solicitação está a {difference} Dias em Aguardando</p>
                    )}
                </div>
            );
            
        }else{
            if(items.pedido_compra){
                const timeone = moment(items.c1_datprf, 'YYYYMMDD')
                const timetwo = moment(items.pedido_compra.c7_datprf, 'YYYYMMDD')
                const difference = timetwo.diff(timeone, 'days');
                return (
                    <div className={currentStep >= 1 ? 'item-data active' : 'item-data'}>
                        <p>A solicitação ficou {difference} dias pendente</p>
                    </div>
                )
            }
        }
        
    };



    const CurrentActive = (items) => {
        if(items.status === 'PD'){
            return setCurrent(1);
        }
        if(items.status === 'AT'){
            return setCurrent(2);
        }else if(items.status === 'EA' || items.status === 'AP' || items.status === 'RG'){
            return setCurrent(3);
        }else if(items.status === 'EN'){
            return setCurrent(4);
        }
    }




    const DesAprovTime = ({items}) => {
        if(items.pedido_aprov){
            if(items.status === 'EA'){
                const timeone = moment(items?.pedido_compra.c7_emissao, 'YYYYMMDD')
                const timetwo = moment();
                const  difference = timetwo.diff(timeone, 'days');
                return (
                    <div className='item-data'>
                        <p>{difference} Dias Aguardando Aprovação</p>
                    </div>
                )
            }else if(items.status === 'RG'){
                setCurrentStatus('error')
                const timeone = moment(items?.pedido_compra.c7_emissao, 'YYYYMMDD')
                const timetwo = moment(items?.pedido_aprov?.data_aproval_response, 'YYYYMMDD');
                const  difference = timetwo.diff(timeone, 'days');
                return (
                    <div className='item-data' style={{height: 100}}>
                        <p>{items.pedido_aprov.usuario?.toLowerCase()}</p>
                        <p>Levou {difference} dias para reprovar o pedido</p>
                        <p>O pedido foi Reprovado em: {moment(items?.pedido_aprov?.data_aproval_response).format('DD/MM/YYYY')}</p>
                    </div>
                )
            }else{
                const timeone = moment(items?.pedido_compra.c7_emissao, 'YYYYMMDD')
                const timetwo = moment(items?.pedido_aprov?.data_aproval_response, 'YYYYMMDD');
                const  difference = timetwo.diff(timeone, 'days');
                return (
                    <div className='item-data' style={{height: 100}}>
                        <p>{items.pedido_aprov.usuario?.toLowerCase()}</p>
                        <p>Levou {difference} dias para aprovar o pedido</p>
                        <p>O pedido foi Aprovado em: {moment(items?.pedido_aprov?.data_aproval_response).format('DD/MM/YYYY')}</p>
                    </div>
                )
            }
        }

    }
    


    useEffect(() => {
        CurrentActive(data);
    }, [data]);


    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
           <AppBar sx={{ position: 'relative' }}>
            <Toolbar style={{ backgroundColor: '#00664e', paddingLeft: 10 }}>
                <IconButton icon={<CloseIcon />} size='sm' onClick={() => setOpen(false)} />
                <div style={{width: '100%', padding: 10}}>
                <h3 style={{fontSize: 12}}>TIME-LINE DA SOLICITAÇÃO</h3>
                </div>
            </Toolbar>
            </AppBar>
           <div style={{width: '100%', display: 'flex', paddingTop: 10, paddingLeft: 20, paddingRight: 10}}>


           <Steps small currentStatus={currentStatus} className='custom-step' current={current} vertical style={{marginTop: 10}}>
           
            <Steps.Item title="Solicitação"  
            description={<DesNova description={data} currentStep={current}/>} 
            
            />
            <Steps.Item title="Pedido Pendente"  description={
                <DesPend items={data} currentStep={current}/>
            } 
            />
            
            {/* 2 */}
            <Steps.Item 
                title="Pedido de Compra"  
                description={<DesForne description={data} currentStep={current}/>} 
            />

            {/* 3 */}
            <Steps.Item 
            title="Aprovação do Pedido"  
            description={<DesAprovTime items={data}/>} 
            />

            <Steps.Item 
                title="Solicitação concluida"  
                // description={<DesAprovTime items={data}/>} 
            />


           
        
            </Steps>
           </div>
        </div>
    );
}
export default TimeLineSc;