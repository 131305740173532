import './pdlist.css';
import { useState, useEffect } from 'react';
import { useAxios } from '../../../../../../utils/api/api';
import moment from 'moment';
import { MdOutlineExpandMore } from "react-icons/md";
import { MdOutlineExpandLess } from "react-icons/md";
import { Loader, Progress, Drawer, Button, Modal} from 'rsuite';


const PdList = ({handlePedido, solicitacoes, handleGetSolicitacoes, loading}) => {
    const api = useAxios();
    const [expandId, setExpandId] = useState(null);
    





    const handleProgress = (items) => {
        // if (items.c1_num === '0000'){
        //     return {
        //         percent: 0,
        //         count: '1/1'
        //     };
        // }
        // const data = items.data;
        // const isPedido = data.filter(item => item?.c1_pedido?.trim() !== '');
        // const progressPercent = (isPedido?.length / data?.length) * 100;
        return {
            percent: 20,
            count: `1/1`
        };
    };
    




    const handleStatusSol = (item) => {

    }
    
    
       

    console.log(solicitacoes)

    






    const toggleExpand = (index) => {
        
        setExpandId(prevId => (prevId === index ? null : index));
    };

   

    const handleCheck = (item) => {
        handlePedido(item.id);
    }


    

    return (
        <div className="purchase-list-container" style={{position: 'relative'}}>
            <div className="purchase-list-titles-pd">
                <div className="purchase-list-title-item" style={{textAlign: 'center'}}>NUMERO</div>
                <div className="purchase-list-title-item" style={{textAlign: 'center'}}>COMPRADOR</div>
                <div className="purchase-list-title-item item-center" style={{textAlign: 'left'}}>FORNECEDOR</div>
                <div className="purchase-list-title-item item-center">DATA DE EMISSÃO</div>
                <div className="purchase-list-title-item item-center">QTD. ITENS</div>
                <div className="purchase-list-title-item item-center">STATUS</div>
                <div className="purchase-list-title-item item-center">PROGRESSO</div>
                <div className="purchase-list-title-item item-center">...</div>
            </div>
            <>
            {loading ? (
                <div style={{marginTop: -10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Loader center content="Carregando..." style={{position: 'relative', marginTop: '5%'}}/>
                </div>
            ):(
                <>
                {solicitacoes?.map((processos, index) => {
                    return (
                        <ul key={index} className='purchase-list-items'>
                            <li>
                                <div className='pushase-item-container'>
                                    <div className="purchase-list-item-content-pd">
                                        {/* <div className="purchase-list-item custom">
                                            <span className="item-manager">
                                                <input type="checkbox" onChange={()=>handleCheck(processos)}/>
                                            </span>
                                        </div> */}

                                        <div className="purchase-list-item" style={{textAlign: 'center'}}>
                                            <span className="item-title-sm">
                                                NUMERO
                                            </span>
                                            <span className="item-manager">
                                                {`#${processos?.numero?.toUpperCase()}`}
                                            </span>
                                        </div>
                                            
                                        <div className="purchase-list-item">
                                            <span className="item-manager">
                                                RAILSON PINHEIRO
                                            </span>
                                        </div>
                                        
                                        <div className="purchase-list-item custom">
                                            <span className="item-title-sm">
                                                PRODUTO
                                            </span>

                                            <span className="item-manager">
                                                {processos?.solicitacao?.produto?.toUpperCase()}
                                            </span>
                                        </div>

                                        

                                        <div className="purchase-list-item">
                                            <span className="item-manager">
                                                {moment(processos.c7_emissao).format('DD/MM/YYYY')}
                                            </span>
                                        </div>


                                        <div className="purchase-list-item">
                                            <span className="item-title-sm">
                                                QTD. ITENS
                                            </span>
                                            <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                {processos.data.length}
                                            </span>
                                        </div>

                                        <div className="purchase-list-item">
                                            <span className="item-manager" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                PENDENTE
                                            </span>
                                        </div>

                                        <div className="purchase-list-item">
                                            <span className="item-title-sm">
                                                PROGRESSO DA SOLICITAÇÃO
                                            </span>
                                            <Progress.Line showInfo={false} title='progresso' percent={0} className='progress-bar-data' strokeColor='#00664e'/>  
                                            <span className='progress-data'>{handleProgress(processos).count}</span>
                                        </div>

                                        <div className="sc-purchase-list-item">
                                                <span className="item-title-sm">
                                                    ...
                                                </span>
                                                {expandId === index ? 
                                                    <MdOutlineExpandLess 
                                                        className="list-documents-icon" 
                                                        style={{ cursor: 'pointer' }} 
                                                        onClick={() => toggleExpand(index)} 
                                                    /> 
                                                    : 
                                                    <MdOutlineExpandMore 
                                                        className="list-documents-icon" 
                                                        style={{ cursor: 'pointer' }} 
                                                        onClick={() => toggleExpand(index)} 
                                                    />
                                                }
                                            </div>
                                    </div>
                                </div>
                            </li>
                            
                        </ul>
                    );
                })}
                
                </>
                
            )}
            </>
        </div>
    );
}
export default PdList;