import './table-os.css';
import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Table, Pagination, IconButton, Popover, Whisper, Dropdown, Button, Modal, List } from 'rsuite';
import MoreIcon from '@rsuite/icons/legacy/More';
import { useParams, useNavigate } from 'react-router-dom';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';


const TableOs = ({ ordenServicos, setOrdenServicos,loading }) => {
  const user = useSelector(state => state.auth);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const documents = [];
  const navigate = useNavigate();
  const { Column, HeaderCell, Cell } = Table;


  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };
  const data = ordenServicos?.slice(limit * (page - 1), limit * page);

  




   


  const renderMenu = ({ rowData, onClose, left, top, className }, ref) => {
    const handleSelect = () => {
      onClose();
    };

    const handleOpenDocument = (rowData) => {
    //   navigate(`/documentos/visualizar/${rowData.id}`, { state: { documentName: rowData.codigo, documentTitle: rowData.titulo } })
      
      // setDocumentist([
      //   {
      //     uri: `${BASE_URL}${rowData?.document_file}`,
      //     name: rowData?.titulo,
      //     is_principal: true
      //   }
      // ])
      // setPrincipalDoc({
      //   uri: `${BASE_URL}${rowData?.document_file}`,
      //   name: rowData?.titulo,
      //   is_principal: true
      // })
      onClose();
    };

    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu>
            <Dropdown.Item eventKey={1} onClick={()=>handleOpenDocument(rowData)}>
                Abrir
            </Dropdown.Item>
            <Dropdown.Item eventKey={1}>
                Editar
            </Dropdown.Item>
            <Dropdown.Item eventKey={1}>
                Remover
            </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  };

  const ActionCell = ({ rowData, dataKey, ...props }) => {
    const renderMenuWithRowData = (menuProps, ref) => {
      return renderMenu({ ...menuProps, rowData }, ref);
    };

    return (
      <Cell {...props} className="link-group">
        <Whisper trigger="click" speaker={renderMenuWithRowData} placement="bottomEnd">
          <IconButton appearance="subtle" icon={<MoreIcon />} style={{ marginTop: -10 }} />
        </Whisper>
      </Cell>
    );
  };



  const NameCell = ({ rowData, dataKey, ...props }) => {
    const speaker = (
      <Popover title={<span style={{fontSize: 10}}>OBSERVAÇÃO</span>}>
        <div style={{width: 150, fontSize: 12}}>
            {rowData[dataKey]}
        </div>
      </Popover>
    );
  
    return (
      <Cell {...props}>
        <Whisper placement="top" speaker={speaker}>
          <a><InfoOutlineIcon/></a>
        </Whisper>
      </Cell>
    );
  };



  

  return (
    <div className="containter-table-documents">
      <Table loading={loading} height={520} data={data} bordered style={{ borderRadius: 5, padding: 5, fontFamily: 'sans-serif', fontSize: 11}}>
        
        <Column width={100} fullText>
          <HeaderCell className="table-header-documents">CODIGO OS</HeaderCell>
          <Cell dataKey="TJ_ORDEM" />
        </Column>

        <Column width={100} fullText>
          <HeaderCell className="table-header-documents">BEM</HeaderCell>
          <Cell dataKey="TJ_CODBEM" />
        </Column>

        <Column width={200} flexGrow={1} fullText>
          <HeaderCell className="table-header-documents">TIPO DE MANUTENÇÃO</HeaderCell>
          <Cell dataKey="TE_NOME" />
        </Column>

        <Column width={200} flexGrow={1} fullText>
          <HeaderCell className="table-header-documents">SERVIÇO</HeaderCell>
          <Cell dataKey="T4_NOME"/>
        </Column>

        <Column width={150} align='center' fullText>
          <HeaderCell className="table-header-documents">CENTRO DE CUSTO</HeaderCell>
          <Cell dataKey="TJ_CCUSTO" />
        </Column>

        <Column width={150} align='center' fullText>
          <HeaderCell className="table-header-documents">ULTIMA ALTERAÇÃO</HeaderCell>
          <Cell>
            {rowData => (
                <span>{rowData?.TJ_USUARIO.toUpperCase()}</span>
            )}
          </Cell>
        </Column>

        

        <Column width={200} align="center">
          <HeaderCell className="table-header-documents">DATA DE INICIO ORIGINAL</HeaderCell>
          <Cell>{rowData => moment(rowData?.TJ_DTORIGI, "YYYYMMDD").format('DD/MM/YYYY')}</Cell>
        </Column>
        <Column width={200} align="center">
          <HeaderCell className="table-header-documents">ULTIMA MANUTENÇÃO</HeaderCell>
          <Cell>{rowData => rowData.TJ_DTULTMA.replace(/\s+/g, '').length === 0 ? 'INDEFINIDO' : moment(rowData?.TJ_DTULTMA, "YYYYMMDD").format('DD/MM/YYYY')}</Cell>
        </Column>
        <Column width={100} align='center'>
            <HeaderCell>OBS.</HeaderCell>
            <NameCell dataKey="TJ_OBSERVA_TEXT" />
        </Column>

        <Column width={80}>
          <HeaderCell className="table-header-documents">...</HeaderCell>
          <ActionCell dataKey="R_E_C_N_O_" />
        </Column>
      </Table>
      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={ordenServicos?.length}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </div>
  );
};

export default TableOs;
