import { Steps, IconButton } from 'rsuite';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@rsuite/icons/Close';
import './timeline.css';
import { useState, useEffect } from 'react';
import moment from 'moment';


import { FaCheckCircle } from "react-icons/fa";
import { TbCircleNumber1 } from "react-icons/tb";
import { TbCircleNumber2 } from "react-icons/tb";
import { TbCircleNumber3 } from "react-icons/tb";
import { TbCircleNumber4 } from "react-icons/tb";
import { TbCircleNumber5 } from "react-icons/tb";
import { TbCircleNumber6 } from "react-icons/tb";



const TimeLineSa = ({ data, setOpen }) => {

    const [expanded, setExpanded] = useState('');
    const [current, setCurrent] = useState(1);
    const [isReprov, setIsRepov] = useState(false);
    const [currentStatus, setCurrentStatus] = useState('process');

    


    const DesNova = ({description, currentStep}) => {
        return (
            <div  className={currentStep >= 1 ? 'item-data active' : 'item-data'}>
                <span>
                {description.cp_solicit
                    ? description.cp_solicit.charAt(0).toUpperCase() + description.cp_solicit.slice(1).toLowerCase()
                    : ''}
                </span>
                <span>N°: {description?.cp_num}</span>
                
            </div>
        )
    }



    const DesForne = ({description, currentStep}) => {
        if(description.pedido_compra){
            return (
                <div  className={currentStep >= 3 ? 'item-data active' : 'item-data'}>
                    <p>N°: {description.pedido_compra?.c7_num}</p>
                    <p>Fornecedor: {description.fornecedor?.toLowerCase()}</p>
                </div>
            )
        }else{
            return (
                <div  className={currentStep >= 3 ? 'item-data active' : 'item-data'}>
                    <p></p>
                </div>
            )
        }
    }

    const DesPend = ({items, currentStep}) => {
        if(items.pedido_compra){
            return (
                <div className={currentStep >= 1 ? 'item-data active' : 'item-data'}>
                    <p>Iniciado em: {moment(items?.pedido_compra?.c7_emissao).format('DD/MM/YYYY')}</p>
                </div>
            )
        }else{
            const timeone = moment(items.cp_datprf, 'YYYYMMDD')
            const timetwo = moment()
            const difference = timetwo.diff(timeone, 'days');
            return (
                <div className={currentStep >= 1 ? 'item-data active' : 'item-data'}>
                    <p>{difference} Dias em Comparação com a Necessidade</p>
                </div>
            )
        }
        
    };



    const CurrentActive = (items) => {
        if (items.status === "PD"){
            setCurrent(1);
        }else if(items.status === "EA"){
            setCurrent(3);
        }else if(items.status === "AP" || items.status === "RG"){
            setCurrent(3);
        }
        
    }




    const DesAprovTime = ({items}) => {
        if(items.status === "EA"){
            if(items.pedido_compra){
                const timeone = moment(items?.pedido_compra?.c7_emissao, 'YYYYMMDD')
                const timetwo = moment();
                const  difference = timetwo.diff(timeone, 'days');
                return (
                    <div className='item-data'>
                        <p>{difference} Dias aguardando aprovação</p>
                    </div>
                )
            }else{
                return (
                    <div className='item-data'>
                        <p></p>
                    </div>
                )
            }
        }else if(items.status === "AP"){
            return (
                <div className='item-data'>
                    <p>Aprovado em: {moment(items?.data_aproval_response).format('DD/MM/YYYY')}</p>
                </div>
            )
        }else if(items.status === "RG"){
            setCurrentStatus('error')
            return (
                <div className='item-data'>
                    <p>Reprovado em: {moment(items?.data_aproval_response).format('DD/MM/YYYY')}</p>
                </div>
            )

        }
        
    }
    


    useEffect(() => {
        // CurrentActive(data);
        console.log(data);
    }, [data]);


    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
           {/* {CurrentActive(data)} */}
           <AppBar sx={{ position: 'relative' }}>
            <Toolbar style={{ backgroundColor: '#00664e', paddingLeft: 10 }}>
                <IconButton icon={<CloseIcon />} size='sm' onClick={() => setOpen(false)} />
                <div style={{width: '100%', padding: 10}}>
                <h3 style={{fontSize: 12}}>TIME-LINE DA SOLICITAÇÃO</h3>
                </div>
            </Toolbar>
            </AppBar>
           <div style={{width: '100%', display: 'flex', paddingTop: 10, paddingLeft: 20, paddingRight: 10}}>


           <Steps currentStatus={currentStatus} className='custom-step' current={current} vertical style={{marginTop: 10}}>
           
            <Steps.Item title="Solicitação"  
            description={<DesNova description={data} currentStep={current}/>} 
            
            />
            <Steps.Item title="Pendente de Atendimento"  description={
                <DesPend items={data} currentStep={current}/>
            } 
            />
            
            {/* 2 */}
            <Steps.Item 
                title="Pedido de Compra"  
                description={<DesForne description={data} currentStep={current}/>} 
            />

            {/* 3 */}
            <Steps.Item 
            title="Aprovação do Pedido"  
            description={<DesAprovTime items={data}/>} 
            />



            {/* 4 */}
           
            
            {!isReprov && (
                <>
                
                {/* 5 */}
                </>
            )}
            </Steps>
           </div>
        </div>
    );
}
export default TimeLineSa;