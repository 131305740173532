import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Loader, Modal, Button, Avatar, Timeline,  IconButton, Tooltip, Whisper, Popover, Progress, Nav,Tag, InputPicker} from 'rsuite';
import { useNavigate } from 'react-router-dom';
import BranchIcon from '@rsuite/icons/Branch';
import { PiUserCirclePlusLight } from "react-icons/pi";
import { FiFile } from "react-icons/fi";
import ArrowDownIcon from '@rsuite/icons/ArrowDown';
import ArrowUpIcon from '@rsuite/icons/ArrowUp';
import { FaRegFolder } from "react-icons/fa6";
import DocumentView from '../../components/documentView';
import './gen-area.css';
import axios from "axios";
import { BASE_URL } from "../../utils/api/base";
import SearchIcon from '@rsuite/icons/Search';
import GridIcon from '@rsuite/icons/Grid';
import ReviewIcon from '@rsuite/icons/Review';
import WavePointIcon from '@rsuite/icons/WavePoint';
import moment from "moment";
import ShareOutlineIcon from '@rsuite/icons/ShareOutline';
import DocPassIcon from '@rsuite/icons/DocPass';
import ListIcon from '@rsuite/icons/List';
import SharedProperties from "../drawerSharedProperties";
import AcessPermission from "../acessPermission";
import EditIcon from '@rsuite/icons/Edit';




const permissionList = [
  {
      "label": "Visualizar",
      "value": "can_view"
  },
  {
      "label": "Editar",
      "value": "can_edit"
  },
  {
      "label": "Baixar",
      "value": "can_download"
  },
  {
      "label": "Criar Pasta",
      "value": "can_create_folder"
  },
  {
      "label": "Compartilhar",
      "value": "can_shared_documents"
  }
]




const GerenciarArea = ({showModal, setShowModal, area}) => {
    const userAuth = useSelector(state => state.auth);
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [selected, setSelected] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [folders, setFolders] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [visibleCount, setVisibleCount] = useState(6);
    const [folderVisibleCount, setFolderVisibleCount] = useState(6);
    const [openFolderGen, setOpenFolderGen] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState({
      id: null,
      codigo: null,
      titulo: null,
    });
    const [openShared, setOpenShared] = useState(false);
    const [openDocument, setOpenDocument] = useState(false);
    const [activity, setActivity] = useState([]);
    const [originalActivity, setOriginalActivity] = useState([]);
    const [stepActivity, setStepActivity] = useState(0);
    const [gestor, setGestor] = useState(null);
    const [userList, setUserList] = useState([]);
    const [openPicker, setOpenPicker] = useState(false);
    const [openModalPermission, setOpenModalPermission] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [groupedData, setGroupedData] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    
    

    useEffect(() => {
      if(area){
        handleGetArea();
        handleGedActivity();
        handleGetAllUsers();
      }
    }, [area])


    const handleAddUserArea = (user_id) => {
      const user = userList.find(user => user.id === user_id);
      axios({
          method: 'POST',
          url: `${BASE_URL}/api/v1/codigo/area/add_user_area/`,
          headers: {
              Authorization: `Bearer ${userAuth.token.access}`
          },
          data: {
              area_id: area?.id,
              user_id: user_id
          }
      }).then((response) => {
          setUsuarios([...usuarios, user]);
      }).catch((error) => {
          console.log(error);
      })
    }



    const handleRemovaUserArea = (user_id) => {
      const user = usuarios.filter(user => user.id !== user_id);
      
      axios({
          method: 'POST',
          url: `${BASE_URL}/api/v1/codigo/area/remove_user_area/`,
          headers: {
              Authorization: `Bearer ${userAuth.token.access}`
          },
          data: {
              area_id: area?.id,
              user_id: user_id
          }
      }).then((response) => {
          setUsuarios(user);
      }).catch((error) => {
          console.log(error);
      })
    }




    const handleGetAllUsers = () => {
      axios({
          method: 'GET',
          url: `${BASE_URL}/api/v1/pasta/main/get_all_users`,
          headers: {
              Authorization: `Bearer ${userAuth.token.access}`
          }
      }).then((response) => {
          setUserList(response.data);
      }).catch((error) => {
          console.log(error);
      })
    }



    const suggestions = userList.filter(user => !usuarios?.some(assigned => assigned.id === user.id));
    useEffect(() => {
      
      const assignedData = usuarios.map(user => ({ ...user, group: 'Atribuídos' }));
      const suggestionData = suggestions.map(user => ({ ...user, group: 'Sugestões' }));
      setGroupedData([...assignedData, ...suggestionData]);
    }, [data, usuarios]);




    const renderMenuItem = (label, item) => {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <>
          <span>{label} {item.last_name}</span>
          {item.group === 'Atribuídos' && (
            <div style={{ width: 50, display: 'flex', justifyContent: 'flex-end'}}>
              <Button size="xs" title="Remover Usuário" style={{ color: '#000' }} onClick={()=>handleRemovaUserArea(item.id)}>X</Button>
            </div>
          )}
          </>
        </div>
      );
    };


    const handleGetArea = () => {
      setLoading(true)
      axios({
          method: 'GET',
          url: `${BASE_URL}/api/v1/codigo/area/gerenciar?area_id=${area?.id}`,
          headers: {
              Authorization: `Bearer ${userAuth.token.access}`
          }
      }).then((response) => {
        setData(response.data);
        setUsuarios(response.data[0].usuarios);
        setDocuments(response.data[0].documentos);
        setFolders(response.data[0].pastas);
        setGestor(response.data[0].gestor);

      }).catch((error) => {
          console.log(error)
          setLoading(false)
      })
    }

    const handleGedActivity = () => {
      axios({
          method: 'GET',
          url: `${BASE_URL}/api/v1/codigo/area/feed_area?area_id=${area?.id}`,
          headers: {
              Authorization: `Bearer ${userAuth.token.access}`
          }
      }).then((response) => {
        
        const sortedActivity = response.data[0]?.eventos.sort((a, b) => {
          const dateA = new Date(a.data).getTime();
          const dateB = new Date(b.data).getTime();
          return dateB - dateA;
        });
        setActivity(sortedActivity);
        setOriginalActivity(sortedActivity);
        setLoading(false);
      }).catch((error) => {
          console.log(error)
          setLoading(false)
      })
    }




    const handleSearch = (value) => {
        if (value.length < 2) {
            setActivity(originalActivity);
            return;
        }
    
        const lowerCaseValue = value.toLowerCase();
    
        const filteredData = originalActivity.filter(item => {
            if (item.data && item.data.toLowerCase().includes(lowerCaseValue)) {
                return true;
            }
    
            return item.eventos.some(event => 
                (event.aprovador && event.aprovador.toLowerCase().includes(lowerCaseValue)) ||
                (event.document_codigo && event.document_codigo.toLowerCase().includes(lowerCaseValue)) ||
                (event.document_titulo && event.document_titulo.toLowerCase().includes(lowerCaseValue)) ||
                (event.reservado_por && event.reservado_por.toLowerCase().includes(lowerCaseValue)) ||
                (event.compartilhado_por && event.compartilhado_por.toLowerCase().includes(lowerCaseValue)) ||
                (event.compartilhado_para && event.compartilhado_para.toLowerCase().includes(lowerCaseValue)) ||
                (event.tipo_evento && event.tipo_evento.toLowerCase().includes(lowerCaseValue)) ||
                (event.data && event.data.toLowerCase().includes(lowerCaseValue))
            );
        });
    
        setActivity(filteredData);
    };
  
    


   
    
    const getAllSubpastas = (pastas) => {
      let allSubpastas = [];
      pastas.forEach(pasta => {
        if (pasta.subpastas && pasta.subpastas.length > 0) {
          allSubpastas.push(...pasta.subpastas);
          allSubpastas.push(...getAllSubpastas(pasta.subpastas));
        }
      });
      return allSubpastas;
    };
  
    const filteredSubpastas = folders
      .flatMap(folder => folder?.subpastas)
      .filter(subpasta => subpasta?.nome === "GESTAO DOCUMENTADA" || subpasta?.nome === "REGISTRO DOCUMENTADO")
      .flatMap(folder => getAllSubpastas([folder]));


    return(
        <Modal open={showModal} onClose={()=>{{setShowModal(false); setOpenPicker(false)}}} size='md'>
          <Modal.Header>
            <Modal.Title style={{fontSize: 14, fontWeight: 'bold'}}>
                {area?.nome} 
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{minHeight: 300}} className='processos-list'>
          
          <Nav appearance="subtle">
            <Nav.Item active={step === 0} onClick={()=>setStep(0)}>Inicio</Nav.Item>
            <Nav.Item active={step === 1} onClick={()=>setStep(1)}>Atividades</Nav.Item>
            {parseInt(gestor?.id) === parseInt(userAuth.userId) && (
              <Nav.Item active={step === 2} onClick={()=>setStep(2)}>Gerenciar Acessos</Nav.Item>
            )}
          </Nav>
            
            {step === 0 && (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: '100%', justifyContent: 'flex-start', paddingLeft: '1%', paddingRight: '1%', marginTop: 20}} >
              <div style={{marginBottom: '5%'}}>
                <span style={{fontSize: 12, fontWeight: 'bold', marginLeft: 5}}>Usuarios</span>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', cursor: 'pointer'}}> 
                <div className="content-users" onClick={()=> parseInt(gestor?.id) === parseInt(userAuth.userId) ? setOpenPicker(prev=>!prev) : null}>
                  <>
                  {usuarios.length > 0 ? (
                    <>
                      {usuarios?.map((usuario, index) => (
                    
                      <>
                      {index === 0 ? <PiUserCirclePlusLight style={{fontSize: 30, marginRight: 4}}/> : null}
                      <Whisper followCursor placement="bottom" speaker={<Tooltip>{usuario?.first_name} {usuario?.last_name}</Tooltip>}>
                        <Avatar circle size='sm' style={{ background: usuario?.avatar_color, marginRight: 5, cursor: 'pointer'}}>
                          {usuario?.first_name[0]}{usuario?.last_name[0]}
                        </Avatar>
                      </Whisper>
                      </>
                        
                    ))}
                    </>
                  ):(<span>Nenhum Usuario</span>)}
                  </>
                </div>  
                  <div style={{width: 200, marginTop: '-2%'}}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                      <div style={{width: 50,display: 'inline-block'}}>
                      <Progress.Circle showInfo={true} percent={10} style={{ fontSize: 12, cursor: 'pointer' }} strokeColor='#00664e' />
                      </div>                                   
                    </div>
                  </div>
                </div>
                <div className={openPicker ? "content-list-users active" : "content-list-users"}>
                    <InputPicker
                      data={groupedData}
                      onSelect={(value)=>handleAddUserArea(value)}
                      groupBy="group"
                      labelKey="first_name"
                      valueKey="id"
                      style={{ width: 300 }}
                      placeholder="Selecione um usuário"
                      menuMaxHeight={300}
                      open={openPicker}
                      renderMenuItem={renderMenuItem}
                    />
                  </div>
              </div>
    
            
              <div style={{marginTop: '5%', marginBottom: '5%'}}>
                  <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span style={{fontSize: 12, fontWeight: 'bold', marginLeft: 5}}>Documentos</span>
                    <SearchIcon style={{fontSize: 25, marginRight: 5, backgroundColor: 'gray', color: '#fff', padding: 3, borderRadius: 5, cursor: 'pointer'}}/>
                    {/* <input type="text" placeholder="Pesquisar" style={{width: 200, height: 25, border: '0.1px solid #d3d3d3', paddingLeft: 5, borderRadius: 5}}/> */}
                  </div>
                  <div className='cards-documentos'>
                    {documents?.slice(0, visibleCount).map((doc, index) => (
                      <div className='card-documento' key={index}>
                        <IconButton icon={<FiFile style={{ color: '#fff' }} />} appearance='primary' size="lg" className="icon-button" />
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, alignItems: 'flex-start', justifyContent: 'flex-start', maxWidth: '250px' }}>
                          <span style={{ fontSize: 11, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>{doc?.codigo}</span>
                          <span title={doc?.titulo} style={{ fontSize: 11, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>{doc?.titulo}</span>
                          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', fontSize: 11 }}>{doc?.autor?.first_name} {doc?.autor?.last_name}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                  {visibleCount < documents?.length && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                    <IconButton icon={<ArrowDownIcon style={{ color: '#fff' }} />} style={{ fontSize: 10 }} appearance='default' size="sm" onClick={()=>setVisibleCount(documents.length)}>
                      Mostrar mais
                    </IconButton>
                  </div>
                  )}

                  {visibleCount > 6 && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                    <IconButton icon={<ArrowUpIcon style={{ color: '#fff' }} />} style={{ fontSize: 10 }} appearance='default' size="sm" onClick={()=>setVisibleCount(6)}>
                      Mostrar menos
                    </IconButton>
                  </div>
                  )}
              </div>

              <div style={{marginTop: '5%', marginBottom: '5%'}}>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span style={{fontSize: 12, fontWeight: 'bold', marginLeft: 5}}>Pastas</span>
                    <SearchIcon style={{fontSize: 25, marginRight: 5, backgroundColor: 'gray', color: '#fff', padding: 3, borderRadius: 5, cursor: 'pointer'}}/>
                    {/* <input type="text" placeholder="Pesquisar" style={{width: 200, height: 25, border: '0.1px solid #d3d3d3', paddingLeft: 5, borderRadius: 5}}/> */}
                  </div>
                  <div className='cards-documentos'>
                  {filteredSubpastas?.slice(0, folderVisibleCount)?.map((doc, index) => (
                    <div className='card-documento' onClick={()=>{setOpenFolderGen(true); setSelectedFolder([doc])}}>
                      <IconButton icon={<FaRegFolder style={{ color: '#fff' }} />} appearance='primary' size="lg" className="icon-button" />
                      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, alignItems: 'flex-start', justifyContent: 'flex-start', maxWidth: '250px'}}>
                        <span style={{ fontSize: 11, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }} title={doc?.nome}>{doc?.nome}</span>
                      </div>
                    </div>
                  ))} 
                  </div>
                  {folderVisibleCount < filteredSubpastas?.length && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                    <IconButton icon={<ArrowDownIcon style={{ color: '#fff' }} />} style={{ fontSize: 10 }} appearance='default' size="sm" onClick={()=>setFolderVisibleCount(filteredSubpastas?.length)}>
                      Mostrar mais
                    </IconButton>
                  </div>
                  )}

                  {folderVisibleCount > 6 && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                    <IconButton icon={<ArrowUpIcon style={{ color: '#fff' }} />} style={{ fontSize: 10 }} appearance='default' size="sm" onClick={()=>setFolderVisibleCount(6)}>
                      Mostrar menos
                    </IconButton>
                  </div>
                  )}
              </div>
  

            </div>
            )}

            {step === 1 && (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: '100%', justifyContent: 'flex-start', paddingLeft: '1%', paddingRight: '1%', marginTop: 20}}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                      <IconButton onClick={()=>setStepActivity(0)} icon={<WavePointIcon className={stepActivity === 0 ? 'icon-buttom-activity active' : 'icon-buttom-activity'}/>} className={stepActivity === 0 ? 'icon-buttom-activity active' : 'icon-buttom-activity'} size="xs" style={{marginRight: 5}}>
                      Todas
                      </IconButton>
                      <IconButton 
                      icon={<BranchIcon className={stepActivity === 1 ? 'icon-buttom-activity active' : 'icon-buttom-activity'} />} className={stepActivity === 1 ? 'icon-buttom-activity active' : 'icon-buttom-activity'} size="xs" style={{marginRight: 5}}
                      onClick={()=>setStepActivity(1)}
                      >
                      WorkFlow
                      </IconButton>
                      <IconButton onClick={()=>setStepActivity(2)} icon={<GridIcon className={stepActivity === 2 ? 'icon-buttom-activity active' : 'icon-buttom-activity'} />} className={stepActivity === 2 ? 'icon-buttom-activity active' : 'icon-buttom-activity'} size="xs" style={{marginRight: 5}}>
                      Processo
                      </IconButton>
                      <IconButton onClick={()=>setStepActivity(3)} icon={<ReviewIcon className={stepActivity === 3 ? 'icon-buttom-activity active' : 'icon-buttom-activity'} />} className={stepActivity === 3 ? 'icon-buttom-activity active' : 'icon-buttom-activity'} size="xs" style={{marginRight: 5}}>
                      Reserva
                      </IconButton>
                      <IconButton onClick={()=>setStepActivity(4)} icon={<ShareOutlineIcon className={stepActivity === 4 ? 'icon-buttom-activity active' : 'icon-buttom-activity'} />} className={stepActivity === 4 ? 'icon-buttom-activity active' : 'icon-buttom-activity'} size="xs">
                      Compartilhados
                      </IconButton>
                    </div>
                    <input type="text" onChange={(e)=>handleSearch(e.target.value)} placeholder="Pesquisar" className="input-search"/>
                  </div>
                  <div style={{ marginTop: '5%'}}>
                    {activity?.map((act, index) => (
                      <div key={index}>
                        <div style={{position: 'sticky', top: -1, backgroundColor: '#fff', width: '100%', zIndex: 9999}}>
                          <h3 style={{ fontSize: '12px', fontWeight: 'bold', margin: '20px 0 10px', color: '#2c3e50'}}>
                            {moment(act?.data).format('L')}
                          </h3>
                        </div>
                        <Timeline>
                          {act?.eventos
                            ?.filter((evento) => {
                              if (stepActivity === 0) return true;
                              if (stepActivity === 1) return evento.tipo_evento === 'Documento em Aprovação'; 
                              if (stepActivity === 2) return evento.tipo_evento === 'Documento Adicionado ao Processo'; 
                              if (stepActivity === 3) return evento.tipo_evento === 'Nova Reserva';
                              if (stepActivity === 4) return evento.tipo_evento === 'Novo Compartilhamento'; 
                              return false;
                            })
                            .map((evento, index) => (
                              <Timeline.Item key={index} style={{ padding: '10px 0' }}>
                                <div className="card-timeline">
                                  <div className="timeline-title-container">
                                    <Tag 
                                      size="sm" 
                                      style={{
                                        backgroundColor: `${evento?.tipo_evento === 'Documento Adicionado ao Processo' ? '#076e56d7' : 
                                                        evento?.tipo_evento === 'Novo Compartilhamento' ? '#2196f3' : 
                                                        evento?.tipo_evento === 'Documento em Aprovação' ? '#bb7e0eb2' : 
                                                        evento?.tipo_evento === 'Nova Reserva' ? '#444444ce' : ''}`, 
                                        color: '#fff', fontSize: 9, borderRadius: 4
                                      }}>
                                      {/* Ícones de acordo com o tipo de evento */}
                                      {evento?.tipo_evento === 'Novo Compartilhamento' && (
                                        <ShareOutlineIcon style={{color: '#fff', marginRight: 5, fontSize: 12, marginTop: -3}}/>
                                      )}
                                      {evento?.tipo_evento === 'Documento Adicionado ao Processo' && (
                                        <GridIcon style={{color: '#fff', marginRight: 5, fontSize: 12, marginTop: -3}}/>
                                      )}
                                      {evento?.tipo_evento === 'Documento em Aprovação' && (
                                        <BranchIcon style={{color: '#fff', marginRight: 5, fontSize: 12, marginTop: -3}}/>
                                      )}
                                      {evento?.tipo_evento === 'Nova Reserva' && (
                                        <ReviewIcon style={{color: '#fff', marginRight: 5, fontSize: 12, marginTop: -3}}/>
                                      )}
                                      {evento?.tipo_evento}
                                    </Tag>
                                    <p style={{ fontSize: '10px', margin: 0, color: '#95a5a6' }}>
                                      {moment(evento?.data).startOf('hour').fromNow()}
                                    </p>
                                  </div>
                                  <hr style={{marginTop: 5, marginBottom: 0}}/>
                                  <div className="timeline-content">
                                    {evento?.tipo_evento === 'Nova Reserva' && (
                                      <div>
                                        <p style={{ fontSize: '13px', margin: '3px 0', color: '#7f8c8d' }}>Código: {evento?.codigo}</p>
                                        <p style={{ fontSize: '13px', margin: '3px 0', color: '#7f8c8d' }}>Autor: {evento?.reservado_por}</p>
                                      </div>
                                    )}
                                    {evento?.tipo_evento === 'Documento em Aprovação' && (
                                      <div>
                                        <div style={{marginBottom: 5, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                          <span style={{ fontSize: '13px', color: '#7f8c8d'}}>
                                            O Usuario {evento?.aprovador} recebeu o documento
                                          </span>
                                          <div>
                                            <div style={{marginLeft: 5, marginRight: 5}}>
                                              <strong title={evento?.document_titulo}  
                                                onClick={()=>{setSelectedDocument({id: evento?.document_id, codigo: evento?.document_codigo, titulo: evento?.document_titulo}); setOpenDocument(true)}}
                                                style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block',fontSize: 12, cursor: 'pointer', color: '#4848f6'}}>
                                                {evento?.document_codigo}
                                              </strong>
                                            </div>
                                          </div>
                                          <span style={{ fontSize: '12px', color: '#7f8c8d', marginRight: 5 }}>
                                            para aprovação no dia
                                          </span>
                                          <span style={{ fontSize: '12px', color: '#7f8c8d' }}>
                                            {moment(evento?.data).format('LLL')}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    {evento?.tipo_evento === 'Novo Compartilhamento' && (
                                      <div>
                                        <div style={{marginBottom: 5, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                          <span style={{ fontSize: '13px', color: '#7f8c8d'}}>
                                            O Usuario {evento?.compartilhado_por} compartilhou o documento
                                          </span>
                                          <div>
                                            <div style={{marginLeft: 5, marginRight: 5}}>
                                              <strong title={evento?.document_titulo}
                                                onClick={()=>{setSelectedDocument({id: evento?.document_id, codigo: evento?.document_codigo, titulo: evento?.document_titulo}); setOpenDocument(true)}}  
                                                style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', cursor: 'pointer', color: '#4848f6'}}>
                                                {evento?.document_codigo}
                                              </strong>
                                            </div>
                                          </div>
                                          <span style={{ fontSize: '12px', color: '#7f8c8d' }}>
                                            Com {evento?.compartilhado_para}
                                          </span>
                                        </div>
                                        {parseInt(area?.gestor?.id) === parseInt(userAuth.userId) && (
                                          <IconButton icon={<ListIcon  />} size="xs" style={{fontSize: 10}} onClick={()=>{setSelectedDocument({id: evento?.document_id, codigo: evento?.document_codigo, titulo: evento?.document_titulo}); setOpenShared(true)}}>
                                            Abrir propriedades
                                          </IconButton>
                                        )}
                                      </div>
                                    )}
                                    {evento?.tipo_evento === 'Documento Adicionado ao Processo' && (
                                      <div>
                                        <div style={{marginBottom: 5}}>
                                          <p style={{ fontSize: '13px', margin: '3px 0', color: '#7f8c8d' }}>{evento?.nome}</p>
                                          <p style={{ fontSize: '12px', margin: '3px 0', color: '#7f8c8d' }}>{evento?.descricao}</p>
                                        </div>
                                        <div style={{ width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                                          <div className="document-timeline">
                                            <IconButton icon={<DocPassIcon/>} size="xs" onClick={()=>{setSelectedDocument({id: evento?.documento_id, codigo: evento?.documento_codigo, titulo: evento?.documento_titulo}); setOpenDocument(true)}} >
                                              <span title={evento?.documento_titulo}  
                                                style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block'}}>
                                                {evento?.documento_codigo}
                                              </span>
                                            </IconButton>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Timeline.Item>
                          ))}
                        </Timeline>

                      </div>
                    ))}
                  </div>

                </div>
            )}

            {step === 2 && (
              <AcessPermission area_id={area?.id}/>
            )}
         
            <SharedProperties id={selectedDocument.id} titulo={selectedDocument.titulo} codigo={selectedDocument.codigo} open={openShared} setOpen={setOpenShared}/>
            <DocumentView id={selectedDocument.id} documentTitle={selectedDocument.titulo} documentName={selectedDocument.codigo} open={openDocument} setOpen={setOpenDocument}/>
            
            <Modal keyboard={false} style={{zIndex: 99999}} onOpen={()=>setOpenPicker(false)} open={openModalPermission} onClose={()=>setOpenModalPermission(false)}>
              <Modal.Header>
                <Modal.Title>Permissões</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div>
                  {console.log(selectedUser)}
                  <span>Selecione as permissões</span>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={()=>setOpenModalPermission(false)} appearance="primary">
                  Ok
                </Button>
                <Button onClick={()=>{setOpenModalPermission(false)}} appearance="subtle">
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={()=>setShowModal(false)} appearance="subtle">
              Sair
            </Button>
          </Modal.Footer>
        </Modal>
    )
}
export default GerenciarArea;