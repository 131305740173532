import './open-service.css';
import React,{ useState, useEffect } from "react";
import { BASE_URL } from "../../../../utils/api/base";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack'
import { InputGroup, Input, IconButton, Breadcrumb, Button, Dropdown, Popover, Whisper, Modal} from 'rsuite';
import TableOs from '../../components/tableOs';
import SearchIcon from '@rsuite/icons/Search';
import PlusIcon from '@rsuite/icons/Plus';
import MenuIcon from '@rsuite/icons/Menu';
import FormOs from '../../components/formOs';
import CardsOs from '../../components/cardsOs';


const NavLink = React.forwardRef((props, ref) => {
    const { to, children, ...rest } = props;
    return (
        <Link to={to} ref={ref} {...rest}>
            {children}
        </Link>
    );
});


const AberturaServico = () => {
    const id = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { appName } = location.state || {};
    const[open, setOpen] = useState(false);
    const[loading, setLoading] = useState(false);
    const[loadingForm, setLoadingForm] = useState(false);
    const [ordenServicos, setOrdenServicos] = useState([]);
    const[formOs, setFormOs] = useState({
        equipment:"",
        equipmentName: "",
        service:"",
        serviceName: "",
        situation: "",
        costCenter: "105009",
        startDate:"20240418 10:02",
        endDate: "20240419 10:02",
        observation: "",
        checklist: [],
        inputs: []
    })
        


    const renderMenu = ({ onClose, left, top, className }, ref) => {
        const handleSelect = eventKey => {
          onClose();
          console.log(eventKey);
        };
        return (
          <Popover ref={ref} className={className} style={{ left, top }} full>
            <Dropdown.Menu onSelect={handleSelect}>
              <Dropdown.Item eventKey={1} onClick={()=>{setOpen(true); onClose()}}>OS CORRETIVA</Dropdown.Item>
              <Dropdown.Item eventKey={2}>SOLICITAÇÃO DE SERVIÇO</Dropdown.Item>
            </Dropdown.Menu>
          </Popover>
        );
    };

    useEffect(() => {
        handleGetFrota()
    },[])

    const handleGetFrota = () => {
        // setLoading(true)
        // var selectdata = new Array("/jdbc/PROTHEUSQUERY", `
        // SELECT TOP 1000 
        //     STJ010.*, 
        //     ST4010.T4_NOME, 
        //     STE010.TE_NOME,
        //     CONVERT(varchar(max), CONVERT(varbinary(max), TJ_OBSERVA)) AS TJ_OBSERVA_TEXT
        // FROM STJ010 
        // LEFT JOIN ST4010 ON STJ010.TJ_SERVICO = ST4010.T4_SERVICO
        // LEFT JOIN STE010 ON STJ010.TJ_TIPO = STE010.TE_TIPOMAN
        // ORDER BY STJ010.R_E_C_N_O_ DESC;
        // `);
        // var data = {
        //     name: 'DOCGED',
        //     fields: selectdata
        // }
        // fetchIntegration(`/api/public/ecm/dataset/datasets/`, {}, 'POST',data)
        // .then(function(data){
        //     // console.log(data.content.values)
        //     setLoading(false)
        //     setOrdenServicos(data.content.values)
        // }).catch(function(error){
        //     console.log(error)
        // })

    }



    const GetRequest = () =>{
        setLoadingForm(true)
        axios.post('http://138.36.21.139:8061/rest/api/oauth2/v1/token?grant_type=password&username=railson.pinheiro@traterra.com.br&password=123456')
        .then(function(response){
            axios({
                method: 'POST',
                url: 'http://138.36.21.139:8061/rest/mntng/create',
                headers: {
                    Authorization: `Bearer ${response.data.access_token}`
                },
                data:formOs
            }).then(function(response){
                setLoadingForm(false)
                setOpen(false)
                enqueueSnackbar(`O.S. criada com sucesso Nº: ${response.data.data.code}`, { 
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                });
                handleGetFrota()
            }).catch(function(error){
                console.log(error)
                setLoadingForm(false)
                enqueueSnackbar('Erro ao criar O.S.', { 
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                });

            })
        })
    }


    return (
        <div className="folder-content" style={{padding: 10}}>
                <SnackbarProvider/>
                <div className="folder-row-1 user-space">
                    <div className="folder-child-01">
                        <Breadcrumb>
                            <Breadcrumb.Item as={NavLink} to={`/aplicativos/gestao-frotas/${id.id}`}>
                            GESTÃO DE FROTAS
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>GERENCIAMENTO DE OS | SS</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>    
                </div>
                <div>
                    <CardsOs/>
                </div>
                <div className="folder-row-2 os-content">
                    <div className='os-input-content'>
                        <Whisper placement="bottomStart" trigger="click" speaker={renderMenu}>
                        <IconButton icon={<PlusIcon style={{color: '#fff', backgroundColor: '#077844d5'}}/>} appearance='primary' style={{backgroundColor: '#077844d5', fontSize: 11}}>CRIAR NOVO</IconButton>
                        </Whisper>    
                        <div className='folder-row-3'>
                            <InputGroup style={{width: 250}}>
                                <Input placeholder='Pesquisar'/>
                                <InputGroup.Addon style={{backgroundColor: '#53525200', fontSize: 8}}s>
                                    <SearchIcon />
                                </InputGroup.Addon>
                            </InputGroup>
                            <IconButton icon={<MenuIcon style={{color: '#fff'}}/>} appearance='primary' style={{marginLeft: 5, backgroundColor: '#077844d5'}}></IconButton> 
                        </div> 
                    </div>
                    
                    <div className='table-documents os-app'>
                        <TableOs ordenServicos={ordenServicos} setOrdenServicos={setOrdenServicos} loading={loading}/>
                    </div>
                </div>

                <Modal size="md" open={open} onClose={()=>setOpen(false)}>
                    <Modal.Header>
                    <Modal.Title style={{fontSize: 12}}>NOVA O.S. CORRETIVA</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormOs formOs={formOs} setFormOs={setFormOs}/>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button onClick={()=>setOpen(false)} appearance="subtle">
                        Cancel
                    </Button>
                    <Button loading={loadingForm} onClick={()=>GetRequest()} appearance="primary">
                        Ok
                    </Button>
                    </Modal.Footer>
                </Modal>

            </div>
);
}
export default AberturaServico;