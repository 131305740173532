import { Modal, Button, Table } from "rsuite";
import { useSelector} from "react-redux";
import moment from "moment";



const { Column, HeaderCell, Cell } = Table;


export const ModalVersion = ({ showModal, setShowModal, documentList }) => {
    const user = useSelector(state => state.auth);
    
   



    






    




    return (
        <Modal open={showModal} overflow={true} size="md" onClose={() => setShowModal(false)}>
            <Modal.Header>
                <Modal.Title style={{fontSize: 12}}>VERSÕES DO DOCUMENTO</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: 400, display: 'flex', justifyContent: 'stretch', alignItems: 'stretch' }}>
            <div style={{ width: '100%' }}>
                <Table
                    style={{width: '100%'}}
                    height={400}
                    data={documentList}
                    onRowClick={rowData => {
                        console.log(rowData);
                    }}
                    >
                    <Column width={300} align="left">
                        <HeaderCell style={{fontSize: 13}}>AUTOR</HeaderCell>
                        <Cell>
                        {rowData => (
                            <span>
                            {rowData.autor_first_name} {rowData.autor_last_name}
                            </span>
                        )}
                        </Cell>
                    </Column>

                    <Column width={360} align="center">
                        <HeaderCell style={{fontSize: 13}}>DATA DE ATUALIZAÇÃO</HeaderCell>
                        <Cell>
                        {rowData => (
                            <span>
                            {moment(rowData.data_atualizacao).format('DD/MM/YYYY')}
                            </span>
                        )}
                        </Cell>
                    </Column>

                    <Column width={100} align="center">
                        <HeaderCell>VERSÃO</HeaderCell>
                        <Cell dataKey="versao" />
                    </Column>


                    
                    </Table>
              
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShowModal(false)} appearance="subtle">
                    Sair
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

