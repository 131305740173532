import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../store/auth';
import { BASE_URL } from '../api/base';
import axios from 'axios';
import { useLocation } from 'react-router-dom';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector(state => state.auth);
    return (
        <AuthContext.Provider value={{ user }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
