import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import 'rsuite/dist/rsuite.min.css';
import { BrowserRouter } from 'react-router-dom';
import { store, persistor } from './utils/store/store';
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt-br';
import { ENVIROMENT } from './utils/api/base';

moment.locale('pt-br');



// if(ENVIROMENT === 'prod'){
//   console.log('%cAtenção!', 'color: red; font-size: 24px;');
//   console.log(
//     '%cEste console é uma ferramenta destinada a desenvolvedores. Se alguém pediu para que você cole algo aqui, isso pode ser uma tentativa de comprometer a segurança da sua conta, o que contraria as políticas internas de segurança da empresa.',
//     'color: orange; font-size: 16px;'
//   );

//   Object.keys(console).forEach(function(method) {
//     console[method] = function () {};
//   });
// }



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// reportWebVitals();
