import {
  Modal,
  Button,
  InputPicker,
  Input,
  TagGroup,
  Tag,
  Panel,
} from "rsuite";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../utils/api/base";
import "./modal-compartilhar.css";
import { useState, useEffect } from "react";
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from "notistack";
import { FaRegUser } from "react-icons/fa";
import { TbUsersGroup } from "react-icons/tb";
import { PiSuitcaseSimple } from "react-icons/pi";
import { GoContainer } from "react-icons/go";
import { useAxios } from "../../utils/api/api";



const ModalCompartilhar = ({ open, setOpen, id, tipo }) => {
  const api = useAxios();
  const userAuth = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [funcao, setFuncao] = useState([]);
  const [groups, setGroups] = useState([]);
  const [text, setText] = useState("");
  const [compStep, setCompStep] = useState(1);
  const [compData, setCompData] = useState({
    users: [],
    groups: [],
    funcao: [],
    text: "",
    permission: [],
  });


  const permissionArray = [
    { label: "Visualizar", value: "can_view" },
    { label: "Editar", value: "can_edit" },
    { label: "Baixar", value: "can_download" },
    { label: "Compartilhar", value: "can_shared_documents"},
    
  ];


  useEffect(() => {
    handleGetUsers("");
    handleGetGrupo();
    handleGetFuncao("");
  },[])



  const handleGetUsers = async (query) => {
    try{
      const response = await api.get(`/api/v2/membros/users?query=${query}`)
      setUsers(response.data)
    }catch(error){
      console.log(error)
    }
  };

  const handleGetFuncao = async (query) => {
    try{
      const response = await api.get(`/api/v2/membros/funcao?query=${query}`)
      setFuncao(response.data)
    }catch(error){
      console.log(error)
    }
  };

  const handleGetGrupo = async () => {
    try{
      const response = await api.get(`/api/v2/acess-manager/group/`)
      setGroups(response.data)
    }catch(error){
      console.log(error)
    }
  };

  const handleShareDocument = (id) => {
    setLoading(true);
    axios({
      method: "POST",
      url: `${BASE_URL}/api/v1/compartilhamentos/${tipo}/`,
      headers: {
        Authorization: `Bearer ${userAuth.token.access}`,
      },
      data: {
        document_id: id,
        user: compData.users.map((item) => item.id),
        permissions: compData.permission.map((item) => item.value),
        message: text,
        group: compData.groups.map((item) => item.id),
        funcao: compData.funcao.map((item) => item.id),
      },
    })
      .then((response) => {
        setLoading(false);
        enqueueSnackbar("Documento Compartilhado com sucesso", {
          variant: "success",
        });
        setTimeout(() => {
            setOpen(false);
        }, 1000);
        setCompData({
          users: [],
          groups: [],
          funcao: [],
          text: "",
          permission: "",
        });
        setCompStep(1);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Erro no compartilhamento", { variant: "error" });
        console.log(error);
      });
  };

  return (
    <Modal
      // className="modal-compartilhar"
      backdrop
      size={"md"}
      open={open}
      onClose={() => {
        setOpen(false);
        setCompData({
          users: [],
          groups: [],
          funcao: [],
          text: "",
          permission: "",
        });
      }}
    >
      <Modal.Header>
        <Modal.Title style={{ fontSize: 12 }}>
          {compStep === 1
            ? "COMPARTILHAR"
            : compStep === 2
            ? "COMPARTILHAR PARA USUARIOS"
            : compStep === 3
            ? "COMPARTILHAR PARA GROUPOS"
            : "COMPARTILHAR POR FUNÇÕES"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="compartilhar-content">
          {compStep === 1 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Panel
                bordered
                onClick={() => setCompStep(2)}
                className="card-container"
                header={<span>Usuarios</span>}
                style={{cursor: "pointer"}}
              >
                <div>
                  <FaRegUser style={{ fontSize: 50, color: "#ccc" }} />
                </div>
              </Panel>

              <Panel
                bordered
                onClick={() => setCompStep(3)}
                className="card-container"
                style={{cursor: "pointer"}}
                header={<span>Grupos</span>}
              >
                <div>
                  <TbUsersGroup style={{ fontSize: 50, color: "#ccc" }} />
                </div>
              </Panel>

              <Panel
                bordered
                onClick={() => setCompStep(4)}
                className="card-container"
                header={<span>Funções</span>}
                style={{cursor: "pointer"}}
              >
                <div>
                  <PiSuitcaseSimple style={{ fontSize: 50, color: "#ccc" }} />
                </div>
              </Panel>
              
              <Panel
                bordered
                className="card-container"
                header={<span>Contratos</span>}
                style={{cursor: "not-allowed"}}
              >
                <div>
                  <GoContainer style={{ fontSize: 50, color: "#ccc" }} />
                </div>
              </Panel>

            </div>
          )}

          {compStep === 2 && (
            // USUARIOS
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "95%",
              }}
            >
              <div className="compartilhar-content-colum1">
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "stretch",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "stretch",
                      justifyContent: "stretch",
                      flexDirection: "column",
                    }}
                  >
                    <label>Usuário</label>
                    <InputPicker
                      style={{ flex: 1 }}
                      placeholder="Usuario"
                      onSelect={(value) =>
                        setCompData((prevState) => ({
                          ...prevState,
                          users: [
                            ...prevState.users,
                            users.find((item) => item.id === value),
                          ],
                        }))
                      }
                      data={users?.map((item) => ({
                        label: `${item?.first_name} ${item?.last_name}`,
                        value: item.id,
                      }))}
                      onSearch={(value) => handleGetUsers(value)}
                      renderMenu={(menu) => {
                        if (users.length === 0) {
                          return (
                            <p
                              style={{
                                padding: 10,
                                color: "#999",
                                textAlign: "center",
                              }}
                            >
                              Nenhum usuário encontrado
                            </p>
                          );
                        }
                        return menu;
                      }}
                    />
                  </div>
                  
                </div>
                <div className="compartilhar-content-colum2">
                  <TagGroup style={{ margin: 5 }}>
                    {compData?.users?.map((item, index) => (
                      <Tag
                        style={{ margin: 5, backgroundColor: "#fff"}}
                        key={index}
                        closable
                        onClose={() =>
                          setCompData((prevState) => ({
                            ...prevState,
                            users: prevState.users.filter(
                              (value) => value.id !== item.id
                            ),
                          }))
                        }
                      >
                        {item.first_name} {item.last_name}
                      </Tag>
                    ))}
                </TagGroup>
              </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "stretch",
                    justifyContent: "stretch",
                    flexDirection: "column",
                  }}
                >
                  <InputPicker
                    style={{ flex: 1 }}
                    placeholder="Permissões"
                    onSelect={(value) =>
                      setCompData((prevState) => ({
                        ...prevState,
                        permission: [
                          ...prevState.permission,
                          permissionArray.find((item) => item.value === value),
                        ],
                      }))
                    }
                    data={permissionArray}
                  />
                </div>
                <div className="compartilhar-content-colum2">
                  <TagGroup style={{ margin: 5 }}>
                    {Array.isArray(compData?.permission) ? (
                      compData.permission.map((item, index) => (
                        <Tag
                          style={{ margin: 5, backgroundColor: "#fff" }}
                          key={index}
                          closable
                          onClose={() =>
                            setCompData((prevState) => ({
                              ...prevState,
                              permission: prevState.permission.filter(
                                (value) => value.value !== item.value
                              ),
                            }))
                          }
                        >
                          {item.label}
                        </Tag>
                      ))
                    ) : (
                      <p>No permissions available</p>
                    )}
                  </TagGroup>
                </div>
                <Input
                  as="textarea"
                  onChange={(value) => setText(value)}
                  rows={3}
                  placeholder="Mensagem"
                />
              </div>              
            </div>
          )}

          {compStep === 3 && (
            // GRUPOS
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "95%",
              }}
            >
              <div className="compartilhar-content-colum1">
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "stretch",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "stretch",
                      justifyContent: "stretch",
                      flexDirection: "column",
                    }}
                  >
                    <label>Grupo</label>
                    <InputPicker
                      style={{ flex: 1 }}
                      placeholder="Grupo"
                      onSelect={(value) =>
                        setCompData((prevState) => ({
                          ...prevState,
                          groups: [
                            ...prevState.groups,
                            groups.find((item) => item.id === value),
                          ],
                        }))
                      }
                      data={groups?.map((item) => ({
                        label: `${item?.nome}`,
                        value: item.id,
                      }))}
                      onSearch={(value) => handleGetGrupo(value)}
                      renderMenu={(menu) => {
                        if (groups.length === 0) {
                          return (
                            <p
                              style={{
                                padding: 10,
                                color: "#999",
                                textAlign: "center",
                              }}
                            >
                              Nenhum grupo encontrado
                            </p>
                          );
                        }
                        return menu;
                      }}
                    />
                  </div>
                  
                </div>
                <div className="compartilhar-content-colum2">
                  <TagGroup style={{ margin: 5 }}>
                    {compData?.groups?.map((item, index) => (
                      <Tag
                        style={{ margin: 5, backgroundColor: "#fff"}}
                        key={index}
                        closable
                        onClose={() =>
                          setCompData((prevState) => ({
                            ...prevState,
                            groups: prevState.groups.filter(
                              (value) => value.id !== item.id
                            ),
                          }))
                        }
                      >
                        {item.nome}
                      </Tag>
                    ))}
                </TagGroup>
              </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "stretch",
                    justifyContent: "stretch",
                    flexDirection: "column",
                  }}
                >
                  <InputPicker
                    style={{ flex: 1 }}
                    placeholder="Permissões"
                    onSelect={(value) =>
                      setCompData((prevState) => ({
                        ...prevState,
                        permission: [
                          ...prevState.permission,
                          permissionArray.find((item) => item.value === value),
                        ],
                      }))
                    }
                    data={permissionArray}
                  />
                </div>
                <div className="compartilhar-content-colum2">
                  <TagGroup style={{ margin: 5 }}>
                    {Array.isArray(compData?.permission) ? (
                      compData.permission.map((item, index) => (
                        <Tag
                          style={{ margin: 5, backgroundColor: "#fff" }}
                          key={index}
                          closable
                          onClose={() =>
                            setCompData((prevState) => ({
                              ...prevState,
                              permission: prevState.permission.filter(
                                (value) => value.value !== item.value
                              ),
                            }))
                          }
                        >
                          {item.label}
                        </Tag>
                      ))
                    ) : (
                      <p>No permissions available</p>
                    )}
                  </TagGroup>
                </div>
                <Input
                  as="textarea"
                  onChange={(value) => setText(value)}
                  rows={3}
                  placeholder="Mensagem"
                />
              </div>              
            </div>
            
          )}

          {compStep === 4 && (
            // FUNÇÕES
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "95%",
              }}
            >
              <div className="compartilhar-content-colum1">
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "stretch",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "stretch",
                      justifyContent: "stretch",
                      flexDirection: "column",
                    }}
                  >
                    <label>Função</label>
                    <InputPicker
                      style={{ flex: 1 }}
                      placeholder="Função"
                      onSelect={(value) =>
                        setCompData((prevState) => ({
                          ...prevState,
                          funcao: [
                            ...prevState.funcao,
                            funcao.find((item) => item.id === value),
                          ],
                        }))
                      }
                      data={funcao?.map((item) => ({
                        label: `${item?.nome}`,
                        value: item.id,
                      }))}
                      onSearch={(value) => handleGetFuncao(value)}
                      renderMenu={(menu) => {
                        if (funcao.length === 0) {
                          return (
                            <p
                              style={{
                                padding: 10,
                                color: "#999",
                                textAlign: "center",
                              }}
                            >
                              Nenhum usuário encontrado
                            </p>
                          );
                        }
                        return menu;
                      }}
                    />
                  </div>
                  
                </div>
                <div className="compartilhar-content-colum2">
                  <TagGroup style={{ margin: 5 }}>
                    {compData?.funcao?.map((item, index) => (
                      <Tag
                        style={{ margin: 5, backgroundColor: "#fff"}}
                        key={index}
                        closable
                        onClose={() =>
                          setCompData((prevState) => ({
                            ...prevState,
                            funcao: prevState.funcao.filter(
                              (value) => value.id !== item.id
                            ),
                          }))
                        }
                      >
                        {item.nome}
                      </Tag>
                    ))}
                </TagGroup>
              </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "stretch",
                    justifyContent: "stretch",
                    flexDirection: "column",
                  }}
                >
                  <InputPicker
                    style={{ flex: 1 }}
                    placeholder="Permissões"
                    onSelect={(value) =>
                      setCompData((prevState) => ({
                        ...prevState,
                        permission: [
                          ...prevState.permission,
                          permissionArray.find((item) => item.value === value),
                        ],
                      }))
                    }
                    data={permissionArray}
                  />
                </div>
                <div className="compartilhar-content-colum2">
                  <TagGroup style={{ margin: 5 }}>
                    {Array.isArray(compData?.permission) ? (
                      compData.permission.map((item, index) => (
                        <Tag
                          style={{ margin: 5, backgroundColor: "#fff" }}
                          key={index}
                          closable
                          onClose={() =>
                            setCompData((prevState) => ({
                              ...prevState,
                              permission: prevState.permission.filter(
                                (value) => value.value !== item.value
                              ),
                            }))
                          }
                        >
                          {item.label}
                        </Tag>
                      ))
                    ) : (
                      <p>No permissions available</p>
                    )}
                  </TagGroup>
                </div>
                <Input
                  as="textarea"
                  onChange={(value) => setText(value)}
                  rows={3}
                  placeholder="Mensagem"
                />
              </div>              
            </div>
            // <div
            //   style={{
            //     display: "flex",
            //     flexDirection: "column",
            //     justifyContent: "flex-start",
            //     alignItems: "flex-start",
            //     width: "95%",
            //   }}
            // >
            //   <div className="compartilhar-content-colum1">
            //     <div
            //       style={{
            //         flex: 1,
            //         display: "flex",
            //         alignItems: "center",
            //         justifyContent: "stretch",
            //         marginBottom: 25,
            //       }}
            //     >
            //       <div
            //         style={{
            //           flex: 1,
            //           display: "flex",
            //           alignItems: "stretch",
            //           justifyContent: "stretch",
            //           flexDirection: "column",
            //         }}
            //       >
            //         <label>Função</label>
            
            //       </div>
            //     </div>
            //     <div
            //       style={{
            //         flex: 1,
            //         display: "flex",
            //         alignItems: "stretch",
            //         justifyContent: "stretch",
            //         flexDirection: "column",
            //         marginBottom: 25,
            //       }}
            //     >
            //       <label>Permissões</label>
            //       <InputPicker
            //         style={{ flex: 1 }}
            //         placeholder="Permissões"
            //         onSelect={(value) =>
            //           setCompData((prevState) => ({
            //             ...prevState,
            //             permission: value,
            //           }))
            //         }
            //         data={permissionArray}
            //       />
            //     </div>
            //     <label>Mensagem</label>
            //     <Input
            //       as="textarea"
            //       onChange={(value) =>
            //         setCompData((prevState) => ({
            //           ...prevState,
            //           text: value.toUpperCase,
            //         }))
            //       }
            //       rows={3}
            //       placeholder="Mensagem"
            //     />
            //   </div>

            //   <div className="compartilhar-content-colum2">
            //     <TagGroup style={{ margin: 5 }}>
            //       {compData?.funcao?.map((item, index) => (
            //         <Tag
            //           style={{ margin: 5 }}
            //           key={index}
            //           closable
            //           onClose={() =>
            //             setCompData((prevState) => ({
            //               ...prevState,
            //               funcao: prevState.funcao.filter(
            //                 (value) => value.id !== item.id
            //               ),
            //             }))
            //           }
            //         >
            //           {item.nome}
            //         </Tag>
            //       ))}
            //     </TagGroup>
            //   </div>
            // </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {compStep != 1 && (
          <>
            <Button onClick={() => setCompStep(1)} appearance="subtle">
              Retornar
            </Button>
            <Button
              onClick={() => handleShareDocument(id)}
              loading={loading}
              appearance="primary"
            >
              Ok
            </Button>
          </>
        )}

        <Button onClick={() => setOpen(false)} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
      <SnackbarProvider />
    </Modal>
  );
};
export default ModalCompartilhar;
