import './app-screen.css';
import React, { useState, useEffect } from "react";
import { FaUsers } from "react-icons/fa";
import { Loader, Tag, Breadcrumb } from 'rsuite';
import { SnackbarProvider } from 'notistack';
import { useAxios } from '../../utils/api/api';
import { useNavigate } from 'react-router-dom';


const AplicativosScreen = () => {
    const api = useAxios();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [aplicativos, setAplicativos] = useState([]);
    const [selectedApp, setSelectedApp] = useState(null);
    const [selectedFrame, setSelectedFrame] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState(['APLICATIVOS']);

    useEffect(() => {
        handleGedAplication();
    }, []);

    const handleGedAplication = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/v1/aplicativo/open-app/')
            setAplicativos(response.data);
            setLoading(false);
        }catch (error) {
            console.log(error);
        }
    };

    const handleCardClick = (app) => {
        if(app.nome === 'GESTÃO DE COMPRAS') {
           navigate('/aplicativos/gestao-compras');
        }
        // if(app.nome === 'GESTÃO DE NÃO CONFORMIDADES') {
        //     navigate('/aplicativos/gestao-nao-conformidades');
        // }
        // if(app.nome === 'GESTÃO DE ARMAZÉM') {
        //     navigate('/aplicativos/gestao-armazem/');
        // }
        setSelectedApp(app);
        setBreadcrumbs(prev => [...prev, app.nome.toUpperCase()]);
    };

    const handleFrameClick = (frame) => {
        if (frame.is_redirect) {
            window.location.href = frame.link;
        } else {
            setSelectedFrame(frame);
            setBreadcrumbs(prev => [...prev, frame.nome]);
        }
    };

   

    return (
        <div className="folder-content">
            <SnackbarProvider maxSnack={3} />
            <div className="folder-row-2">
                <div className='table-documents apps'>
                    {loading ? <Loader center size="md" content="loading" /> : (
                        <div className='cards-container'>
                            {selectedFrame ? (
                                <iframe 
                                    style={{ width: '100%', height: '100vh', border: 'none' }} 
                                    src={selectedFrame.link} 
                                    allowFullScreen 
                                    webkitallowfullscreen="true" 
                                    mozallowfullscreen="true" 
                                    msallowfullscreen="true"
                                />
                            ) : selectedApp ? (
                                selectedApp.frames.map((frame, index) => (
                                    <div className="card" key={index} onClick={() => handleFrameClick(frame)}>
                                        <div className='card-01'>
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                                                <span style={{fontSize: 16, fontWeight: 'bold', marginBottom: 5}}>{frame.nome}</span>
                                                <span>{frame.descricao}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                aplicativos.map((app, index) => (
                                    <div className="card" key={index} onClick={() => handleCardClick(app)}>
                                        <div className='card-01'>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <span>{app.nome.toUpperCase()}</span>
                                            </div>
                                            <div className='card-body'>
                                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', height: '100%', alignItems: 'center' }}>
                                                    <FaUsers className='card-users-icon' />
                                                </div>
                                                <span className='text-02'>{app.aprovador ? app.aprovador : 'SEM GESTOR'}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AplicativosScreen;
