import { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { BASE_URL, ENVIROMENT } from './base';

export const useWS = (urlPath) => {
  const auth = useSelector((state) => state.auth);
  const [isConnected, setIsConnected] = useState(false);
  const [messages, setMessages] = useState([]);
  const ws = useRef(null);
  const baseUrl = ENVIROMENT === 'dev' ? `${BASE_URL}/dev` : BASE_URL;

  const connectWebSocket = useCallback(() => {
    if(auth.token.access){
        const uuid = auth?.uuid;
        const wsUrl = uuid ? `${baseUrl}${urlPath}?uuid=${uuid}` : `${baseUrl}${urlPath}`;

        ws.current = new WebSocket(wsUrl);

        ws.current.onopen = () => {
          setIsConnected(true);
          console.log("Conectado ao WebSocket");
        };

        ws.current.onmessage = (event) => {
          const messageData = JSON.parse(event.data);
          setMessages((prevMessages) => [...prevMessages, messageData]);
        };

        ws.current.onclose = () => {
          setIsConnected(false);
          console.log("Desconectado do WebSocket");

          // setTimeout(connectWebSocket, 3000);
        };

        ws.current.onerror = (error) => {
          console.error("WebSocket Error:", error);
        };
    }
    
  }, [baseUrl, urlPath, auth?.uuid, auth?.token.access]);

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [connectWebSocket]);

  const sendMessage = useCallback(
    (message) => {
      if (ws.current && isConnected) {
        ws.current.send(JSON.stringify(message));
      } else {
        console.error("WebSocket desconectado");
      }
    },
    [isConnected]
  );

  return { sendMessage, messages, isConnected };
};
