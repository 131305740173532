import {
  List,
  Whisper,
  Popover,
  Dropdown,
  Modal,
  Button,
  Loader,
  IconButton,
} from "rsuite";
import "./shared-screen.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RemindIcon from "@rsuite/icons/legacy/Remind";
import DocumentView from "../../components/documentView";
import { IoDocumentOutline } from "react-icons/io5";
import moment from 'moment';
import MoreIcon from '@rsuite/icons/More';
import CustomDrawer from "../../components/drawer";
import { useAxios } from "../../utils/api/api";





const SharedScreen = ({sharedType}) => {
  const api = useAxios();
  const [documentos, setDocumentos] = useState([]);
  const [open, setOpen] = useState(false);
  const [delected, setDeleted] = useState(null);
  const triggerRef = React.useRef();
  const close = () => triggerRef.current.close();
  const[selectDocProps, setSelectDocProps] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openDocument, setOpenDocument] = useState(false);
  const [openSharedProperties, setOpenSharedProperties] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({
    id: null,
    title: null,
    name: null,
  })


  useEffect(() => {
    handleGetShared();
  }, [sharedType]);

  const handleOpenDocument = (rowData) => {
    setSelectedDocument({
      id: rowData?.id,
      title: rowData?.titulo,
      name: rowData?.codigo,
    });
    setOpenDocument(true);
  };

  const handleGetShared = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/api/v1/compartilhamentos/document/`, {params: {sharedType: sharedType}});
      setDocumentos(response.data);
      setLoading(false);
    }catch (error) {
      console.log(error);
    }
  };

  const handleRemoveShared = async () => {
    setLoading(true);
    try {
      const response = await api.delete(`/api/v1/compartilhamentos/document/${delected}/`);
      setOpen(false);
      setDeleted(null);
      handleGetShared();
      setLoading(false);
    }catch (error) {
      console.log(error);
      setLoading(false);
    }
  };






  const MenuPopover = React.forwardRef(({ data, onSelect, ...rest }, ref) => (
    <Popover ref={ref} {...rest} full>
      <Dropdown.Menu onSelect={onSelect}>
        
        <Dropdown.Item eventKey="1" onClick={() => {handleOpenDocument(data.document); close()}}>
          Abrir Documento
        </Dropdown.Item>
        {sharedType === 2 && (
          <Dropdown.Item eventKey="1" onClick={() => {setDeleted(data?.shares[0]?.id); close(); setOpen(true)}}>
            Remover Compartilhamento
          </Dropdown.Item>
        )}
       
      </Dropdown.Menu>
    </Popover>
  ));








  return (
    <div>
      <div className="folder-row-2 compartilhados-container">
        
        <div className="containter-documents-compartilhados">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginTop: "10%",
              }}
            >
              <Loader size="sm" content='loading...' />
            </div>
          ) : documentos?.length > 0 ? (
            
              <div className="containter-table-documents">
                <div className="list-document-titles shared">
                  <div className="list-document-title-item codigo">DOCUMENTO</div>
                  <div className="list-document-title-item">MEMBROS</div>
                  <div className="list-document-title-item">DATA DE COMPARTILHAMENTO</div>
                  <div className="list-document-title-item">VERSÃO</div>
                  <div className="list-document-title-item"></div>
                </div>
              <ul className="list-documents">
                {documentos.map((document) => (
                  <li className="list-documents-data">
                    <div className='list-documentos-content shared'>
                      <div className="list-documents-item custom">
                        <IoDocumentOutline className="list-documents-icon" style={{marginRight: 10}}/>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                          <span className="list-documents-title">{document?.document?.titulo}</span>
                          <span className="list-documents-title">{document.document?.codigo}</span>
                        </div>
                      </div>
                      
                      <div className="list-documents-item shared">
                        <span className="list-documents-title">{document?.shared_members?.length} Membros</span>
                      </div>
                      <div className="list-documents-item shared">
                        <span className="list-documents-title">{moment(document?.data_compartilhamento).format('L')}</span>
                      </div>
                      <div className="list-documents-item shared">
                        <span className="list-documents-title">{document?.document?.versao}</span>
                      </div>
                      <div className="list-documents-item shared">
                        <Whisper
                          ref={triggerRef}
                          placement="bottomEnd"
                          trigger="click"
                          speaker={<MenuPopover data={document}/>}
                        >
                          <IconButton style={{backgroundColor: '#00664e', marginLeft: 5}} icon={<MoreIcon style={{color: '#fff'}}/>} id="btn-filter" appearance='primary'></IconButton> 
                        </Whisper>
                        
                      </div>
                    </div>
                  </li>
                
                ))}
              </ul>
              </div>
            
          ): (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <span>Nenhum documento compartilhado</span>
            </div>
          
          )}
        </div>
      </div>
      <Modal
        backdrop="static"
        role="alertdialog"
        open={open}
        onClose={() => {
          setOpen(false);
          setDeleted(null);
        }}
        size="xs"
      >
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <RemindIcon style={{ color: "#ffb300", fontSize: 50 }} />
          <div style={{ padding: 20 }}>
            Atenção: Os usuários serão removidos da lista de
            compartilhamento imediatamente.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              handleRemoveShared();
            }}
            loading={loading}
            appearance="primary"
          >
            Ok
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              setDeleted(null);
            }}
            appearance="subtle"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <CustomDrawer
        data={selectDocProps}
        open={openSharedProperties} 
        setSelectDocProps={setSelectDocProps}
        setOpen={setOpenSharedProperties}
      />
      <DocumentView  open={openDocument} setOpen={setOpenDocument} id={selectedDocument.id} documentTitle={selectedDocument.title} documentName={selectedDocument.name} />
    </div>
  );
};
export default SharedScreen;
