import './acess-permission.css';
import { useState, useEffect } from 'react';
import { IconButton, Modal, Button, TagPicker, InputPicker, Toggle } from 'rsuite';
import FolderFillIcon from '@rsuite/icons/FolderFill';
import PageIcon from '@rsuite/icons/Page';
import { useSelector } from 'react-redux';
import PlusIcon from '@rsuite/icons/Plus';
import CloseIcon from '@rsuite/icons/Close';
import { useAxios } from '../../utils/api/api';



const permissionList = [
    {
        "label": "Visualizar",
        "value": "can_view"
    },
    {
        "label": "Editar",
        "value": "can_edit"
    },
    {
        "label": "Baixar",
        "value": "can_download"
    },
    {
        "label": "Criar Pasta",
        "value": "can_create_folder"
    },
    {
        "label": "Compartilhar",
        "value": "can_shared_documents"
    }
]



const AcessPermission = ({area_id}) => {
    const api = useAxios();
    const userAuth = useSelector(state => state.auth);
    const [stepActivity, setStepActivity] = useState(0);
    const [dataDocuments, setDataDocuments] = useState([]);
    const [dataFolders, setDataFolders] = useState([]);
    const [originalDocuments, setOriginalDocuments] = useState([]);
    const [originalFolders, setOriginalFolders] = useState([]);
    const [open, setOpen] = useState(false);
    const [folderList, setFolderList] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [selectedPermission, setSelectedPermission] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);


    useEffect(() => {
        if (stepActivity === 0) {
            handleGetPermissions(area_id, null);
        }
    }, [area_id]);

    const handleGetPermissions = async (id, folderName) => {
        try{
            const response = await api.get(`/api/v1/codigo/area/get_area_permissions?area_id=${id}&tipo=folder`)
            const removerGestorData = response?.data[0]?.permissions.filter((item) => parseInt(item?.user?.id) !== parseInt(userAuth?.userId));

            const groupedData = removerGestorData?.reduce((acc, item) => {
                const name = item?.folder?.nome;
                if (!acc[name]) {
                    acc[name] = [];
                }
                acc[name].push(item);
                return acc;
            }, {});
            
            if (folderName){
                
                setDataFolders(prevData => {
                    const updatedData = {...prevData};
                    updatedData[folderName] = groupedData[folderName];
                    return updatedData;
                });
            } else {
                setDataFolders(groupedData);
                setOriginalFolders(groupedData);
            }
        }catch(error){
            console.log(error);
        }
    }




    const handleGetPermissionsDocument = async (id, documentName) => {
        try{
            const response = api.get(`/api/v1/codigo/area/get_area_permissions?area_id=${id}&tipo=document`)
            const removerGestorData = response?.data[0]?.permissions.filter((item) => parseInt(item?.user?.id) !== parseInt(userAuth?.userId));

            const groupedData = removerGestorData?.reduce((acc, item) => {
                const name = item?.document?.codigo;
                if (!acc[name]) {
                    acc[name] = [];
                }
                acc[name].push(item);
                return acc;
            }, {});
    
            if (documentName) {
                setDataDocuments(prevData => {
                    const updatedData = {...prevData};
                    updatedData[documentName] = groupedData[documentName];
                    return updatedData;
                });
            } else {
                setDataDocuments(groupedData);
                setOriginalDocuments(groupedData);
            }

        }catch(error){
            console.log(error);
        }
    }




    useEffect(() => {
        if (open) {
            handleGetFolders();
            handleGetAllUsers();
            handleGetDocumets();
        }
    }, [open]);
    
    const handleGetFolders = async () => {
        try{
            const response = await api.get(`/api/v1/pasta/main/get_folder_area?area_id=${area_id}`);
            const newdata = response.data[0]?.children?.map((item) => {
                return {
                    label: item?.nome,
                    value: item?.id
                }
            })
            setFolderList(newdata);
        }catch(error){
            console.log(error);
        }
    }


    const handleGetDocumets = async () => {
        try{
            const response = await api.get(`/api/v1/documento/get_documet_for_area?area_id=${area_id}&tipo=filtered`);
            const newdata = response.data?.map((item) => {
                return {
                    label: item?.codigo + ' - ' + item?.titulo,
                    value: item?.id
                }
            })
            setDocumentList(newdata);
        }catch(error){
            console.log(error);
        }
    }




    const handleGetAllUsers = async () => {
        try{
            const response = await api.get(`/api/v1/pasta/main/get_all_users`);
            const newdataUsers = response.data.map((item) => {
                return {
                    label: item?.first_name + ' ' + item?.last_name,
                    value: item?.id
                }
            })
            setUserList(newdataUsers);

        }catch(error){
            console.log(error);
        }
    }




    const handlePermissionChange = async (item, field, tipo) => {
        let updatedPermission = null;
        if (tipo === 'folder') {
            updatedPermission = {
                ...item,
                [field]: !item[field],
                folder: item.folder.id,
                user: item.user.id,
                funcao: item.funcao ? item.funcao.id : null,
                contrato: item.contrato ? item.contrato.id : null,
                custom_group: item.custom_group_id
            };
        } else {
            updatedPermission = {
                ...item,
                [field]: !item[field],
                document: item.document.id,
                user: item.user.id,
                funcao: item.funcao ? item.funcao.id : null,
                contrato: item.contrato ? item.contrato.id : null,
                custom_group: item.custom_group_id
            };
        }
        try{
            const response = await api.post(`/api/v2/acess-manager/permissions/create_or_update_permission/?tipo=${tipo}`, updatedPermission);
            if (tipo === 'folder') {
                handleGetPermissions(area_id, item.folder.nome);
            } else {
                handleGetPermissionsDocument(area_id, item.document.codigo);
            }
        }catch(error){
            console.log("Erro ao atualizar a permissão:", error);
        }
    };


    const handleDeletePermission = async (id) => {
        try{
            const response = await api.delete(`/api/v2/acess-manager/permissions/${id}/`);
            if(stepActivity === 0){
                handleGetPermissions(area_id);
            }else{
                handleGetPermissionsDocument(area_id);
            }
        }catch(error){
            console.log("Erro ao deletar a permissão:", error);
        }
    }



    const handleAddPermission = async () => {
        const payload = {
            folder: selectedFolder,
            document: selectedDocument,
            user: selectedUser,
            can_view: selectedPermission.includes('can_view'),
            can_edit: selectedPermission.includes('can_edit'),
            can_download: selectedPermission.includes('can_download'),
            can_create_folder: selectedPermission.includes('can_create_folder'),
            can_shared_documentsfolder: selectedPermission.includes('can_shared_documents'),
        }
        try{
            const response = await api.post(`/api/v2/acess-manager/permissions/`, payload);
            if(stepActivity === 0){
                handleGetPermissions(area_id);
            }else{
                handleGetPermissionsDocument(area_id);
            }
            setOpen(false);

        }catch(error){
            console.log("Erro ao adicionar a permissão:", error);
        }
    }


    const handleFilterChange = (value) => {
        if (stepActivity === 0) {
            if (value.length > 2) {
                const filteredData = {};
                Object.keys(originalFolders).forEach((key) => {
                    const filteredItems = originalFolders[key].filter(
                        (item) =>
                            key.toLowerCase().includes(value.toLowerCase()) ||
                            item.user.first_name.toLowerCase().includes(value.toLowerCase()) ||
                            item.user.last_name.toLowerCase().includes(value.toLowerCase())
                    );
                    if (filteredItems.length > 0) {
                        filteredData[key] = filteredItems;
                    }
                });
                setDataFolders(filteredData);
                
            } else {
                setDataFolders(originalFolders);
            }
        } else {
            if (value.length > 2) {
                const filteredData = {};
                Object.keys(originalDocuments).forEach((key) => {
                    const filteredItems = originalDocuments[key].filter(
                        (item) =>
                            key.toLowerCase().includes(value.toLowerCase()) ||
                            item.user.first_name.toLowerCase().includes(value.toLowerCase()) ||
                            item.user.last_name.toLowerCase().includes(value.toLowerCase())
                    );
                    if (filteredItems.length > 0) {
                        filteredData[key] = filteredItems;
                    }
                });
                setDataDocuments(filteredData);
            } else {
                setDataDocuments(originalDocuments);
            }
        }
    };
    


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', margin: 5, padding: 0 }}>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', padding: 5, margin: 5}}>
                <div>
                    <IconButton onClick={()=>{setStepActivity(0); handleGetPermissions(area_id)}} icon={<FolderFillIcon className={stepActivity === 0 ? 'icon-buttom-activity active' : 'icon-buttom-activity'}/>} className={stepActivity === 0 ? 'icon-buttom-activity active' : 'icon-buttom-activity'} size="xs" style={{marginRight: 5}}>
                    Pastas
                    </IconButton>
                    <IconButton 
                    icon={<PageIcon className={stepActivity === 1 ? 'icon-buttom-activity active' : 'icon-buttom-activity'} />} className={stepActivity === 1 ? 'icon-buttom-activity active' : 'icon-buttom-activity'} size="xs" style={{marginRight: 5}}
                    onClick={()=>{setStepActivity(1); handleGetPermissionsDocument(area_id)}}
                    >
                    Documentos
                    </IconButton>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <input onChange={(e) => handleFilterChange(e.target.value)} type="text" className='input-permission' placeholder="Pesquisar"/>
                    <IconButton title='adicionar' onClick={()=>setOpen(true)} icon={<PlusIcon style={{backgroundColor: '#00664e', color: '#fff'}} />} size="xs" appearance='primary' style={{backgroundColor: '#00664e', marginLeft: 5}}/>
                </div>
            </div>
            <div className="permission-title-container">
                <div className="list-title-item max-title">ITEM</div>
                <div className="list-title-item">VISUALIZAR</div>
                <div className="list-title-item">EDITAR</div>
                <div className="list-title-item">BAIXAR</div>
                <div className="list-title-item">CRIAR PASTA</div>
                <div className="list-title-item">COMPARTILHAR</div>
                <div className="list-title-item">...</div>
            </div>
            {stepActivity === 0 && (
                <>
                {Object.keys(dataFolders).map((folderName, index) => (
                    <div key={index} className="folder-section">
                        <div className="folder-title">{folderName}</div>
                        {dataFolders[folderName].map((item, idx) => (
                            <div key={idx} className="permission-item-container">
                                <div className="permission-item-name">
                                    {item.user.first_name} {item.user.last_name}
                                </div>
                                <div className="permission-checkbox">
                                    <Toggle
                                        checked={item.can_view}
                                        disabled={parseInt(item.user.id) === parseInt(userAuth.userId)}
                                        onChange={() => handlePermissionChange(item, 'can_view', 'folder')}
                                        checkedChildren="Sim"
                                        unCheckedChildren="Não"
                                    />
                                </div>
                                <div className="permission-checkbox">
                                    <Toggle
                                        checked={item.can_edit}
                                        disabled={parseInt(item.user.id) === parseInt(userAuth.userId)}
                                        onChange={() => handlePermissionChange(item, 'can_edit', 'folder')}
                                        checkedChildren="Sim"
                                        unCheckedChildren="Não"
                                    />
                                </div>
                                <div className="permission-checkbox">
                                    <Toggle
                                        checked={item.can_download}
                                        disabled={parseInt(item.user.id) === parseInt(userAuth.userId)}
                                        onChange={() => handlePermissionChange(item, 'can_download', 'folder')}
                                        checkedChildren="Sim"
                                        unCheckedChildren="Não"
                                    />
                                </div>
                                <div className="permission-checkbox">
                                    <Toggle
                                        checked={item.can_create_folder}
                                        disabled={parseInt(item.user.id) === parseInt(userAuth.userId)}
                                        onChange={() => handlePermissionChange(item, 'can_create_folder', 'folder')}
                                        checkedChildren="Sim"
                                        unCheckedChildren="Não"
                                    />
                                </div>
                                <div className="permission-checkbox">
                                    <Toggle
                                        checked={item.can_shared_documents}
                                        disabled={parseInt(item.user.id) === parseInt(userAuth.userId)}
                                        onChange={() => handlePermissionChange(item, 'can_shared_documents', 'folder')}
                                        checkedChildren="Sim"
                                        unCheckedChildren="Não"
                                    />
                                </div>
                                <div className="permission-checkbox">
                                    <IconButton
                                        title="remover"
                                        onClick={() => handleDeletePermission(item?.id)}
                                        disabled={parseInt(item.user.id) === parseInt(userAuth.userId)}
                                        icon={<CloseIcon style={{ color: '#fff' }} />}
                                        appearance="primary"
                                        size="xs"
                                        style={{ backgroundColor: 'red' }}
                                    />
                                </div>
                            </div>

                        ))}
                    </div>
                ))}
                </>
            )}

            {stepActivity === 1 && (
               <>
               {Object.keys(dataDocuments).map((documentName, index) => (
                   <div key={index} className="folder-section">
                       <div className="folder-title">{documentName}</div>
                       {dataDocuments[documentName].map((item, idx) => (
                           <div key={idx} className="permission-item-container">
                                <div className="permission-item-name">
                                    {item.user.first_name} {item.user.last_name}
                                </div>
                                <div className="permission-checkbox">
                                    <Toggle
                                        
                                        checked={item.can_view}
                                        disabled={parseInt(item.user.id) === parseInt(userAuth.userId)}
                                        onChange={() => handlePermissionChange(item, 'can_view', 'document')}
                                        checkedChildren="Sim"
                                        unCheckedChildren="Não"
                                    />
                                </div>
                                <div className="permission-checkbox">
                                    <Toggle
                                        
                                        checked={item.can_edit}
                                        disabled={parseInt(item.user.id) === parseInt(userAuth.userId)}
                                        onChange={() => handlePermissionChange(item, 'can_edit', 'document')}
                                        checkedChildren="Sim"
                                        unCheckedChildren="Não"
                                    />
                                </div>
                                <div className="permission-checkbox">
                                    <Toggle
                                        
                                        checked={item.can_download}
                                        disabled={parseInt(item.user.id) === parseInt(userAuth.userId)}
                                        onChange={() => handlePermissionChange(item, 'can_download', 'document')}
                                        checkedChildren="Sim"
                                        unCheckedChildren="Não"
                                    />
                                </div>
                                <div className="permission-checkbox">
                                    <Toggle
                                        
                                        checked={item.can_create_folder}
                                        disabled={true}
                                        checkedChildren="Sim"
                                        unCheckedChildren="Não"
                                    />
                                </div>
                                <div className="permission-checkbox">
                                    <Toggle
                                        
                                        checked={item.can_shared_documents}
                                        disabled={parseInt(item.user.id) === parseInt(userAuth.userId)}
                                        onChange={() => handlePermissionChange(item, 'can_shared_documents', 'document')}
                                        checkedChildren="Sim"
                                        unCheckedChildren="Não"
                                    />
                                </div>
                                <div className="permission-checkbox">
                                    <IconButton
                                        title="remover"
                                        onClick={() => handleDeletePermission(item?.id)}
                                        disabled={parseInt(item.user.id) === parseInt(userAuth.userId)}
                                        icon={<CloseIcon style={{ color: '#fff' }} />}
                                        appearance="primary"
                                        size="xs"
                                        style={{ backgroundColor: 'red' }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
               ))}
               </> 
            )}

            <Modal open={open} onClose={()=>setOpen(false)}>
                <Modal.Header>
                <Modal.Title>Adicionar Permissão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{display: 'flex', justifyContent: 'stretch', width: '100%', flexDirection: 'column'}}>
                        <div style={{margin: 5}}>
                            {stepActivity === 0 && (
                                <>
                                <label>Pasta</label>
                                <InputPicker placeholder="Selecione uma pasta" onSelect={(value)=>setSelectedFolder(value)} style={{width: '100%'}} data={folderList}/>
                                </>
                            )}
                            {stepActivity === 1 && (
                                <>
                                <label>Documento</label>
                                <InputPicker placeholder="Selecione um documento" onSelect={(value)=>setSelectedDocument(value)} style={{width: '100%'}} data={documentList}/>
                                </>
                            )}
                        </div>
                        <div style={{margin: 5}}>
                            <label>Usuário</label>
                            <InputPicker placeholder="Selecione o usuário" onSelect={(value)=>setSelectedUser(value)} style={{width: '100%'}} data={userList}/>
                        </div>
                        <div style={{margin: 5}}>
                            <label>Permissões</label>
                            <TagPicker onChange={(value) => setSelectedPermission(value)}  placeholder="Adicionar Permissões" data={permissionList} style={{width: '100%'}}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={()=>handleAddPermission()} appearance="primary">
                    Ok
                </Button>
                <Button onClick={()=>setOpen(false)} appearance="subtle">
                    Cancel
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default AcessPermission;