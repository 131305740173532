import { Drawer, ButtonToolbar, Button, Placeholder } from 'rsuite';

const CustomDrawer = ({data, open, setOpen, setSelectDocProps}) => {
    console.log(data)

  return (
    <Drawer open={open} backdrop='static' onClose={() => {setOpen(false); setTimeout(()=>setSelectDocProps(null), 500)}}>
        <Drawer.Header>
            <Drawer.Title>
                <span style={{color: '#fff'}}>{data?.document?.codigo}</span>
            </Drawer.Title>
            <Drawer.Actions>
                
            </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
            
        </Drawer.Body>
    </Drawer>
    
  );
};
export default CustomDrawer;