import { Drawer, Form, InputPicker, Button, Divider, Tag } from "rsuite";
import { useState, useEffect } from "react";
import "./drawer-reserva.css";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../utils/api/base";
import { CreateTemplate } from "../../utils/templates/templates";
import moment from "moment";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useAxios } from "../../utils/api/api";


const template = [
    {
        label: 'DOCX',
        value: 'DOCX'
    },
    {
        label: 'XLSX',
        value: 'XLSX'
    },
    {
        label: 'BPM',
        value: 'BPM'
    }
]

const privacidade = [
    {
        label: 'Publico',
        value: 0
    },
    {
        label: 'Restrita',
        value: 1
    },
    {
        label: 'Confidencial',
        value: 2
    },
    {
        label: 'Uso Interno',
        value: 3
    }
]






const DrawerReserva = ({showDrawer,setShowDrawer,loader,setLoading,getDocuments, documents}) => {
  const api = useAxios();
  const [step, setStep] = useState(1);
  const user = useSelector((state) => state.auth);
  const [seachLoading, setSearchLoading] = useState(false);
  const [documento, setDocumento] = useState([]);
  const [area, setArea] = useState([]);
  const [especialidade, setEspecialidade] = useState([]);
  const [funcao, setFuncao] = useState([]);
  const[processo, setProcesso] = useState([]);
  const[folder, setFolder] = useState([]);
  const[is_opeSubProcesso, setIs_opeSubProcesso] = useState(false)
  const [subProcesso, setSubProcesso] = useState([])
  const[autoblock, setAutoBlock] = useState({
    case1: false,
    case2: false,
    case3: false,
    case4: false
  })
  const [formData, setFormData] = useState({
    titulo: "",
    tipo: "",
    area: "",
    especialidade: "",
    template: "",
    processo: "",
    subProcesso: "",
    privacidade: "",
    funcao: [],
    codigo_area: "",
    codigo_tipo: "",
    codigo_especialidade: "",
    status: "0",
    add_seguranca: false,
    autorizador: false,
    add_juridico: false,
    add_marketing: false,
    folder: "",
    descricao: ""  

  });
  const [selectedTemplate, setSelectedTemplate] = useState(null);


  const handleNext = () => {
    if (step === 1) {
      if (formData.titulo && formData.tipo && formData.area && formData.especialidade && formData.template) {
        setStep(step + 1);
      }else{
        enqueueSnackbar(<span style={{fontSize: 12}}>Preencha Todos os Campos</span>, {
          variant: "error",
      });
      }
    }else if(step === 2){
      if(formData.privacidade || formData.privacidade === 0){
        setStep(step + 1);
      }else{
        enqueueSnackbar(<span style={{fontSize: 12}}>Preencha Todos os Campos</span>, {
          variant: "error",
        });
      }
    }else if(step === 3){
      if(formData.folder){
        handlePostReserva()
      }else{
        enqueueSnackbar(<span style={{fontSize: 12}}>Preencha Todos os Campos</span>, {
          variant: "error",
      });
      }
    }
    
  };

  const handleBack = (value) => {
    if (value > 1) {
      setStep(value - 1);
    }
  };




  useEffect(()=>{
    handleDocumentoType()
    handleDocumentoArea()
    handleDocumentoESP()
    handleFuncao()
    handleFolter()
  },[])
  

  const handleFolter = () => {
    axios({
        method: 'GET',
        url: `${BASE_URL}/api/v1/pasta/main/all_folders/`,
        headers: {
            'Authorization': `Bearer ${user.token.access}`,
        },
        params: {
            all: true,
        }
    })
        .then((response) => {
            setFolder(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
};



  const handleDocumentoType = () => {
    axios({
        method: "GET",
        url: `${BASE_URL}/api/v1/codigo/tipo/`,
        headers: {
          Authorization: `Bearer ${user.token.access}`,
        },
        
    }).then((response) => {
        const sortedData = response.data.sort((a, b) => a.nome.localeCompare(b.nome));
        setDocumento(sortedData);
    }).catch((error) => {
        console.log(error);
    })
  }

  const handleDocumentoArea = () => {
    axios({
        method: "GET",
        url: `${BASE_URL}/api/v1/codigo/area/`,
        headers: {
          Authorization: `Bearer ${user.token.access}`,
        },
        
    }).then((response) => {
        const sortedData = response.data.sort((a, b) => a.nome.localeCompare(b.nome));
        setArea(sortedData);
    }).catch((error) => {
        console.log(error);
    })
  }

  const handleDocumentoESP = () => {
    axios({
        method: "GET",
        url: `${BASE_URL}/api/v1/codigo/espacialidade/`,
        headers: {
          Authorization: `Bearer ${user.token.access}`,
        },
        
    }).then((response) => {
        const sortedData = response.data.sort((a, b) => a.nome.localeCompare(b.nome));
        setEspecialidade(sortedData);
    }).catch((error) => {
        console.log(error);
    })
  }


  const handleFuncao = async () => {
    try {
      const response = await api.get(`/api/v2/membros/funcao/`);
      const sortedData = response.data.sort((a, b) => a.nome.localeCompare(b.nome));
      sortedData.unshift({ nome: "SELECIONAR TODOS", id: 0 });
      setFuncao(sortedData);
    }catch(error){
      console.log(error)
    }

  }



  const handleProcessoArea = (area) => {
    axios({
        method: "GET",
        url: `${BASE_URL}/api/v1/processo/get_filtered_processos/?area_id=${area}`,
        headers: {
            Authorization: `Bearer ${user.token.access}`,
        },
    }).then((response) => {
        setProcesso(response.data);
    }).catch((error) => {
        console.log(error);
    });
  }


  const handleSubProcesso = (id) => {
    let parent_id = processo.find(item => item.id === id)
    let tipo_data = documento.find((item) => item.id === formData.tipo);
    if(parent_id?.children?.length > 0 && !tipo_data?.nome.includes('DEIP')){
      setSubProcesso(parent_id.children)
      setIs_opeSubProcesso(true)
      return
    }else{
      setIs_opeSubProcesso(false)
      setFormData({...formData, processo: id})
    }
    
  }


  const autoSelectTemplate = (value) => {
    const tipo_filter = documento.find((item) => item.id === value);
    const tipo = tipo_filter?.nome.split(' - ')[0] || '';
    
    let selectedValue = '';
    switch (tipo) {
      case 'MAP':
        selectedValue = 'BPM';
        break;
      case 'SLA':
      case 'RACI':
      case 'DEIP':
        selectedValue = 'XLSX';
        break;
      default:
        selectedValue = '';
        break;
    }
    console.log(selectedValue)
    setFormData({ ...formData, template: selectedValue });
  };

  useEffect(() => {
    autoSelectTemplate(formData.tipo);
  }, [formData.tipo]);



  const selectDocument = (value) => {
    const tipo_filter = documento.find((item) => item.id === value);
    const tipo = tipo_filter?.nome.split(' - ')[0] || '';

    let newFormData = { ...formData, tipo: value };


    const showSnackbar = (message, delay = 0) => {
        setTimeout(() => {
            enqueueSnackbar(<span style={{ fontSize: 12 }}>{message}</span>, { variant: "warning" });
        }, delay);
    };

    const updateAutoBlock = (updates) => {
        setAutoBlock({ ...autoblock, ...updates });
    };
    switch (tipo) {
        
        case 'NRA':
            newFormData = { ...newFormData, autorizador: true, add_juridico: true, add_marketing: false, add_seguranca: false, };
            updateAutoBlock({ case1: true, case2: true, case3: false, case4: false });
            showSnackbar("Departamento Jurídico Incluido no workFlow");
            showSnackbar("Diretoria da Area Incluida no workFlow", 500);
            break;

        case 'DEIP':
          newFormData = { ...newFormData, autorizador: true, add_juridico: false, add_marketing: false, add_seguranca: false};
          updateAutoBlock({ case1: true, case2: false, case3: false, case4: false });
          showSnackbar("Diretoria da Area Incluida no workFlow", 500);
          break;

        case 'ITR':
        case 'POP':
        case 'MTR':  
            newFormData = { ...newFormData, add_seguranca: true, add_juridico: false, autorizador: false, add_marketing: false};
            updateAutoBlock({ case3: true, case2: false, case1: false, case4: false });
            showSnackbar("Departamento de Segurança Incluido no workFlow");
            break;

        case 'POL':
            newFormData = { ...newFormData, add_marketing: true, add_juridico: true, autorizador: true, add_seguranca: false };
            updateAutoBlock({ case4: true, case2: true, case1: true, case3: false });
            showSnackbar("Departamento de Marketing Incluido no workFlow");
            showSnackbar("Departamento Jurídico Incluido no workFlow", 500);
            showSnackbar("Diretoria da Area Incluida no workFlow", 800);
            break;

        default:
            break;
    }

    setFormData(newFormData);
};






  const handlePostReserva = () => {





    var area_filter = area.filter((item)=>item.id === formData.area)
    var area_name = area_filter[0].nome.split(' - ')[0]
    
    var especialidade_filter = especialidade.filter((item)=>item.id === formData.especialidade)
    var especialidade_name = especialidade_filter[0].nome.split(' - ')[0]

    var tipo_filter = documento.filter((item)=>item.id === formData.tipo)
    var tipo_name = tipo_filter[0].nome.split(' - ')[0]

    formData.codigo_area = area_name
    formData.codigo_tipo = tipo_name
    formData.codigo_especialidade = especialidade_name
    
    setSearchLoading(true)
    formData.funcao = formData.funcao.map(item=>item.id)





    axios({
        method: "POST",
        url: `${BASE_URL}/api/v1/flow/document-reserve/`,
        headers: {
            Authorization: `Bearer ${user.token.access}`,
        },
        data:formData
    }).then((response) => {
        getDocuments('is_user');
        setTimeout(() => {
            setShowDrawer(false)
            setSearchLoading(false)
            CreateTemplate(response.data?.tipo_detail?.nome, response.data.codigo, response.data.titulo, response.data.template, moment(response.data.data_reserva).format('DD/MM/YYYY'), response.data.privacidade, tipo_name)
            setFormData({
                titulo: "",
                tipo: "",
                area: "",
                especialidade: "",
                template: "",
                processo: "",
                privacidade: "",
                funcao: [],
                codigo_area: "",
                codigo_tipo: "",
                codigo_especialidade: "",
                status: "0",
                add_seguranca: false,
                autorizador: false,
                add_juridico: false,
                folder: "",
                descricao: ""


            })
            setStep(1)
        }, 1000);
    }).catch((error) => {
        setSearchLoading(false)
        console.log(error);
    })
  }


  const handleRemoveFuncao = (id) => {
    let fn = formData.funcao?.filter(list => list.id !== id)
    setFormData({...formData, funcao: fn})
  };

  const handleSelectFuncao = (value) => {
    if(value === 0) {
      setFormData({
        ...formData,
        funcao: funcao.filter(item => item.id !== 0),
      });
    }else{
      setFormData({...formData, funcao: [...formData.funcao, {
        nome: funcao.find(item => item.id === value).nome,
        id: value
      }]})
    }
   
  };


  return (
    <div>
      <SnackbarProvider 
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      
      />
      <Drawer
        backdrop="static"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        style={{ zIndex: 2000 }}
      >
        <Drawer.Header style={{backgroundColor: '#00664e'}}>
          <Drawer.Title style={{color: '#fff'}}>Iniciar reserva</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body className="drawer-body">
          <div>
            <strong className="form-title">
              {step === 1 ? "DESCRIÇÃO DO DOCUMENTO" : step === 2 ? "CONTROLE DE ACESSO" : "DEFINIÇÃO DE WORKFLOW"}
            </strong>
            <Divider style={{ marginTop: 5 }} />
          </div>

          <Form fluid>
            {step === 1 && (
              <>
                <Form.Group controlId="title">
                  <Form.ControlLabel className="form-label">
                    Titulo
                  </Form.ControlLabel>
                  <Form.Control
                    className="form-item"
                    name="titulo"
                    id="titulo"
                    type="text"
                    placeholder="Titulo do documento"
                    value={formData.titulo}
                    onChange={(e) => setFormData({ ...formData, titulo: e.toUpperCase() })}
                  />
                  
                </Form.Group>

                <Form.Group controlId="title">
                  <Form.ControlLabel className="form-label">
                    Descrição
                  </Form.ControlLabel>
                  <Form.Control
                    className="form-item"
                    name="descricao"
                    id="descricao"
                    type="text"
                    placeholder="Descrição do documento"
                    value={formData.descricao}
                    onChange={(e) => setFormData({ ...formData, descricao: e.toUpperCase() })}
                  />
                  
                </Form.Group>


                <Form.Group controlId="documento">
                  <Form.ControlLabel className="form-label">
                    Documento
                  </Form.ControlLabel>
                  <Form.Control
                    name="documento"
                    className="form-item"
                    id="documento"
                    block
                    accepter={InputPicker}
                    onSearch={(e) => handleDocumentoType(e.toUpperCase())}
                    data={documento?.map(item => ({ label: item.nome, value: item.id }))}
                    onSelect={(e)=>selectDocument(e)}
                    value={formData.tipo}
                    placeholder="Selecione o documento"
                    menuStyle={{
                      maxHeight: "250px",
                      wordBreak: "break-word",
                      maxWidth: 200,
                      zIndex: 3000,
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="area">
                  <Form.ControlLabel className="form-label">
                    Area
                  </Form.ControlLabel>
                  <Form.Control
                    name="area"
                    className="form-item"
                    id="area"
                    block
                    accepter={InputPicker}
                    data={area?.map(item => ({ label: item.nome, value: item.id }))}
                    onSelect={(e)=>{setFormData({...formData, area: e}); handleProcessoArea(e)}}
                    value={formData.area}
                    placeholder="Selecione a area"
                    menuStyle={{
                      maxHeight: "250px",
                      wordBreak: "break-word",
                      maxWidth: 200,
                      zIndex: 3000,
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel className="form-label">
                    Especialidade
                  </Form.ControlLabel>
                  <Form.Control
                    name="especialidade"
                    className="form-item"
                    id="especialidade"
                    block
                    data={especialidade?.map(item => ({ label: item.nome, value: item.id }))}
                    onSelect={(e)=>setFormData({...formData, especialidade: e})}
                    value={formData.especialidade}
                    accepter={InputPicker}
                    placeholder="Selecione a especialidade"
                    menuStyle={{
                      maxHeight: "200px",
                      wordBreak: "break-word",
                      maxWidth: 200,
                      zIndex: 3000,
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel className="form-label">
                    Template
                  </Form.ControlLabel>
                  <Form.Control
                    name="template"
                    className="form-item"
                    id="template"
                    block
                    data={template.map(item=>({label: item.label, value: item.value}))}
                    onSelect={(e)=>setFormData({...formData, template: e})}
                    value={formData.template}
                    accepter={InputPicker}
                    placeholder="Selecione a template"
                    menuStyle={{
                      maxHeight: "200px",
                      wordBreak: "break-word",
                      maxWidth: 200,
                      zIndex: 3000,
                    }}
                  />
                </Form.Group>
              </>
            )}
            {step === 2 && (
              <>
                <Form.Group>
                  
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Form.ControlLabel className="form-label">
                      Processo
                    </Form.ControlLabel>
                    <Form.HelpText tooltip className="helper-text" style={{zIndex: 3000}}>O Processo Que o Documento Esta Incluido</Form.HelpText>
                  </div>
                  <Form.Control
                      name="processo"
                      className="form-item"
                      id="processo"
                      block
                      data={processo?.map(item => ({ label: item.nome, value: item.id }))}
                      onSelect={(e)=>{handleSubProcesso(e)}}
                      accepter={InputPicker}
                      placeholder="Selecione o processo"
                      menuStyle={{
                        maxHeight: "200px",
                        wordBreak: "break-word",
                        maxWidth: 200,
                        zIndex: 3000,
                      }}
                    />
                </Form.Group>
                {is_opeSubProcesso && (
                  <Form.Group>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <Form.ControlLabel className="form-label">
                        Sub-Processo
                      </Form.ControlLabel>
                    </div>
                    <Form.Control
                        name="subprocesso"
                        className="form-item"
                        id="subprocesso"
                        block
                        data={subProcesso?.map(item => ({ label: item.nome, value: item.id }))}
                        onSelect={(e)=>setFormData({...formData, processo: e})}
                        accepter={InputPicker}
                        placeholder="Selecione o sub-processo"
                        menuStyle={{
                          maxHeight: "200px",
                          wordBreak: "break-word",
                          maxWidth: 200,
                          zIndex: 3000,
                        }}
                      />
                  </Form.Group>
                )}
                
                

                <Form.Group>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Form.ControlLabel className="form-label">
                      Privacidade do documento
                    </Form.ControlLabel>
                    <Form.HelpText tooltip className="helper-text" style={{zIndex: 3000}}>
                      Nivel de confidencialidade do documento
                      <br />
                      - Publico: Todos podem acessar
                      <br />
                      - Restrita: Apenas pessoas autorizadas
                      <br />
                      - Confidencial: Apenas pessoas pontuais
                      <br />
                      - Uso Interno: Todos da empresa

                    </Form.HelpText>
                  </div>
                  <Form.Control
                    name="privacidade"
                    className="form-item"
                    id="privacidade"
                    block
                    data={privacidade?.map(item => ({ label: item.label, value: item.value }))}
                    onSelect={(e)=>setFormData({...formData, privacidade: e})}
                    value={formData.privacidade}
                    accepter={InputPicker}
                    placeholder="Confidencialidade do documento"
                    menuStyle={{
                      maxHeight: "200px",
                      wordBreak: "break-word",
                      maxWidth: 200,
                      zIndex: 3000,
                    }}
                  />
                </Form.Group>


                <Form.Group>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Form.ControlLabel className="form-label">
                    Função
                    </Form.ControlLabel>
                    <Form.HelpText tooltip className="helper-text" style={{zIndex: 3000}}>
                      O cargo do funcionario que tera acesso ao documento, caso seja de uso interno
                    </Form.HelpText>
                  </div>
                  <Form.Control
                    name="funcao"
                    className="form-item"
                    id="funcao"
                    block
                    data={funcao.map(item=>({label: item.nome, value: item.id}))}
                    onSelect={(value)=> handleSelectFuncao(value)}
                    accepter={InputPicker}
                    placeholder="Selecione a função"
                    menuStyle={{
                      maxHeight: "200px",
                      wordBreak: "break-word",
                      maxWidth: 200,
                      zIndex: 3000,
                    }}
                  />
                </Form.Group>
                <div className="funcao-list">
                  {formData.funcao.length > 20 ? (
                      <div>
                        <Tag closable onClose={()=>setFormData({...formData, funcao:[]})}>TODAS AS FUNÇÕES INCLUIDAS</Tag>
                      </div>
                  ): (
                    <>
                    {formData.funcao.map((item, index)=>(
                      <div key={index} style={{margin: 5}}>
                        <Tag closable onClose={()=>handleRemoveFuncao(item.id)}>{item.nome}</Tag>
                      </div>
                    ))}
                    </>
                  )}

                  
                </div>
              </>
            )}

            {step === 3 && (
              <>
                <Form.Group>
                  
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Form.ControlLabel className="form-label">
                      Pasta
                    </Form.ControlLabel>
                  </div>
                  <Form.Control
                      name="pasta"
                      className="form-item"
                      id="pasta"
                      block
                      data={folder?.map(item => ({ label: item.nome, value: item.id }))}
                      onSelect={(e)=>{setFormData({...formData, folder: e})}}
                      value={formData.folder}
                      accepter={InputPicker}
                      placeholder="Selecione a pasta"
                      menuStyle={{
                        maxHeight: "200px",
                        wordBreak: "break-word",
                        maxWidth: 200,
                        zIndex: 3000,
                      }}
                    />
                    {formData.folder && (
                      <Form.HelpText  className="helper-text" style={{zIndex: 3000, marginLeft: 4}}>
                        Gestor: {
                          folder.find(item => item.id === formData.folder).gestor === null || folder.find(item => item.id === formData.folder).gestor === undefined ? 'PASTA SEM GESTOR' : folder.find(item => item.id === formData.folder).gestor.first_name + ' ' + folder.find(item => item.id === formData.folder).gestor.last_name
                        }
                      </Form.HelpText>
                    )}
                </Form.Group>
                
                

                <Form.Group>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Form.ControlLabel className="form-label">
                      Solicitar Validação do Departamento de Segurança.
                    </Form.ControlLabel>
                  </div>
                  <Form.Control
                    name="seguranca"
                    className="form-item"
                    id="seguranca"
                    block
                    data={
                      [
                        {label: 'Sim', value: true},
                        {label: 'Não', value: false}
                      ]
                    }
                    onSelect={(e)=>setFormData({...formData, add_seguranca: e})}
                    value={formData.add_seguranca}
                    disabled={autoblock.case3}
                    accepter={InputPicker}
                    placeholder="Incluir Segurança"
                    menuStyle={{
                      maxHeight: "200px",
                      wordBreak: "break-word",
                      maxWidth: 200,
                      zIndex: 3000,
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Form.ControlLabel className="form-label">
                      Solicitar Validação do Departamento de Marketing.
                    </Form.ControlLabel>
                  </div>
                  <Form.Control
                    name="marketing"
                    className="form-item"
                    id="marketing"
                    block
                    data={
                      [
                        {label: 'Sim', value: true},
                        {label: 'Não', value: false}
                      ]
                    }
                    onSelect={(e)=>setFormData({...formData, add_marketing: e})}
                    value={formData.add_marketing}
                    disabled={autoblock.case4}
                    accepter={InputPicker}
                    placeholder="Incluir Marketing"
                    menuStyle={{
                      maxHeight: "200px",
                      wordBreak: "break-word",
                      maxWidth: 200,
                      zIndex: 3000,
                    }}
                  />
                </Form.Group>
                
                <Form.Group>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Form.ControlLabel className="form-label">
                    Solicitar Validação do Departamento Juridico.
                    </Form.ControlLabel>
                  </div>
                  <Form.Control
                    name="juridico"
                    className="form-item"
                    id="juridico"
                    block
                    data={
                      [
                        {label: 'Sim', value: true},
                        {label: 'Não', value: false}
                      ]
                    }
                    onSelect={(e)=>setFormData({...formData, add_juridico: e})}
                    accepter={InputPicker}
                    disabled={autoblock.case2}
                    value={formData.add_juridico}
                    placeholder="Incluir o Juridico"
                    menuStyle={{
                      maxHeight: "200px",
                      wordBreak: "break-word",
                      maxWidth: 200,
                      zIndex: 3000,
                    }}
                  />
                </Form.Group>
                <Form.Group>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Form.ControlLabel className="form-label">
                    Solicitar Aprovação da Diretoria.
                    </Form.ControlLabel>
                  </div>
                  <Form.Control
                    name="autorizador"
                    className="form-item"
                    id="autorizador"
                    block
                    data={
                      [
                        {label: 'Sim', value: true},
                        {label: 'Não', value: false}
                      ]
                    }
                    onSelect={(e)=>setFormData({...formData, autorizador: e})}
                    accepter={InputPicker}
                    disabled={autoblock.case1}
                    value={formData.autorizador}
                    placeholder="Incluir Diretoria"
                    menuStyle={{
                      maxHeight: "200px",
                      wordBreak: "break-word",
                      maxWidth: 200,
                      zIndex: 3000,
                    }}
                  />
                </Form.Group>
              </>
            )}
          </Form>
        </Drawer.Body>
        <div className="drawer-footer">
          <div>
            <Button
              onClick={() => handleBack(step)}
              appearance="primary"
              style={{ marginRight: 10, backgroundColor: "gray" }}
            >
              Voltar
            </Button>
            <Button
                onClick={() => handleNext()}
                loading={seachLoading}
                style={{ backgroundColor: step < 3 ? "" : "#077642d5" }}
                appearance="primary"
            >
              {step < 3 ? "Proximo" : "Concluir"}
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
export default DrawerReserva;
