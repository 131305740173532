import './aprovacao-screen.css';
import { Loader } from 'rsuite';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DocumentView from '../../components/documentView';
import { IoDocumentOutline } from "react-icons/io5";
import { useAxios } from '../../utils/api/api';

const AprovScreen = ({tipo, setTipo}) => {
    const api = useAxios()
    const userAuth = useSelector(state => state.auth)
    const[documentos, setDocumentos] = useState([])
    const[loading, setLoading] = useState(false)
    const[selectedDocument, setSelectedDocument] = useState({
        id: null,
        title: null,
        name: null
    })
    const[openDocument, setOpenDocument] = useState(false)



    useEffect(() => {
        if(tipo == 1){
            handleGetShared()
        }
    },[openDocument])


    useEffect(() => {
        handleGetShared()
    }, [tipo])

   

    const handleGetShared = async () => {
        setLoading(true)
        try {
            const response = await api.get(`/api/v1/flow/document-approval/?user_id=${userAuth.userId}&tipo=${tipo}`)
            setDocumentos(response.data)
            setLoading(false)
        }catch(error){
            console.log(error)
            setLoading(false)
        }
        
    }

    const renterDocumentStatus = ({document, className}) =>{
        if(document.status == '0'){
            return <span className={className}  style={{color: 'orange'}}>PENDENTE</span>
        }else if(document.status == '2'){
            return <span className={className} style={{color: 'red'}}>REPROVADO</span>
        }
        else{
            return <span className={className} style={{color: 'green'}}>APROVADO</span>
        }
    }


    useEffect(() => {
        const handleScroll = (event) => {
            const stickyHeader = document.querySelector('.input-table-folder-01');
            const stickyList = document.querySelector('.list-document-titles.shared');
            const headerRect = stickyHeader.getBoundingClientRect();
            if (headerRect.top < 0){
              stickyList.classList.add('sticky-top');
            }else{
              stickyList.classList.remove('sticky-top')
            }
    
        };
    
        const scrollableElements = document.querySelectorAll('.layout-main');
    
        scrollableElements.forEach(element => {
            element.addEventListener('scroll', handleScroll);
        });
    
        return () => {
            scrollableElements.forEach(element => {
                element.removeEventListener('scroll', handleScroll);
            });
        };
      }, []);


    return(
        <div>
            <div className="folder-row-2 compartilhados-container aprovacao" id="my-first-step-04">
            <div className="containter-documents-compartilhados">
            
            {loading ? (
                <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    marginTop: "10%",
                }}
                >
                <Loader size="sm" content='loading...' />
                </div>
            ) : documentos?.length > 0 ? (
                
                <div className="containter-table-documents">
                    <div className="list-document-titles shared">
                    <div className="list-document-title-item codigo">DOCUMENTO</div>
                    <div className="list-document-title-item">AUTOR</div>
                    <div className="list-document-title-item">DATA DA CRIAÇÃO</div>
                    <div className="list-document-title-item">VERSÃO</div>
                    <div className="list-document-title-item">STATUS</div>
                </div>
                <ul className="list-documents">
                    {documentos.map((document) => (
                    <li className="list-documents-data" onClick={()=>{setOpenDocument(true); setSelectedDocument({id: document.document.id, title: document.document.titulo, name: document.document.codigo})}}>
                        <div className='list-documentos-content shared'>
                        <div className="list-documents-item custom">
                            <IoDocumentOutline className="list-documents-icon" style={{marginRight: 10}}/>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                            <span className="list-documents-title" style={{color: '#000'}}>{document?.document?.titulo}</span>
                            <span className="list-documents-title" style={{fontSize: 11}}>{document.document?.codigo}</span>
                            </div>
                        </div>
                        
                        <div className="list-documents-item shared">
                            <span className="list-documents-title">{document?.document?.autor?.first_name} {document?.document?.autor?.last_name}</span>
                        </div>
                        <div className="list-documents-item shared">
                            <span className="list-documents-title">{moment(document?.document?.data_criacao).format('L')}</span>
                        </div>
                        <div className="list-documents-item shared">
                            <span className="list-documents-title">{document?.document?.versao}</span>
                        </div>

                        <div className="list-documents-item shared">
                            {
                                renterDocumentStatus({
                                    document: document?.document,
                                    className: 'list-documents-title'
                                })
                            }
                        </div>
                        
                        </div>
                    </li>
                    
                    ))}
                </ul>
                </div>
                
            ): (
                <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}
                >
                <span>Nenhum documento encontrado</span>
                </div>
            
            )}
            </div>
            </div>
            <DocumentView  open={openDocument} setOpen={setOpenDocument} id={selectedDocument.id} documentTitle={selectedDocument.title} documentName={selectedDocument.name} />
        </div>

    )
}
export default AprovScreen;