import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { BASE_URL } from '../../utils/api/base';
import { useSelector } from 'react-redux';
import axios from 'axios';

const BarChart = () => {
  const selector = useSelector(state => state.auth);
  const [chartData, setChartData] = useState({
    categories: [],
    series: [],
    fullNames: [],
  });

  useEffect(() => {
    handleGetData();
  }, []);

  const handleGetData = () => {
    axios({
      url: `${BASE_URL}/api/v1/relatorios/areas_summary`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${selector.token.access}`
      }
    }).then(response => {
      const data = response.data;
      const categories = data.map(item => item.nome.split(' - ')[0]);
      const fullNames = data.map(item => item.nome);
      const series = [
        
        {
          name: 'DOCUMENTOS EM APROVAÇÃO',
          data: data.map(item => item.documentos_pendentes),
        },
        {
          name: 'DOCUMENTOS APROVADOS',
          data: data.map(item => item.documentos_aprovados),
        },
        {
          name: 'DOCUMENTOS REPROVADOS',
          data: data.map(item => item.documentos_rejeitados),
        },
      ];

      setChartData({ categories, series, fullNames });
    });
  };

  const chartOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    colors: ['#01795da4', '#00664e', '#6e6e6e'],
    grid: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 2,
        columnWidth: '70%',
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '12px',
              fontWeight: 900,
            },
          },
        },
      },
    },
    xaxis: {
      categories: chartData.categories,
      labels: {
        style: {
          fontSize: '12px',
        },
        tooltip: {
          enabled: true,
        },
      },
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const fullName = chartData.fullNames[dataPointIndex];
        const value = series[seriesIndex][dataPointIndex];
        const seriesName = w.globals.seriesNames[seriesIndex];
        return `<div style="padding: 5px;">
                  <strong style="font-size: 11px!important">${fullName}</strong><br />
                  ${seriesName}: ${value}
                </div>`;
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      offsetY: 5,
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <div id="chart" style={{ height: '100%', position: 'absolute', width: '100%'}}>
      <ReactApexChart
        options={chartOptions}
        series={chartData.series}
        type="bar"
        height='100%'
      />
    </div>
  );
};

export default BarChart;
