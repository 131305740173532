import './App.css';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { AuthProvider} from './utils/context/AuthContext';
import { CLIENT_ID, AUTHORITY_URI } from './utils/api/base';
import { SnackbarProvider } from 'notistack';
import RouterComponent from './routers';

const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: AUTHORITY_URI,
    
  },
};

const pca = new PublicClientApplication(msalConfig);

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <MsalProvider instance={pca}>
        <AuthProvider>
        <RouterComponent/>
        </AuthProvider>
      </MsalProvider>
    </SnackbarProvider>  
  );
}

export default App;
