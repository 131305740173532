import {
  Form,
  ButtonToolbar,
  Button,
  Input,
  InputGroup,
  InputNumber,
  InputPicker,
  DatePicker, 
  MaskedInput
} from "rsuite";
import './form-os.css';
import React from "react";
import { useState } from "react";
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

const FormOs = ({formOs, setFormOs}) => {
    const[bems, setBems] = useState([]);
    const[services, setSetvices] = useState([]);
    const[loadingInpt, setLoadingInpt] = useState(true);
   

    const handleGetBems = (bem) => {  
        // setLoadingInpt(true)
        // if(bem.length > 2){
        //     var selectdata = new Array("/jdbc/PROTHEUSQUERY", `SELECT * FROM ST9010 WHERE T9_CODBEM LIKE '${bem}%'`);
        //     var data = {
        //         name: 'DOCGED',
        //         fields: selectdata
        //     }
        //     fetchIntegration(`/api/public/ecm/dataset/datasets/`, {}, 'POST',data)
        //     .then(function(data){
        //         setLoadingInpt(false)
        //         setBems(data.content.values)
        //         console.log(data.content.values)
        //     }).catch(function(error){
        //         console.log(error)
        //     })
        // }
    }


    const hadleSelect = ({name, value}) => {
        if(name === 'servico'){
            let arr = services.filter(item => item.T4_SERVICO === value)
            setFormOs(prev => ({...prev, service: arr[0].T4_SERVICO, serviceName: arr[0].T4_NOME}))
        }else if(name === 'bem'){
            let arr = bems.filter(item => item.T9_CODBEM === value)
            setFormOs(prev => ({...prev, equipment: arr[0].T9_CODBEM, equipmentName: arr[0].T9_NOME}))
        }
        
    }


    const handleGetService = (service) => {  
        // setLoadingInpt(true)
        // if(service.length > 0){
        //     var selectdata = new Array("/jdbc/PROTHEUSQUERY", `SELECT * FROM ST4010 WHERE T4_SERVICO LIKE '${service}%' OR T4_NOME LIKE '${service}%'`);
        //     var data = {
        //         name: 'DOCGED',
        //         fields: selectdata
        //     }
        //     fetchIntegration(`/api/public/ecm/dataset/datasets/`, {}, 'POST',data)
        //     .then(function(data){
        //         setLoadingInpt(false)
        //         setSetvices(data.content.values)
        //     }).catch(function(error){
        //         console.log(error)
        //     })
        // }
    }







    return (
    <div style={{padding: 20}}>
      <Form fluid>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'stretch', marginBottom: 30}}>
            
            <div style={{width: '35%', marginRight: 20}}>
            <Form.Group controlId="bem-1" className="input-bem">
                <Form.ControlLabel>BEM</Form.ControlLabel>
                <Form.Control 
                    style={{width: '100%'}}
                    name="bem" 
                    accepter={InputPicker}
                    value={formOs?.equipment || ''}
                    placeholder="Codigo do Bem"
                    onSearch={(value) => handleGetBems(value.toUpperCase())}
                    onSelect={(value)=>hadleSelect({name: 'bem', value})}
                    data={bems.map(item => ({label: item.T9_CODBEM, value: item.T9_CODBEM}))} 
                    renderMenu={menu => {
                        if (loadingInpt) {
                          return (
                            <p style={{ padding: 5, color: '#999', textAlign: 'center' }}>
                              <SpinnerIcon spin style={{fontSize: 10}}/><span style={{marginLeft: 5, fontSize: 10}}>Carregando...</span>
                            </p>
                          );
                        }
                        return menu;
                      }}
                    />
            </Form.Group>
            </div>

            <div style={{width: '65%'}}>
            <Form.Group controlId="bem-2" className="input-bem">
                <Form.ControlLabel>NOME DO BEM</Form.ControlLabel>
                <Form.Control value={formOs?.equipmentName || ''} readOnly name="tipoos" type="text" />
            </Form.Group>
            </div>
        </div>
        
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'stretch', marginBottom: 30}}>
            
            <div style={{width: '35%', marginRight: 20}}>
            <Form.Group controlId="bem-1" className="input-bem">
                <Form.ControlLabel style={{fontSize: 12}}>SERVIÇO</Form.ControlLabel>
                <Form.Control 
                    style={{width: '100%'}}
                    name="bem" 
                    accepter={InputPicker}
                    value={formOs?.service || ''}
                    placeholder="Codigo do Serviço"
                    onSearch={(value) => handleGetService(value.toUpperCase())}
                    onSelect={(value)=>hadleSelect({name: 'servico', value})}
                    data={services.map(item => ({label: item.T4_SERVICO, value: item.T4_SERVICO}))} 
                    renderMenu={menu => {
                        if (loadingInpt) {
                          return (
                            <p style={{ padding: 5, color: '#999', textAlign: 'center' }}>
                              <SpinnerIcon spin style={{fontSize: 10}}/><span style={{marginLeft: 5, fontSize: 10}}>Carregando...</span>
                            </p>
                          );
                        }
                        return menu;
                      }}
                    />
                    
            </Form.Group>
            </div>

            <div style={{width: '65%'}}>
            <Form.Group controlId="bem-2" className="input-bem">
                <Form.ControlLabel style={{fontSize: 12}}>NOME DO SERVIÇO</Form.ControlLabel>
                <Form.Control readOnly value={formOs.serviceName || ''} name="tipoos" type="text" />
            </Form.Group>
            </div>
        </div>




        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'stretch', marginBottom: 30}}>
            
            <div style={{width: '25%', marginRight: 20}}>
            <Form.Group controlId="bem-1" className="input-bem">
                <Form.ControlLabel style={{fontSize: 12}}>DATA DE PARADA DO BEM</Form.ControlLabel>
                <Form.Control 
                    style={{width: '100%'}}
                    name="dtInicio" 
                    accepter={MaskedInput}
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    placeholder="Data Real de Inicio"
                    onChange={(value)=>console.log(value)}
                    />
            </Form.Group>
            </div>

            <div style={{width: '10%', marginRight: 20}}>
            <Form.Group controlId="bem-2" className="input-bem">
                <Form.ControlLabel style={{fontSize: 12}}>HORA</Form.ControlLabel>
                <Form.Control 
                    name="hora" 
                    type="text" 
                    accepter={MaskedInput}
                    mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                    
                    onChange={(value)=>console.log(value)}
                />
            </Form.Group>
            </div>

            <div style={{width: '65%'}}>
            <Form.Group controlId="bem-2" className="input-bem">
                <Form.ControlLabel style={{fontSize: 12}}>SITUAÇÃO</Form.ControlLabel>
                <Form.Control 
                style={{width: '100%'}}
                accepter={InputPicker}
                value={formOs?.situation || ''}
                placeholder="Situação"
                onSelect={(value)=>setFormOs(prev => ({...prev, situation: value}))}
                data={[{label: 'P - PENDENTE', value: 'P'}, {label: 'C - CANCELADA', value: 'C'}, {label: 'L - LIBERADA', value: 'L'}]}
                />
            </Form.Group>
            </div>
        </div>

        <Form.Group controlId="textarea-1">
          <Form.ControlLabel style={{fontSize: 12}}>OBSERVAÇÃO</Form.ControlLabel>
          <Form.Control rows={5} name="textarea" onChange={(value)=>setFormOs(prev=>({...prev, observation: value}))} accepter={Textarea} />
        </Form.Group>
        <Form.Group>
            
        </Form.Group>
      </Form>
    </div>
  );
};
export default FormOs;
