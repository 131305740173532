import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../../utils/api/base";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack';
import { InputGroup, Input, IconButton, Breadcrumb, Button } from 'rsuite';
import { logout } from "../../../../utils/store/auth";

const NavLink = React.forwardRef((props, ref) => {
    const { to, children, ...rest } = props;
    return (
        <Link to={to} ref={ref} {...rest}>
            {children}
        </Link>
    );
});

const MainGestaoRecursosH = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { appName } = location.state || {};
    const userAuth = useSelector((state) => state.auth);
    const [selectApp, setSelectApp] = useState(null);
    const [nome, setNome] = useState(null);
    const [appList, setAppList] = useState([]);

    useEffect(() => {
        handleApp();
    }, [location]);

    const handleApp = () => {
        axios({
            method: 'GET',
            url: `${BASE_URL}/api/v1/aplicativo/frame-app/`,
            headers: {
                Authorization: `Bearer ${userAuth.token.access}`
            },
            params: {
                aplicativo_id: id
            }
        }).then((response) => {
            setAppList(response.data);
            if (appName !== null) {
                response.data.forEach((app) => {
                    if (app.nome === appName) {
                        setSelectApp(app);
                        setNome(app.nome);
                    }
                });
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        const iframe = document.getElementById('appIframe');

        const handleIframeError = () => {
            if (selectApp?.link) {
                window.location.href = selectApp.link;
            }
        };

        if (iframe) {
            iframe.onerror = handleIframeError;
        }

        return () => {
            if (iframe) {
                iframe.onerror = null;
            }
        };
    }, [selectApp]);

    return (
        <div className="folder-content" style={{ padding: 10 }}>
            <SnackbarProvider />
            <div className="folder-row-1 user-space">
                <div className="folder-child-01">
                    <Breadcrumb>
                        <Breadcrumb.Item as={NavLink} to='/aplicativos'>
                            APLICATIVOS
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active style={{ cursor: 'pointer' }} onClick={() => setSelectApp(null)}>GESTÃO DE RECURSOS HUMANOS</Breadcrumb.Item>
                        {selectApp !== null ? (
                            <Breadcrumb.Item active>{nome.toUpperCase()}</Breadcrumb.Item>
                        ) : null}
                    </Breadcrumb>
                </div>
            </div>
            {selectApp === null ? (
                <div className="folder-row-2">
                    <div className='table-documents apps' style={{ padding: 0 }}>
                        <div className='table-documents apps'>
                            <div className='cards-container card-frota'>
                                {appList.map((app) => (
                                    <div className="card" key={app.nome} onClick={() => { 
                                        if(app.is_redirect){
                                            window.location.href = app.link;
                                        }else{
                                            setSelectApp(app);
                                            setNome(app.nome);
                                        }
                                     }}>
                                        <div className='card-01 gs-frota-card'>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1em' }}>
                                                <span>{app?.nome?.toUpperCase()}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div style={{ width: '100%', height: '100%' }}>
                    <iframe
                        id="appIframe"
                        style={{ width: '100%', height: '100vh', border: 'none' }}
                        src={selectApp?.link}
                        allowFullScreen
                        webkitAllowFullScreen
                        mozallowFullScreen
                        msAllowFullScreen
                    ></iframe>
                   
                </div>
            )}
        </div>
    );
}

export default MainGestaoRecursosH;
