import React from 'react';
import Sidebar from '../../components/sidebar';
import Navbar from '../../components/navbar';
import './main.css';
import { useState} from 'react';
import ListSearch from '../../components/listSearch';
import { useAxios } from '../../utils/api/api';
import { Breadcrumb } from 'rsuite';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { AiOutlineShoppingCart } from "react-icons/ai";
import { MdOutlineShoppingBag } from "react-icons/md";
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { MdStorefront } from "react-icons/md";
import { MdFormatShapes } from "react-icons/md";
import { IoIosGitNetwork } from "react-icons/io";
import { useSelector } from 'react-redux';

const MainLayout = ({ children }) => {
  const api = useAxios();
  const state = useSelector(state=> state.auth)
  const navigate = useNavigate();
  const[data,setData] = useState([]);
  const location = useLocation();
  const[loading,setLoading] = useState(false);
  const[open,setOpen] = useState(false);
  const[value, setValue] = useState('');
  const [OpenSpedDial, setOpenSpedDial] = useState(false);
  const [actions, setActions] = useState(
    [
      { icon: <AiOutlineShoppingCart />, name: 'Compras' },
      // { icon: <MdStorefront />, name: 'Armazém' }
    ]
  )
  const Compras = [
    {
      id: 1,
      name: 'Soliciatação de Compra',
      icon: <MdOutlineShoppingBag onClick={()=>{navigate('/aplicativos/gestao-compras/solicitacao-compras'); setTimeout(handleRedefine, 100)}}/>,
    },
    {
      id: 1,
      name: 'Pedido de Compra',
      icon: <AiOutlineShoppingCart onClick={()=>{navigate('/aplicativos/gestao-compras/pedido-compras'); setTimeout(handleRedefine, 100)}}/>,
    },
    {
      id: 3,
      name: 'Retornar',
      icon: <FaArrowUpFromBracket/>,
    },
  ]

  const Armazem = [
    {
      id: 1,
      name: 'Soliciatação de Armazém',
      icon: <MdFormatShapes onClick={()=>{navigate('/aplicativos/gestao-armazem/solicitacao-armazem'); setTimeout(handleRedefine, 100)}}/>,
    },
    {
      id: 2,
      name: 'Entrada de Pedidos',
      icon: <IoIosGitNetwork onClick={()=>{navigate('/aplicativos/gestao-armazem/chegada-pedidos'); setTimeout(handleRedefine, 100)}}/>,
    },
    {
      id: 3,
      name: 'Retornar',
      icon: <FaArrowUpFromBracket/>,
    },
  ]
  
  
  const handleRedefine = () => {
    setActions(
      [
        { icon: <AiOutlineShoppingCart />, name: 'Compras' },
        // { icon: <MdStorefront />, name: 'Armazém' }
      ]
    );
  }



  const OpenItem = (item) => {
    setOpenSpedDial(false);
    if(item.name === 'Compras'){
      setTimeout(() => {
        setActions(Compras);
        setOpenSpedDial(true);
      }, 200);
    }
    else if(item.name === 'Armazém'){
      setTimeout(() => {
        setActions(Armazem);
        setOpenSpedDial(true);
      }, 200);
    }
    else if(item.name === 'Retornar'){
      handleRedefine();
    }
  }

  const handleSearch = async (seach) => {
    setLoading(true);
    setOpen(true);
    setValue(seach);
    try {
      const response = await api.get(`/api/v1/pasta/main/get_search_data?search=${seach}`);
      setData(response.data);
      setLoading(false);
    }catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  


  const generateBreadcrumbs = () => {
    const pathnames = location.pathname.split('/').filter((x) => x);
    
    const breadcrumbItems = [{ name: 'Home', path: '/' }, ...pathnames.map((value, index) => {
      const path = `/${pathnames.slice(0, index + 1).join('/')}`;
      return { name: value, path };
    })];
  
    return (
      <Breadcrumb>
        {breadcrumbItems.map((item, index) => {
          const isLast = index === breadcrumbItems.length - 1;
          return isLast ? (
            <Breadcrumb.Item className='item-text-breadcrumb' style={{cursor: 'pointer'}} key={index} onClick={() => !isLast && navigate(item.path)} active>
              {item.name}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item style={{cursor: 'pointer'}} className='item-text-breadcrumb deactive' key={index} onClick={() => !isLast && navigate(item.path)}>
              {item.name}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  };
  
 


  



  return (
    <div style={{display: 'flex', flexDirection: 'row', height: '100vh', overflowY: 'hidden', width: '100%', position: 'relative'}}>
      <div>
        <Sidebar setOpen={setOpen}/>
      </div>
      
      <div className='layout-main'>
        <Navbar handleSearch={handleSearch}/>
        <div className='layout-container'>
          <div className='folder-row-1'>
            <div className="folder-child-01" style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <>
              {generateBreadcrumbs()}
              </>
              <div>
                  {state.isAuthenticated !== false && (
                    <SpeedDial
                    ariaLabel="SpeedDial controlled open example"
                    sx={{
                      position: 'absolute',
                      bottom: 16,
                      right: 20,
                      color: 'white',
                      '& .MuiFab-root': {
                        backgroundColor: '#00664e',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#075e4a',
                        },
                      }
                    }}
                    icon={<SpeedDialIcon />}
                    
                    onClick={()=>setOpenSpedDial(!OpenSpedDial)}
                      direction='left'
                      open={OpenSpedDial}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction
                          key={action.name}
                          icon={action.icon}
                          tooltipTitle={action.name}
                          onClick={()=>OpenItem(action)}
                        />
                      ))}
                  </SpeedDial>
                  )}
              </div>
            </div> 
          </div>
          {open ? (
            <ListSearch loading={loading} data={data} value={value} setOpen={setOpen}/>
          ) : (
            <>
              {children}
             
            </>
          )}
          
        </div>
      </div>

      
    </div>
  );
};

export default MainLayout;
