import './drawer-pedido-form.css';
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import CloseIcon from '@rsuite/icons/Close';
import { IconButton, Input, Loader, InputPicker, Modal, Button, Tabs} from 'rsuite';
import EditIcon from '@rsuite/icons/Edit';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import { useAxios } from '../../../../../../utils/api/api';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import CheckIcon from '@rsuite/icons/Check';
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import PageIcon from '@rsuite/icons/Page';
import ListImport from '../ListImport';
import { useFormik } from 'formik';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const filialList = [
  { value: '01', label: 'Matriz' },
  { value: '05', label: 'Parauapebas' },
  { value: '06', label: 'Canaa dos Carajas' },
  { value: '07', label: 'Mara Rosa' },
  { value: '08', label: 'Maraba' },
]


const DrawerPedidoForm = ({ open, setOpen, solicitacoes, handleGetSolicitacoes }) => {
  const user = useSelector((state) => state.auth);
  const api = useAxios();
  const[step, setStep] = useState(1);
  const [openImport, setOpenImport] = useState(false);
  const [listSelected, setListSelected] = useState([]);
  const [listFornec, setListFornec] = useState([]);
  const [listCond, setListCond] = useState([]);
  const [productList, setProductList] = useState([]);
  const [loadingInput, setLoadingInput] = useState(false);
  const formik = useFormik({
    initialValues: {
      c7_filial: '',
      c7_num: '',
      c7_emissao: '',
      c7_fornec: '',
      c7_loja: '',
      c7_cond: '',
      c7_moeda: '',
      c7_filent: '',
      items: []
    }
  });
  const itemsFormik = useFormik({
    initialValues: {
      c7_prod: '',
      c7_qtd: '',
      c7_preco: '',
      c7_total: '',
      c7_um: '',
      c7_numsc: '',
      c7_itemsc: '',
      c7_datprf: '',
      c7_total: '',
      c7_descri: '',
      c7_obs: ''

    }
  });

  const handleSearchFornec = async (value) => {
    setLoadingInput(true);
    try {
      const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_fornecedores',{
        params: {
            filter: value,  
        }
    });
    setLoadingInput(false);
      setListFornec(response.data);
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearchProduct = async (value) => {
    setLoadingInput(true);
    try {
      const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
        params: {
            filter: value,  
            table: 'Sb1010'
        }
    });
      setLoadingInput(false);
      setProductList(response.data);
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearchCond = async (value) => {
    setLoadingInput(true);
    try {
      const response = await api.get('/api/v2/modules/gestao-compras/solicitacao-compras/filter_aux',{
        params: {
            filter: value,  
            table: 'Se4010'
        }
      });
      setLoadingInput(false);
      setListCond(response.data);
    } catch (error) {
      console.log(error)
    }
  }




  const handleSelectProduto = (item) => {
    let produto = productList.find((prod) => prod.b1_cod === item);
    itemsFormik.setFieldValue('c7_prod', produto.b1_cod);
    itemsFormik.setFieldValue('c7_um', produto.b1_um);
    itemsFormik.setFieldValue('c7_descri', produto.b1_desc);

  }


  function removerCaracteresEspeciais(texto) {
    return texto
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/ç/g, 'c') 
        .replace(/Ç/g, 'C')
        .replace(/[^\w\s]/g, '');
  }

  const formatCurrency = (value) => {
    if (typeof value !== 'number' || isNaN(value)) return '';
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  };
  
  const parseCurrency = (formattedValue) => {
    if (!formattedValue) return 0;
  
    const onlyDigits = formattedValue.replace(/\D/g, '');
    // Se não houver dígitos, retorne 0
    if (!onlyDigits) return 0;
  
    // Considerando que a moeda tem duas casas decimais fixas
    const intValue = parseInt(onlyDigits, 10);
    const value = intValue / 100; 
    return value;
  };

  return (
    <div className='modal-custom'>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {setOpen(false)}}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ backgroundColor: '#00664e', padding: 2 }}>
            <IconButton icon={<CloseIcon />} size='sm' onClick={() => setOpen(false)} />
            <div style={{width: '100%', padding: 10}}>
              <h3 style={{fontSize: 18}}>PEDIDO DE COMPRAS</h3>
            </div>
          </Toolbar>
        </AppBar>

        <div className='form-container'>
          <div className='form-pushare'>
              <div className='form-pushare-top'>
                <div className='form-row' style={{display: 'flex', flexDirection: 'row'}}>
                  <div className='form-group input-product-container' style={{width: '30%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Cond. Pgto</label>
                    <InputPicker
                      id="product"
                      style={{width: '100%'}}
                      placeholder="Código ou nome"
                      onSearch={(value)=>handleSearchCond(value)}
                      menuStyle={{
                        maxWidth: '10%',
                        overflowX: 'auto',
                      }}
                      data={listCond.map((item) => 
                        ({
                            label: `${item.e4_codigo} - ${item.e4_descri}`,
                            value: item?.e4_codigo
                        }))}
                      renderMenu={(menu) => {
                          if(loadingInput){
                            return(
                              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                                <Loader content="Carregando..." center/>
                              </div>
                            )
                          } return(
                            <div style={{fontSize: 11}}>
                              {menu}
                            </div>
                          )
                        }}
                        onSelect={(value, item) => {
                          formik.setFieldValue('c7_cond', item.value)
                        }}
                        onReset={() => {
                          formik.setFieldValue('c7_cond', '')
                        }}
                    />
                  </div>
                  <div className='form-group input-product-container' style={{width: '40%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Fornecedor</label>
                    <InputPicker
                      id="product"
                      style={{width: '100%'}}
                      placeholder="Código ou nome"
                      onSearch={(value)=>handleSearchFornec(value)}
                      menuStyle={{
                        maxWidth: '10%',
                        overflowX: 'auto',
                      }}
                      data={listFornec.map((item) => 
                        ({
                            label: `${item.a2_cod} - ${item.a2_nome}`,
                            value: item?.a2_cod
                        }))}
                      renderMenu={(menu) => {
                          if(loadingInput){
                            return(
                              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                                <Loader content="Carregando..." center/>
                              </div>
                            )
                          } return(
                            <div style={{fontSize: 11}}>
                              {menu}
                            </div>
                          )
                        }}
                        onSelect={(value, item) => {
                          formik.setFieldValue('c7_fornec', item.value)
                        }}
                        onReset={() => {
                          formik.setFieldValue('c7_fornec', '')
                        }}
                    
                    />
                  </div>
                  <div className='form-group input-product-container' style={{width: '30%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Filial p/ entrega</label>
                    <InputPicker
                      id="product"
                      style={{width: '100%'}}
                      
                      placeholder="Código ou nome"
                      menuStyle={{
                        maxWidth: '10%',
                        overflowX: 'auto',
                      }}
                      data={filialList}
                      onSelect={(value, item) => {
                        formik.setFieldValue('c7_filial', item.value)
                      }}
                      onReset={() => {
                        formik.setFieldValue('c7_filial', '')
                      }}
                    />
                  </div>
                </div>

              </div>
              <div className='form-pushare-bottom'>
                <div className='form-row' style={{display: 'flex', flexDirection: 'row'}}>
                  <div className='form-group input-product-container' style={{width: '30%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Produto</label>
                    <InputPicker
                      id="product"
                      style={{width: '100%'}}
                      placeholder="Código ou Nome"
                      onSearch={(value)=>handleSearchProduct(value)}
                      menuStyle={{
                        maxWidth: '10%',
                        overflowX: 'auto',
                      }}
                      data={productList.map((item) => 
                        ({
                            label: `${item.b1_cod} - ${item.b1_desc}`,
                            value: item?.b1_cod
                        }))}
                      renderMenu={(menu) => {
                          if(loadingInput){
                            return(
                              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                                <Loader content="Carregando..." center/>
                              </div>
                            )
                          } return(
                            <div style={{fontSize: 11}}>
                              {menu}
                            </div>
                          )
                        }}
                        onSelect={(value, item) => {
                          handleSelectProduto(value)
                        }}
                        onReset={() => {
                          itemsFormik.setFieldValue('c7_prod', '');
                          itemsFormik.setFieldValue('c7_um', '');
                          itemsFormik.setFieldValue('c7_descri', '');
                        }}
                    />
                  </div>
                  <div className='form-group input-product-container' style={{width: '30%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Qtd.</label>
                    <Input
                      id="product"
                      type='number'
                      onChange={(value)=>itemsFormik.setFieldValue('c7_qtd', value)}
                      value={itemsFormik.values.c7_qtd}
                      style={{width: '100%'}}
                    />
                  </div>

                  <div className='form-group input-product-container' style={{width: '30%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Prc Unitario</label>
                    <Input
                      id="product"
                      type='text'
                      onChange={(value) => {
                        itemsFormik.setFieldValue('c7_preco', parseCurrency(value));
                      }}
                      value={formatCurrency(itemsFormik.values.c7_preco)}
                      style={{width: '100%'}}
                    />
                  </div>

                  <div className='form-group input-product-container' style={{width: '30%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Vlr. Total</label>
                    <Input
                      id="product"
                      type='number'
                      readOnly
                      value={itemsFormik.values.c7_qtd * itemsFormik.values.c7_preco}
                      style={{width: '100%'}}
                    />
                  </div>

                  <div className='form-group input-product-container' style={{width: '30%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Unidade</label>
                    <Input
                      id="product"
                      style={{width: '100%'}}
                      value={itemsFormik.values.c7_um}
                      readOnly
                    />
                  </div>
                </div>

                <div className='form-row' style={{display: 'flex', flexDirection: 'row'}}>
                  <div className='form-group input-product-container' style={{width: '30%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Numero (SC)</label>
                    <InputPicker
                      id="product"
                      style={{width: '100%'}}
                      placeholder="Selecione"
                      onSearch={(value)=>handleSearchCond(value)}
                      menuStyle={{
                        maxWidth: '10%',
                        overflowX: 'auto',
                      }}
                      data={listCond.map((item) => 
                        ({
                            label: `${item.e4_codigo} - ${item.e4_descri}`,
                            value: item?.e4_codigo
                        }))}
                      renderMenu={(menu) => {
                          if(loadingInput){
                            return(
                              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}>
                                <Loader content="Carregando..." center/>
                              </div>
                            )
                          } return(
                            <div style={{fontSize: 11}}>
                              {menu}
                            </div>
                          )
                        }}
                        onSelect={(value, item) => {
                          formik.setFieldValue('c7_cond', item.value)
                        }}
                        onReset={() => {
                          formik.setFieldValue('c7_cond', '')
                        }}
                    />
                  </div>
                  <div className='form-group input-product-container' style={{width: '30%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Item (SC)</label>
                    <Input
                      id="product"
                      type='number'
                      style={{width: '100%'}}
                      value={itemsFormik.values.c7_itemsc}
                    />
                  </div>

                  <div className='form-group input-product-container' style={{width: '30%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Data Entrega</label>
                    <Input
                      id="product"
                      type='date'
                      style={{width: '100%'}}
                      value={itemsFormik.values.c7_datprf}
                    />
                  </div>

                  <div className='form-group input-product-container' style={{width: '30%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Vlr. Total</label>
                    <Input
                      id="product"
                      type='number'
                      style={{width: '100%'}}
                    />
                  </div>

                  <div className='form-group input-product-container' style={{width: '30%', marginRight: 10, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <label style={{marginLeft: 4, fontSize: 12}}>Unidade</label>
                    <Input
                      id="product"
                      style={{width: '100%'}}
                    />
                  </div>
                </div>
                <div style={{marginTop: 30}}>
                  <Input as="textarea" rows={5} placeholder="Observação" onChange={(value)=> itemsFormik.setFieldValue('c7_obs', removerCaracteresEspeciais(value.toUpperCase()))}/>
                </div>
              </div>
            <div className="submit-button">
                <IconButton icon={<PageIcon/>} onClick={()=>setOpenImport(true)} size='sm' style={{marginRight: 10}}>
                  Importar
                </IconButton>
                <IconButton icon={<ExpandOutlineIcon />} size='sm'>
                  Adicionar
                </IconButton>
            </div>
          </div>

          <div className='list-pushare' style={{position: 'relative'}}>
            <div>
              <span style={{fontSize: 16, fontWeight: 'bold'}}>Itens</span>
            </div>
            {/* {formData.items.length > 0 && (
              <div style={{position: 'fixed', bottom: 0, height: '5%', zIndex: 9999, backgroundColor: '#fff', width: '100%', left: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '1%'}}>
                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: '1%'}}>
                <IconButton appearance='primary' style={{backgroundColor: 'red', marginRight: 10}} onClick={()=> setFormData({...formData,items: []})} icon={<CloseIcon style={{backgroundColor: 'red', color: '#fff'}}/>} size='sm'>
                    Cancelar
                  </IconButton>
                  <IconButton appearance='primary' style={{backgroundColor: '#00664e'}} icon={<CheckIcon style={{backgroundColor: '#00664e', color: '#fff'}}/>} onClick={()=>handleConclusion()} size='sm'>
                    Concluir
                  </IconButton>    
                </div>
              </div>
            )} */}
          </div>
          <Modal size="lg" open={openImport} onClose={()=>setOpenImport(false)} style={{zIndex: 99999}}>
            
            <Modal.Body style={{padding: 0, margin: 0}}>
            <Tabs defaultActiveKey="1" appearance="subtle">
                <Tabs.Tab eventKey="1" title="Solicitação de compra">
                  <div style={{minHeight: 400}}>
                    <ListImport listSelected={listSelected} setListSelected={setListSelected} fornecedor={formik.initialValues.c7_fornec}/>
                  </div>
                </Tabs.Tab>
                {/* <Tabs.Tab eventKey="2" title="Solicitação Armazem">
                  <div style={{minHeight: 400}}>
                    <span>Tab 2</span>
                  </div>
                </Tabs.Tab> */}
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              {listSelected.length > 0 && (
                <Button onClick={()=>console.log(listSelected)} appearance="primary">
                  Ok
                </Button>
              )}
              <Button onClick={()=>setOpenImport(false)} appearance="subtle">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Dialog>
    </div>
  );
};

export default DrawerPedidoForm;
