import './gestao-compras.css'
import { useNavigate } from 'react-router-dom';
import pd_img from '../gestaoCompras/assets/pd.png';
import sc_img from '../gestaoCompras/assets/sc.png';


const GestaoCompras = () => {
    const navigate = useNavigate();
    
    return(
        <div className="folder-content">
            <div className="folder-row-2">
                
                <div className='table-documents main'>
                    <div className='cards-container-gestao-compras'>
                        <div className="card-gestao-compras" onClick={()=>navigate('solicitacao-compras')}>
                            <img src={sc_img} alt="sc" style={{width: '100%', height: '100%', borderRadius: 5}}/>
                        </div>
                        <div className="card-gestao-compras" onClick={()=>navigate('pedido-compras')}>
                           <img src={pd_img} alt="pd" style={{width: '100%', height: '100%', borderRadius: 5}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GestaoCompras;
