import { Modal, Button, Uploader, Form, Progress, InputPicker } from "rsuite";
import { useState, useContext, useEffect } from "react";
import { BASE_URL } from "../../utils/api/base";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import axios from "axios";
import './create-folder.css';





const privacidade = [
    {
        label: 'Publico',
        value: 0
    },
    {
        label: 'Restrita',
        value: 1
    },
]




export const ModalCreateFolder = ({ showModal, setShowModal, id, getFunction }) => {
    const user = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    
    const [formData, setFormData] = useState(null);
   
    
    useEffect(()=>{
        setFormData(null)
    }, [showModal])

    const createFolder = () => {
        setLoading(true)

        axios({
            method: 'POST',
            url: `${BASE_URL}/api/v1/pasta/main/`,
            headers: {
                'Authorization': `Bearer ${user.token.access}`
            },
            data: formData
            }).then((response) => {
                getFunction(id)
                setShowModal(false)
                setFormData(null)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setLoading(false)
            })
    }
    




    

   


   

    return (
        <Modal  open={showModal} overflow={true} size="xs" onClose={() => setShowModal(false)}>
            <Modal.Header>
                <Modal.Title style={{fontSize: 12}}>NOVA PASTA</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight: 400, padding: 5, marginBottom: 20}}>
                <Form fluid>
                <Form.Group controlId="name-1">
                <Form.ControlLabel className="form-label">Nome</Form.ControlLabel>
                <Form.Control 
                    className="form-item" 
                    name="name" 
                    onChange={(e) => setFormData({...formData, nome: e, subnome: e, parent_id: id})}
                    value={formData?.nome || ''}
                    />
                </Form.Group>
                
                {/* <Form.Group>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Form.ControlLabel className="form-label">
                    Privacidade da pasta
                    </Form.ControlLabel>
                    <Form.HelpText tooltip className="helper-text" style={{zIndex: 3000}}>
                    Nivel de confidencialidade do pasta
                    <br />
                    - Publico: Todos com acesso a pasta pai
                    <br />
                    - Restrita: Apenas pessoas autorizadas

                    </Form.HelpText>
                </div>
                <Form.Control
                    name="privacidade"
                    className="form-item"
                    id="privacidade"
                    block
                    data={privacidade?.map(item => ({ label: item.label, value: item.value }))}
                    onSelect={(e)=>setFormData({...formData, privacidade: e})}
                    value={formData?.privacidade || ''}
                    accepter={InputPicker}
                    placeholder="Privacidade da pasta"
                    menuStyle={{
                    maxHeight: "200px",
                    wordBreak: "break-word",
                    maxWidth: 200,
                    zIndex: 3000,
                    }}
                />
                </Form.Group> */}

            </Form>
                        
                
            </Modal.Body>
            <Modal.Footer>
                <Button loading={loading} appearance="primary" style={{backgroundColor: '#00664e'}} onClick={()=>createFolder()}>
                    Salvar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

