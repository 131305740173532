import { Routes, Route } from 'react-router-dom';
import GestaoNaoConformidades from '.';
import MainLayout from '../../layouts/main';




const GnRouters = () => {
  return (
    <Routes>
        <Route path="/" element={<MainLayout><GestaoNaoConformidades/></MainLayout>} />
    </Routes>
  );
};

export default GnRouters;
