import { Drawer, IconButton } from 'rsuite';
import { useState, useEffect } from 'react';
import axios from "axios";
import { BASE_URL } from "../../utils/api/base";
import { useSelector } from "react-redux";
import './shared.css'
import CloseIcon from '@rsuite/icons/Close';

const SharedProperties = ({open, setOpen, codigo, titulo, id}) => {
  const [loading, setLoading] = useState(true);
  const userAuth = useSelector(state => state.auth);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (open) {
      handleGedProperties(id);
    }
  }, [open]);

  const handleGedProperties = (document_id) => {
    setLoading(true);
    axios({
      method: 'GET',
      url: `${BASE_URL}/api/v1/compartilhamentos/document/get_shared_documents?document_id=${document_id}`,
      headers: {
        Authorization: `Bearer ${userAuth.token.access}`
      }
    }).then((response) => {
      setData(response.data);
      console.log(response.data);
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  };

  const renderSharedType = (value) => {
    if (value.custom_group) {
      return value.custom_group.nome;
    } else if (value.funcao) {
      return value.funcao.nome;
    } else if (value.user) {
      return value.user.first_name + ' ' + value.user.last_name;
    }
  };

  const handleCheckboxChange = (index, field) => {
      const updatedData = [...data];
      updatedData[index][field] = !updatedData[index][field];

      const shareId = updatedData[index].id;
      const updatePayload = {
        [field]: updatedData[index][field]
      };

      axios({
        method: 'PATCH',
        url: `${BASE_URL}/api/v1/compartilhamentos/document/${shareId}/`,
        headers: {
          Authorization: `Bearer ${userAuth.token.access}`
        },
        data: updatePayload
      })
      .then(() => {
        setData(updatedData);
        console.log('Dados atualizados:', updatedData);
      })
      .catch((error) => {
        console.error('Erro ao atualizar:', error);
      });
  };




  const handleDeleteShared = (index) => {
    const updatedData = [...data];
    const shareId = updatedData[index].id;

    axios({
      method: 'DELETE',
      url: `${BASE_URL}/api/v1/compartilhamentos/document/${shareId}/`,
      headers: {
        Authorization: `Bearer ${userAuth.token.access}`
      }
    })
    .then(() => {
      updatedData.splice(index, 1);
      setData(updatedData);
      console.log('Compartilhamento excluído. Dados atualizados:', updatedData);
    })
    .catch((error) => {
      console.error('Erro ao excluir:', error);
    });
  };

  return (
    <>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <Drawer.Header>
          <Drawer.Title style={{ color: '#fff', fontSize: 12 }}>GERENCIAR COMPARTILHAMENTO: {codigo}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body style={{ margin: 0, padding: 0 }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', margin: 5, padding: 0 }}>
            <div className="shared-title-container">
              <div className="list-title-item max-title">NOME</div>
              <div className="list-title-item">VISUALIZAR</div>
              <div className="list-title-item">EDITAR</div>
              <div className="list-title-item">BAIXAR</div>
              <div className="list-title-item">COMPARTILHAR</div>
              <div className="list-title-item">...</div>
            </div>
            {data?.map((item, index) => (
              <div key={index} className="shared-item-container">
                <div className="shared-item-name">{renderSharedType(item)}</div>
                <div className="shared-checkbox">
                  <input
                    type="checkbox"
                    checked={item?.can_view}
                    onChange={() => handleCheckboxChange(index, 'can_view')}
                  />
                </div>
                <div className="shared-checkbox">
                  <input
                    type="checkbox"
                    checked={item?.can_edit}
                    onChange={() => handleCheckboxChange(index, 'can_edit')}
                  />
                </div>
                <div className="shared-checkbox">
                  <input
                    type="checkbox"
                    checked={item?.can_download}
                    onChange={() => handleCheckboxChange(index, 'can_download')}
                  />
                </div>
                <div className="shared-checkbox">
                  <input
                    type="checkbox"
                    checked={item?.can_shared_documents}
                    onChange={() => handleCheckboxChange(index, 'can_shared_documents')}
                  />
                </div>
                <div className="shared-checkbox">
                  <IconButton icon={<CloseIcon style={{ color: '#fff' }} />} appearance="primary" size='xs' style={{ backgroundColor: 'red' }} onClick={()=>handleDeleteShared(index)}/>
                </div>
              </div>
            ))}
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default SharedProperties;
