import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../../utils/api/base';
import { useSelector } from 'react-redux';
import axios from 'axios';
import './leader-board.css';
import WaitIcon from '@rsuite/icons/Wait';
import { Avatar, Progress } from 'rsuite';
import { BiArea } from "react-icons/bi";
import BranchIcon from '@rsuite/icons/Branch';



const filterAccessibleProcesses = (processosList) => {
  // Primeiro, calculamos o progresso de cada processo
  const processosWithProgress = processosList.map(processo => {
    if (processo.children && processo.children.length > 0) {
      const totalProgress = processo.children.reduce((acc, child) => acc + (child.processo_progress[0] || 0), 0);
      processo.processo_progress[0] = totalProgress / processo.children.length;
    }
    return processo;
  });

  // Em seguida, ordenamos os processos pelo progresso, do maior para o menor
  return processosWithProgress.sort((a, b) => b.processo_progress[0] - a.processo_progress[0]);
};






const LeaderBoard = ({loading, setLoading}) => {
  const selector = useSelector(state => state.auth);
  const[data,setData] = useState([])
  const[expand, setExpand] = useState({open: false, index: null});
  const[processosList, setProcessosList] = useState([]);
  

  useEffect(() => {
    handleGetData();
  }, []);

  const handleGetData = () => {
    axios({
      url: `${BASE_URL}/api/v1/relatorios/areas_progress`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${selector.token.access}`
      }
    }).then(response => {
      setData(response.data)
    });
  };


  const handleFilter = (area_id) =>{
    setLoading(true)
    axios({
      url: `${BASE_URL}/api/v1/processo/get_processos?area_id=${area_id}`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${selector.token.access}`
      }
    }).then(response => {
      setLoading(false)
      setExpand({open: true, index: area_id})
      let us = filterAccessibleProcesses(response.data)
      setProcessosList(us)
    });
  }





 

  return (
    <div style={{width: '100%', height: '100%', marginTop: 30}}>
        <div className="folder-row-2 compartilhados-container aprovacao">
        <div className="containter-process-list">  
              <div className="list-container leader-board">
                <div className="list-titles" style={{top: 0, display: 'grid', gridTemplateColumns: '2fr 0.5fr 0.5fr 0.5fr 0.5fr'}}>
                    <div className="list-title-item area">ÁREA</div>
                    <div className="list-title-item">GESTOR/COORDENADOR</div>
                    <div className="list-title-item">PROCESSOS</div>
                    <div className="list-title-item">SUBPROCESSOS</div>
                    <div className="list-title-item">AVANÇO</div>
                </div>
              <>
              {data.map((processos, index) => (
                  <ul key={index} className='list-processos'>
                      <li>
                          <div className="list-item-content leader-item" style={{gridTemplateColumns: '2fr 0.5fr 0.5fr 0.5fr 0.5fr', cursor: 'pointer'}}>
                              <div className="list-processos-item custom">
                                  <div>
                                    <BiArea style={{ color: '#fff', height: '100%', borderRadius: 5}} className="icon-button"/>
                                  </div>
                                  <div className="item-text">
                                      <span className="item-name">{processos?.nome}</span>
                                  </div>
                              </div>
                              <div className="list-processos-item">
                                  <span className="item-manager">
                                      {processos.gestor === "Sem gestor" ? "Sem gestor" : `${processos?.gestor?.first_name} ${processos?.gestor?.last_name}`}
                                  </span>
                              </div>
                              <div className="list-processos-item">
                                  <span className="item-count">{processos?.get_quantidade_processo?.processos}</span>
                              </div>

                              <div className="list-processos-item">
                                  <span className="item-count">{processos?.get_quantidade_processo?.subprocessos}</span>
                              </div>
                              
                              <div className="list-processos-item">
                                <Progress.Line showInfo={false} title='progresso' percent={Math.round(processos?.progresso_area * 10) / 10} style={{ fontSize: 12}} strokeColor={Math.round(processos?.progresso_area * 10) / 10 <= 25 ? '#01795da4' : '#00664e'} />  
                                <span style={{fontSize: 12, position: 'absolute', pointerEvents: 'none', color: '#fff', fontWeight: 'bold'}}>{Math.round(processos?.progresso_area * 10) / 10}%</span>
                              </div>
                          </div>
                      </li>
                      {expand.open && expand.index === processos.id && (
                          <ul className='list-expand'>
                              {processosList?.map((processo, index) => (
                                <>
                                <li>
                                <div className="list-item-content custom" style={{gridTemplateColumns: '3.5fr 0.5fr 0.5fr', cursor: 'pointer'}}>
                                    <div className="list-processos-item custom">
                                        <div>
                                          <BranchIcon style={{ color: '#fff', height: '80%', borderRadius: 5, padding: 8}} className="icon-button" />
                                        </div>
                                        <div className="item-text">
                                          <span style={{ fontSize: 11, fontWeight: 'bold' }}>{processo?.nome?.toUpperCase()}</span>
                                          <span style={{ fontSize: 12, color: 'gray' }}>{processo?.descricao}</span>
                                        </div>
                                    </div>
                                    

                                    <div className="list-processos-item">
                                        <span className="item-count">{processo?.children?.length}</span>
                                    </div>
                                    
                                    <div className="list-processos-item">
                                      <Progress.Line showInfo={false} title='progresso' percent={Math.round(processo?.processo_progress[0] * 10) / 10} style={{ fontSize: 12}} strokeColor={Math.round(processo?.processo_progress[0] * 10) / 10 <= 25 ? '#eb9b34' : '#00664e'} />  
                                      <span style={{fontSize: 12, position: 'absolute', pointerEvents: 'none', color: '#fff', fontWeight: 'bold'}}>{Math.round(processo?.processo_progress[0] * 10) / 10}%</span>
                                    </div>

                                   
                                </div>
                                </li>
                                </>
                              ))}
                          </ul>
                      )}
                  </ul>
              ))}
              </>
              </div>
          </div>
        </div>
    </div>
  );
};

export default LeaderBoard;
