import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    time: '',
    data: [],
};

export const datascSlice = createSlice({
    name: 'datasc',
    initialState,
    reducers: {
        changeDataSc: (state, { payload }) => {
            state.time = payload.time || Date.now();
            state.data = payload.data || [];
        },
        deleteDataSc: (state) => {
            return { ...initialState };
        },
        
    },
});

export const { changeDataSc, deleteDataSc } = datascSlice.actions;

export const selectDataSc = (state) => state.datasc;

export default datascSlice.reducer;
