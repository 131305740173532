import './list-search.css';
import { CiFileOn } from "react-icons/ci";
import { CiFolderOn } from "react-icons/ci";
import { IconButton, Loader } from 'rsuite';
import BranchIcon from '@rsuite/icons/Branch';
import DocumentView from '../documentView';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ListSearch = ({loading, data, value, setOpen}) => {
    const { documentos, folders } = data;
    const[show, setShow] = useState(false);
    const navigate = useNavigate();
    const[selectedDocument, setSelectedDocument] = useState({
        id: null,
        title: '',
        codigo: ''
    })




    


    return(
        <div className="folder-content">
            <div className="folder-row-1">
                <div className="folder-child-01">
                    <span>Resultados para {value}</span>
                </div>    
            </div>
            <div className="folder-row-2">
                <div className='table-documents'>
                    <>
                    {loading ? (
                        <Loader center content="Carregando..."/>
                    ):(
                        <>
                        {documentos.length > 0 && (
                        <div className="row-list">
                            <div className="list-seach-title">
                                <span>Documentos</span>
                            </div>
                            <ul className="list-seach-content">
                                {documentos?.map((documento, index) => (
                                    <li key={index} onClick={()=> {setShow(true); setSelectedDocument({id: documento.id, title: documento.titulo, codigo: documento.codigo})}}>
                                        <div className="list-seach-item-icon">
                                            <IconButton icon={<CiFileOn style={{ color: '#fff' }} />} appearance='primary' size="lg" className="icon-button" />
                                        </div>
                                        <div className="list-seach-item-text">
                                            <span>{documento?.codigo}</span>
                                            <span style={{fontSize: 11}}>{documento?.titulo}</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>

                        </div>
                        )}
                        
                        {folders.length > 0 && (
                            <div className="row-list">
                                <div className="list-seach-title">
                                    <span>Pastas</span>
                                </div>
                                <ul className="list-seach-content">
                                    {folders?.map((folder, index) => (
                                        <li key={index} onClick={()=>{setOpen(false); navigate('/documentos/diretorios', { state: { folder_id: folder.id } })}}>
                                            <div className="list-seach-item-icon">
                                                <IconButton icon={<CiFolderOn style={{ color: '#fff' }} />} appearance='primary' size="lg" className="icon-button" />
                                            </div>
                                            <div className="list-seach-item-text">
                                                <span>{folder?.nome}</span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                            </div>
                        )}
                        </>
                    )}
                    </>
                </div>
            </div>
            <DocumentView open={show} setOpen={setShow} id={selectedDocument.id} documentName={selectedDocument.codigo} documentTitle={selectedDocument.title}/>
        </div>
    )
}
export default ListSearch;