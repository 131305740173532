import React,{ useState, useEffect } from "react";
import { BASE_URL } from "../../../../utils/api/base";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack'
import { InputGroup, Input, IconButton, Breadcrumb, Button} from 'rsuite';
import './gs-frotas.css';
import { logout } from "../../../../utils/store/auth";



const NavLink = React.forwardRef((props, ref) => {
    const { to, children, ...rest } = props;
    return (
        <Link to={to} ref={ref} {...rest}>
            {children}
        </Link>
    );
});


const GestaoFrotas = () => {
    const id = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { appName } = location.state || {}; 
    const userAuth = useSelector((state) => state.auth);


    useEffect(()=>{
        console.log(id);
        axios({
            method: 'GET',
            url: `${BASE_URL}/api/v1/aplicativo/open-app?app_id=${id.id}`,
            headers: {
                Authorization: `Bearer ${userAuth.token.access}`
            }
        }).then((response) => {
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
            if(error.code === 'ERR_BAD_REQUEST'){
                if(error.response.data.error === 'Acesso negado')
                    enqueueSnackbar('Você não tem acesso à o ambiente atual. Deseja solicitar acesso?', {
                        action,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        persist: true
                    })
            }
        
        })
    },[])

    
    const SolicitarAcesso = () => {
        axios({
            method: 'POST',
            url: `${BASE_URL}/api/v1/aplicativo/request-access/`,
            headers: {
                'Authorization': `Bearer ${userAuth.token.access}`,
            },
            data: {
                app_id: id.id
            }
        }).then((response) => {
            if(response.status === 201){
                enqueueSnackbar(`Solicitação de acesso enviada para o Gestor da pasta`, {
                    variant:'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                })
                setTimeout(() => {
                    navigate('/aplicativos')
                }, 2000)
            }
        }).catch((error) => {
            console.log(error);
            if(error.response.status === 400 && error.response.data.error === 'Aprovador não encontrado.'){
                enqueueSnackbar('Aplicativo sem Gestor...', {
                    variant:'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                })
                setTimeout(() => {
                    navigate('/aplicativos')
                }, 2000)
                
            }else if(error.code === 'ERR_BAD_REQUEST'){
                // dispatch(logout())
            }
        })
    }




    const handleOpenService = () =>{
        navigate(`/aplicativos/gestao-frotas/${id.id}/abrir-servico`, { state: { appName: appName } })
        // if(app.nome === 'GESTÃO DE FROTAS'){
        //     navigate(`/aplicativos/gestao-frotas/${app.id}`, { state: { appName: app.nome } })
        // }else if(app.nome === 'GESTÃO DE ORÇAMENTARIA'){
        //     navigate(`/aplicativos/gestao-orcamento/${app.id}`, { state: { appName: app.nome } })
        // }else if(app.nome === 'GESTÃO DE COMPRAS'){
        //     navigate(`/aplicativos/gestao-compras/${app.id}`, { state: { appName: app.nome } })
        // }
        // console.log(app.nome);
    }

    const action = snackbarId => (
        <>
          <Button appearance="primary" style={{backgroundColor: 'green', margin: 5}} onClick={() => { closeSnackbar(snackbarId); SolicitarAcesso()}}>
            Sim
          </Button>
          <Button appearance="primary" style={{backgroundColor: 'gray', margin: 5}} onClick={() => { closeSnackbar(snackbarId); navigate('/aplicativos')}}>
            Não
          </Button>
        </>
    );


    return (
        <div className="folder-content" style={{padding: 10}}>
            <SnackbarProvider/>
            <div className="folder-row-1 user-space">
                <div className="folder-child-01">
                    <Breadcrumb>
                        <Breadcrumb.Item as={NavLink} to='/aplicativos'>
                        APLICATIVOS
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>GESTÃO DE FROTAS</Breadcrumb.Item>
                    </Breadcrumb>
                </div>    
            </div>
            <div className="folder-row-2">
                <div className='table-documents apps'>
                    <div className='table-documents apps'>
                        
                        <div className='cards-container card-frota'>
                            <div className="card" onClick={()=>handleOpenService()}>
                                <div className='card-01 gs-frota-card'>
                                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1em'}}>
                                    <span>ABERTURA E FECHAMENTO DE OS | SS</span>
                                    </div>
                                </div>
                            </div> 


                            <div className="card">
                                <div className='card-01 gs-frota-card'>
                                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1em'}}>
                                    <span>MOVIMENTAÇÃO DE PNEUS</span>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className='card-01 gs-frota-card'>
                                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1em'}}>
                                    <span>MOVIMENTAÇÃO DE MATERIAIS RODANTES</span>
                                    </div>
                                </div>
                            </div>  


                            <div className="card">
                                <div className='card-01 gs-frota-card'>
                                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1em'}}>
                                    <span>ALOCAÇÃO DE FROTA</span>
                                    </div>
                                </div>
                            </div> 


                            <div className="card">
                                <div className='card-01 gs-frota-card'>
                                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1em'}}>
                                    <span>COMBUSTIVEL</span>
                                    </div>
                                </div>
                            </div> 
                        </div>
               
                </div>
                </div>
            </div>
        </div>
    );
}

export default GestaoFrotas;