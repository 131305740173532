import { Form, Input, Button, InputPicker, Schema, Progress } from "rsuite";
import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils/api/base";
import { useNavigate,  useParams } from "react-router-dom";
import './invite.css';
import { FaCheckCircle } from "react-icons/fa";
import { useAxios } from "../../utils/api/api";


const ValidateInvite = () => {
  const { token } = useParams();
  const api = useAxios();
  const navigate = useNavigate();
  const [tokenValidate, setTokenValidate] = useState(false);
  const [funcaoOptions, setFuncaoOptions] = useState([]);
  const [contratoOptions, setContratoOptions] = useState([]);
  const[step, setStep] = useState(1);
  const[loading, setLoading] = useState(false);
  const[validePass, setValidePass] = useState(false);
  const[formValue, setFormValue] = useState({
    first_name: '',
    last_name: '',
    matricula: '',
    funcao: '',
    username: '',
    senha: '',
    confirmesenha: '',
    contrato: '',
    token: token
  
  });

  useEffect(() => {
    handleOnEnter();
  }, [token]);


  const handleOnEnter = async () => {
    try {
      const response = await api.get(`/api/v2/auth/user-invite/${token}`);
      if (response.status === 200) {
        setTokenValidate(true);
        setFuncaoOptions(response.data.funcao);
        setContratoOptions(response.data.contrato);
        setFormValue({...formValue, token: response.data.token})
      }
    }catch (error) {
      console.error("There was an error validating the token!", error);
      setTokenValidate(false);
      navigate('/login');
      alert('Token invalido')
    }
  }



  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await api.post(`/api/v2/auth/user-invite/`, formValue);
      if (response.status === 200) {
        setStep(3);
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      }

    }catch (error) {
      console.error("There was an error validating the token)", error);
      setLoading(false);
    }
  };




  const handleNext = (step) => {
    if(step === 1){
      if(formValue.first_name === '' || formValue.last_name === '' || formValue.matricula === '' || formValue.funcao === ''){
        return alert('Preencha todos os campos')
      }else{
        setStep(step + 1);
      }
    }else if(step === 2){
      if(formValue.username === '' || formValue.senha === '' || formValue.contrato === '' || validePass === true){
        return alert('Preencha todos os campos')
      }else{
        handleSubmit()
      }
    }

  }

  const handleBack = (step) => {
    setStep(step - 1);
  }

  

  return (
    <div className="main-invite">
      <div className="main-invite-row1">
        <span className="main-invite-title">Complete seu cadastro</span>
        <div style={{width: '100%'}}>
        <Progress.Line percent={step === 1 ? 10 : step === 2 ? 50 : 100 }  status={step === 3 ? 'success' : 'active'} />
        </div>
        <div style={{width: '100%'}}>
          <Form fluid style={{width: '100%'}}>
            {step === 1 && (
              <>
              <Form.Group controlId="name-1">
              <Form.ControlLabel>Primeiro Nome</Form.ControlLabel>
              <Form.Control 
              name="name"
              onChange={(value) => setFormValue({...formValue, first_name: value})}
               />
                </Form.Group>
                <Form.Group controlId="name-2">
                  <Form.ControlLabel>Ultimo Nome</Form.ControlLabel>
                  <Form.Control 
                  name="last_name"
                  onChange={(value) => setFormValue({...formValue, last_name: value})}
                   />
                </Form.Group>
                <Form.Group controlId="name-1">
                  <Form.ControlLabel>Matricula</Form.ControlLabel>
                  <Form.Control 
                  name="matricula"
                  type="number"
                  onChange={(value) => setFormValue({...formValue, matricula: value})}
                  />
                </Form.Group>
                <Form.Group controlId="funcao-3">
                  <Form.ControlLabel>Funcao</Form.ControlLabel>
                  <Form.Control 
                  style={{width: '100%'}}
                   name="funcao" 
                   placeholder='Selecione sua função'
                   accepter={InputPicker} 
                   onSelect={(value) => setFormValue({...formValue, funcao: value})}
                   data={funcaoOptions.map(f => ({label: f.nome, value: f.id}))}
                   menuMaxHeight={150}
                   />
                   
                </Form.Group>
              </>
            )}

            {step === 2 && (
              <>
                <Form.Group controlId="username">
                  <Form.ControlLabel>Nome de usuario</Form.ControlLabel>
                  <Form.Control 
                  name="username"
                  autoComplete="off"
                  onChange={(value) => setFormValue({...formValue, username: value})}
                  />
                </Form.Group>
                <Form.Group controlId="senha">
                  <Form.ControlLabel>Senha</Form.ControlLabel>
                  <Form.Control 
                  name="senha"
                  type="password"
                  autoComplete="off"
                  onChange={(value) => setFormValue({...formValue, senha: value})}
                  />
                </Form.Group>
                <Form.Group controlId="confirme-senha">
                  <Form.ControlLabel>Confirme sua senha</Form.ControlLabel>
                  <Form.Control 
                  name="confirme-senha"
                  type="password"
                  onChange={(value) => formValue.senha !== value ? setValidePass(true) : setValidePass(false)}
                  />
                  {validePass && <Form.HelpText style={{color: 'red'}}>Senha Invalida</Form.HelpText>}
                </Form.Group>

                <Form.Group controlId="contrato-3">
                  <Form.ControlLabel>Contrato</Form.ControlLabel>
                  <Form.Control style={{width: '100%'}} 
                    name="contrato" 
                    placeholder='Selecione seu contrato' 
                    menuMaxHeight={150}
                    menuStyle={{width: '10%'}} 
                    accepter={InputPicker} 
                    onSelect={(value) => setFormValue({...formValue, contrato: value})}
                    data={contratoOptions.map(c => ({label: c.nome, value: c.id}))} 
                    />
                </Form.Group>
              </>
            )}
            {step === 3 && (
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: 310}}>
                <div style={{marginRight: 10}}>
                  <span style={{fontSize: 20, color: '#08644c'}}>Cadastro realizado com sucesso!</span>
                  <p>Redirecionando para a pagina de login...</p>
                </div>
                <FaCheckCircle style={{fontSize: 100, color: '#08644c'}}/>
              </div>
            )}

          </Form>
          <div style={{marginTop: 50, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <Button type="submit" onClick={()=>handleBack(step)} appearance="primary" disabled={step === 1} style={{marginRight: 5, backgroundColor: 'gray'}}>
              Voltar
            </Button>
            <Button type="submit" onClick={()=>handleNext(step)} loading={loading} appearance="primary" style={{backgroundColor: '#08644c'}}>
              {step === 2 ? 'Finalizar' : step === 3 ? 'Acessar' : 'Proximo'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidateInvite;
