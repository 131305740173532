import './diretoriosScreen.css';
import { InputGroup, Input, IconButton, List, Placeholder, Breadcrumb, Button, Whisper, Dropdown, Popover } from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import MenuIcon from '@rsuite/icons/Menu';
import { useEffect, useState } from 'react';
import {  useLocation } from 'react-router-dom';
import { PiFoldersDuotone } from "react-icons/pi";
import { FaRegFileAlt } from "react-icons/fa";
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack'
import DocumentView from '../../components/documentView';
import { ModalConclused } from '../../components/modalReserva';
import { ModalCreateFolder } from '../../components/modalCreateFolder';
import { useAxios } from '../../utils/api/api';


const FolderMainScreen = () => {
    const api = useAxios();
    const [folderList, setFolderList] = useState([]);
    const [loading, setLoading] = useState(false);
    const[folderBreadcrumbs, setFolderBreadcrumbs] = useState([]);
    const[documentList, setDocumentList] = useState([]);
    const[openModalEnvio, setOpenModalEnvio] = useState(false);
    const[selectedDocument, setSelectedDocument] = useState(null);
    const[selectedFolder, setSelectedFolder] = useState(null);
    const location = useLocation();
    const { folder_id } = location.state || {};
    const[openDocument, setOpenDocument] = useState(false);
    const[search, setSearch] = useState('');
    const[permissions, setPermissions] = useState([]);
    const[openModaFolder, setOpenModalFolder] = useState(false);
    const[document, setDocument] = useState({
        id: null,
        documentTitle: null,
        documentName: null
    });


    

    useEffect(() => {
        if (folder_id) {
            handleGetSuperFolders(folder_id);
        } else {
            handleGetSuperFolders(null);
        }
    }, [folder_id]);








    const handleGetSuperFolders = async (parent) => {
        
        setLoading(true);
        setSelectedDocument(null);
        try {
            const response = await api.get(`/api/v1/pasta/main/`, {params: {parent: parent}})
            if (response.status === 200) {
                setFolderList(response.data.folders);
                setDocumentList(response.data.documents);
                setFolderBreadcrumbs(response.data.breadcrumbs);
                setLoading(false);
            }
        }catch(error){
            if(error.code === 'ERR_BAD_REQUEST'){
                enqueueSnackbar('Você não tem permissão para acessar essa pasta, Gostaria de solicitar?', {
                    action: (snackbarId) => action(snackbarId, parent),
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                })
            }
        }
        
        
    }



    const SolicitarAcesso = async (folder_id) => {
        try{
            const response = await api.post(`/api/v1/pasta/solicitacao-acesso/`, {pasta_id: folder_id})
            if(response.status === 201){
                enqueueSnackbar(`Solicitação de acesso enviada com sucesso`, {
                    variant:'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                })
                setTimeout(() => {
                    handleGetSuperFolders(null)
                }, 2000)
            }

        }catch(error){
            console.log(error)
        }
    }


    
    const handleKeyDown = (e) => {
        if(e.key === 'Enter'){
            handleGetFolders(search)
        
        }
    }


    const handleGetFolders = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/api/v1/pasta/main/`, {params: {search: search}})
            setFolderList(response.data.folders);
            setDocumentList(response.data?.documents);
            setLoading(false);
            setSearch('')
            
        } catch (error) {console.log(error)}
    }




    const action = (snackbarId, folder) => (
        <>
          <Button appearance="primary" style={{backgroundColor: 'green', margin: 5}} onClick={() => { closeSnackbar(snackbarId);SolicitarAcesso(folder)}}>
            Sim
          </Button>
          <Button appearance="primary" style={{backgroundColor: 'gray', margin: 5}} onClick={() => { closeSnackbar(snackbarId); handleGetSuperFolders(null)}}>
            Não
          </Button>
        </>
    );



    
    const renderMenu = ({ rowData, onClose, left, top, className }, ref) => {
        const handleSelect = () => {
          onClose();
          
        };
    
        

    
        return (
          <Popover ref={ref} className={className} style={{ left, top }} full>
            <Dropdown.Menu>
                {folderBreadcrumbs?.filter((crumb)=>crumb.label.toUpperCase() === 'REGISTRO DOCUMENTADO').length > 0 && (
                    <>
                    <Dropdown.Item  eventKey={1} onClick={()=>{onClose(); setOpenModalEnvio(true)}}>
                        Novo Documento
                    </Dropdown.Item>
                    {permissions?.can_create_folder && (
                        <Dropdown.Item eventKey={1} onClick={()=>setOpenModalFolder(true)}>
                            Nova Pasta
                        </Dropdown.Item>
                    )}
                    </>
                )}
                               
            </Dropdown.Menu>
          </Popover>
        );
      };





    return (
        <div className="folder-content" id="first-step">
            <SnackbarProvider/>
            <div className="folder-row-2">
                <div className="input-table-folder-01">
                    <div className='folder-row-3 diretorio-input'>
                        <div style={{marginTop: 10}}>      
                        <Breadcrumb>
                            <Breadcrumb.Item className='breadcrumb-item' onClick={()=>handleGetSuperFolders(null)}>RAIZ</Breadcrumb.Item>
                            {folderBreadcrumbs?.map((crumb, index)=>(
                                <>
                                <Breadcrumb.Item className='breadcrumb-item'  onClick={()=>handleGetSuperFolders(crumb.value)} key={index}>{crumb?.label.toUpperCase()}</Breadcrumb.Item> 
                                </>
                                
                            ))}
                        </Breadcrumb>
                        </div>
                        <div className='diretorio-input-colum2'>
                            <InputGroup style={{width: 250}} id='second-step'>
                                <Input placeholder='Pesquisar' value={search} onKeyDown={handleKeyDown} onChange={(value)=>setSearch(value.toUpperCase())}/>
                                <InputGroup.Addon style={{backgroundColor: '#53525200', fontSize: 8}}>
                                    <SearchIcon/>
                                </InputGroup.Addon>
                            </InputGroup>
                            <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu}>
                            <IconButton style={{backgroundColor: '#00664e', marginLeft: 5}} icon={<MenuIcon style={{color: '#fff'}}/>} id="btn-filter" appearance='primary'></IconButton> 
                            </Whisper>
                        </div>
                        
                    </div> 
                </div>
                
                <div className='table-documents' style={{height: '100vh'}}>
                    {!loading ? (
                        <>
                        {folderList?.map((folder, index) => (
                            <div key={index} style={{paddingLeft: 5, paddingRight: 5}}>
                                
                                <List key={index} >
                                    <List.Item className='list-hover' index={index} style={{padding: 10}}>
                                        <div className='list-folder-item'>
                                            <div className='list-folder-01' style={{width: '100%'}} onClick={()=>{ handleGetSuperFolders(folder.id); setSelectedFolder(folder)}}>
                                                <div className='list-folder-item-icon'>
                                                    <PiFoldersDuotone style={{fontSize: 40, color: 'gray'}}/>
                                                </div>
                                                <div className='list-folder-item-text'>
                                                    <strong>{folder.nome}</strong>
                                                    <div className='list-folder-item-text-01'>
                                                        <span style={{marginRight: 5}}>{folder.children?.length} Diretorios</span>
                                                        <>
                                                        {folder.documentos_count > 0 && (
                                                            <span> - {folder.documentos_count} Documentos</span>
                                                        
                                                        )}
                                                        </>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                    </List.Item>
                                </List>
                            </div>
                        ))}
                        <>
                        {documentList?.map((doc, index) => (
                            <div key={index}>
                                <List key={index} onClick={()=> {setOpenDocument(true); setDocument({id: doc.id, documentName: doc.codigo, documentTitle: doc.titulo})}}>
                                    <List.Item className='list-hover' index={index} style={{padding: 10}}>
                                        <div className='list-folder-item'>
                                            <div className='list-folder-01'>
                                                <div className='list-folder-item-icon'  onClick={()=>console.log(doc)}>
                                                    <FaRegFileAlt style={{fontSize: 30, color: 'gray'}}/>
                                                </div>
                                                <div className='document-list-01-text'>
                                                    <strong style={{fontSize: 12}}>{doc.codigo}</strong>
                                                    <div className='document-list-01-text-01'>
                                                        <span style={{marginRight: 5, fontSize: 10}}>{doc.titulo}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </List.Item>
                                </List>
                            </div>
                        ))}
                            
                        </>
                    </>
                    ):(
                        <>
                            <Placeholder.Paragraph graph="square" className='placeholder' rows={1} active rowHeight={30}/>
                            <Placeholder.Paragraph graph="square" className='placeholder' rows={1} active rowHeight={30}/>
                            <Placeholder.Paragraph graph="square" className='placeholder' rows={1} active rowHeight={30}/>
                            <Placeholder.Paragraph graph="square" className='placeholder' rows={1} active rowHeight={30}/>
                            <Placeholder.Paragraph graph="square" className='placeholder' rows={1} active rowHeight={30}/>
                            <Placeholder.Paragraph graph="square" className='placeholder' rows={1} active rowHeight={30}/>
                        </>
                    )}
                </div>
                           
            </div>
            <ModalCreateFolder showModal={openModaFolder} setShowModal={setOpenModalFolder} id={selectedFolder?.id} getFunction={handleGetSuperFolders}/>
            <ModalConclused showModal={openModalEnvio} setShowModal={setOpenModalEnvio} tipo={'documento'} id={selectedFolder?.id} getDocuments={handleGetSuperFolders}/>
            <DocumentView open={openDocument} setOpen={setOpenDocument} id={document.id} documentTitle={document.documentTitle} documentName={document.documentName}/>
        </div>
    );
}
export default FolderMainScreen;

