import { Routes, Route } from 'react-router-dom';
import GestaoArmazen from '.';
import MainLayout from '../../layouts/main';
import PedidoInfo from './apps/pedidoInfo';
import SolicitacaoAmz from './apps/solicitacaoAmz';



const AmzRouters = () => {
  return (
    <Routes>
        <Route path="/" element={<MainLayout><GestaoArmazen/></MainLayout>} />
        <Route path="chegada-pedidos" element={<MainLayout><PedidoInfo/></MainLayout>} />
        <Route path="solicitacao-armazem" element={<MainLayout><SolicitacaoAmz/></MainLayout>} />
    </Routes>
  );
};

export default AmzRouters;
