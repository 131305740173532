import PlusIcon from '@rsuite/icons/Plus';
import { IconButton } from "rsuite";
import { useState, useEffect } from "react";
import { useAxios } from "../../../../utils/api/api";
import ListChPedidos from './components/listChPedidos';
import './pedido-info.css';
import DataCardsPedidos from './components/dataCardPedidos';


const PedidoInfo = () => {
    const api = useAxios();
    const [open, setOpen] = useState(false);
    const [expand, setExpand] = useState(false);
    const [solicitacoes, setSolicitacoes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterOption, setFilterOption] = useState('1');
    const [pageSelected, setPageSelected] = useState('AG');


    useEffect(() => {
        handleGetSolicitacoes();
    }, [pageSelected]);


    const handleGroupData = (data, tipo) => {
        const dataGroup = data.reduce((acc, item) => {
            const numero_solicitacao = item?.pedido_compra.c7_num;
            let group = acc.find(group => group.numero === numero_solicitacao);
            if (!group) {
                acc.push({
                    numero: numero_solicitacao,
                    data: [item]
                });
            } else {
                // Substitui o item se já existir
                const existingItemIndex = group.data.findIndex(i => i.id === item.id);
                if (existingItemIndex > -1) {
                    group.data[existingItemIndex] = item;
                } else {
                    group.data.push(item);
                }
            }
            return acc;
        }, []);
    
        if (tipo === 'isGet') {
            // console.log(dataGroup);
            setSolicitacoes(dataGroup);
        } else if (tipo === 'isNew') {
            setSolicitacoes((prevSolicitacoes) => [...dataGroup, ...prevSolicitacoes]);
        } else if (tipo === 'isUpdate') {
            setSolicitacoes((prevSolicitacoes) => {
                return prevSolicitacoes.map(group => {
                    const updatedGroup = dataGroup.find(dg => dg.numero === group.numero);
                    return updatedGroup ? { ...group, data: updatedGroup.data } : group;
                });
            });
        }
    };


    

    const handleGetSolicitacoes = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/v2/modules/gestao-amoxarifado/pedido-info/',{
                params: {
                    type: pageSelected
                }
            });
            // const dt = handleGroupData(response.data, 'isGet');
            console.log(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }



    const handleFilterSolicitacoes = async (value) => {
            if(value.length > 2){
                setLoading(true);
            try {
                const response = await api.get('/api/v2/modules/gestao-amoxarifado/pedido-info/',{
                    params: {
                        filter: value,
                        type: filterOption
                        
                    }
                });
                const dt = handleGroupData(response.data, 'isGet');
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
    }





    const renderInput = () => {
        let tipe = 'text';
        if(filterOption === '3'){
            tipe = 'date';
        }else{
            tipe = 'text';
        }
        return(
            <input type={tipe} placeholder="Pesquisar" id="reserva-input" className="rs-input" onChange={(e)=>handleFilterSolicitacoes(e.target.value)}/>
        )
    }
    


    return (
        <div className="folder-content">
            <div className='container-puchare'>
                <div className="folder-row-2" style={{width: '100%'}}>
                    <div className="container-data-main top" >
                        <div style={{width: '100%', marginRight: 10}}>
                            <div className="rs-input-group">
                                <select className="input-select" defaultValue="1" onChange={(e)=>setFilterOption(e.target.value)} style={{fontSize: 9}}>
                                    <option value="1">Solicitação</option>
                                    <option value="2">Solicitante</option>
                                    <option value="3">Data</option>
                                    <option value="4">Centro de custo</option>
                                </select>
                                {renderInput()}
                                
                            </div>
                        </div>
                    </div>
                    <div className="data-cards-content">
                        <DataCardsPedidos setPageSelected={setPageSelected}/>
                    </div>
                                        
                    <div className='container-data-main'>
                        <ListChPedidos solicitacoes={solicitacoes} loading={loading} handleGroupData={handleGroupData}/>
                    </div>
                </div>    
            </div>
            
        </div>
    );
}
export default PedidoInfo;

