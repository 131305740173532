import { Modal, Button, Uploader, InputPicker, Progress, Input } from "rsuite";
import { useState } from "react";
import { BASE_URL } from "../../utils/api/base";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";






export const ModalConcluirCheckIn = ({ showModal, setShowModal, checkInData, getDocuments, setSelectDocument }) => {
    const user = useSelector(state => state.auth);
    const [fileList, setFileList] = useState({
        file: [],
        anexo: []
    });
    const [loader, setLoader] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const[step, setStep] = useState(1)
    const[descricao, setDescricao] = useState('')
    const[tipoAtualizacao, setTipoAtualizacao] = useState(null)


 
    

    



    const handleUpdateReserva = (id) => {
        const formData = new FormData();
        
        if (fileList.file) {
            formData.append('file', fileList.file[0].blobFile);
        }
        
        if (fileList.anexo) {
            fileList.anexo.forEach((file) => {
                formData.append('anexos', file.blobFile);
            });
        }
        
        formData.append('concluido', true);
        formData.append('data', moment().format('YYYY-MM-DD'));
        formData.append('descricao', descricao);
        formData.append('tipo_atualizacao', tipoAtualizacao);
        setLoader(true);
        axios({
            method: 'PUT',
            url: `${BASE_URL}/api/v1/documento/checkin/${id}/`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${user.token.access}`,
            },
            data: formData,
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted);
            },
        })
        .then((response) => {
            
            setTimeout(()=>{
                getDocuments()
                setFileList({ file: [], anexo: [] });
                setShowModal(false);
                setUploadProgress(0);
                setLoader(false);
                setSelectDocument(null)
            },500)
        })
        .catch((error) => {
            console.log(error);
            setUploadProgress(0);
            setLoader(false);
        });
    };
    


    const handleNextStep = () => {
        if(step === 1){
            setStep(step + 1)
        }else if(step === 2){
            handleUpdateReserva(checkInData.id)
        }
        

    }
   



    return (
        <Modal open={showModal} overflow={true} size="md" onClose={() => setShowModal(false)}>
            <Modal.Header>
                <Modal.Title>Concluir CheckIn</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', maxHeight: 400 }}>
                {loader && (
                    <div style={{position: 'absolute', left: 0, botton: 0, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <Progress.Line percent={uploadProgress} status={uploadProgress === 100 ? 'success' : 'active'} strokeColor={uploadProgress === 100 ? '#52c41a' : '#3385ff'} />
                    </div>
                )}
                {step === 1 && (
                    <div style={{ width: '100%', padding: 5, marginTop: 20, overflowY: 'scroll' }} className="no-scrollbar">
                    <Uploader
                        fileList={fileList.file}
                        accept=".docx,.csv,.xls,.pdf"
                        draggable
                        loading={true}
                        autoUpload={false}
                        removable={true}
                        onRemove={(file) => setFileList({ ...fileList, file: [] })}
                        onChange={(e) => { 
                            if(fileList.file.length === 0){
                                setFileList({...fileList, file:[...e]})
                                
                            }
                         }}
                    >

                        <div style={{ height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <span>Adicionar documento</span>
                        </div>
                    </Uploader>

                    <Uploader
                        fileList={fileList.anexo}
                        draggable
                        autoUpload={false}
                        removable={true}
                        onRemove={(file) => 
                            fileList.anexo.filter((item) => item !== file).map((item) => setFileList({...fileList, anexo: item}))
                        }
                        onChange={(e) => { setFileList({...fileList, anexo:[...e]}) }}
                    >

                        <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <span>Adicionar anexos</span>
                        </div>
                    </Uploader>
                </div>
                )}
                {step === 2 && (
                    <div style={{ width: '100%', padding: 5, marginTop: 20, overflowY: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minHeight: 300}} className="no-scrollbar">
                        <div style={{width: '100%', marginBottom: 10, height: 100}}>
                            <label>Descrição</label>
                            <Input as="textarea" rows={3} onChange={(text)=>setDescricao(text)} value={descricao} placeholder="" />
                        </div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', height: 100}}>
                            <label>Tipo</label>
                            <InputPicker data={[
                                { label: 'Atualização - Alteração total do documento', value: '0' },
                                { label: 'Revisão - Alteração Pontual', value: '1' },
                            ]} onSelect={(value)=>setTipoAtualizacao(value)} placeholder="Selecione o Tipo" />
                        </div>
                    </div>
                )}

            </Modal.Body>
            <Modal.Footer>
                {step === 2 && (
                    <Button onClick={() => setStep(1)} appearance="primary" style={{backgroundColor: 'gray'}}>
                    Voltar
                    </Button>
                )}
                <Button appearance="primary"  style={{backgroundColor: step === 1 ? '' : '#00664e'}} loading={loader} onClick={() => handleNextStep()}>
                    {step === 1 ? 'Próximo' : 'Concluir'}
                </Button>
                <Button onClick={() => {setShowModal(false); setStep(1)}} appearance="subtle">
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

