import React,{ useState, useEffect } from "react";
import { BASE_URL } from "../../../../utils/api/base";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack'
import { InputGroup, Input, IconButton, Breadcrumb, Button} from 'rsuite';
import './gs-orcamento.css';



const NavLink = React.forwardRef((props, ref) => {
    const { to, children, ...rest } = props;
    return (
        <Link to={to} ref={ref} {...rest}>
            {children}
        </Link>
    );
});


const GestaoOrcamento = () => {
    const id = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { appName } = location.state || {}; 
    const userAuth = useSelector((state) => state.auth);


    useEffect(()=>{
        console.log(id);
        axios({
            method: 'GET',
            url: `${BASE_URL}/api/v1/aplicativo/open-app?app_id=${id.id}`,
            headers: {
                Authorization: `Bearer ${userAuth.token.access}`
            }
        }).then((response) => {
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
            if(error.code === 'ERR_BAD_REQUEST'){
                if(error.response.data.error === 'Acesso negado')
                    enqueueSnackbar('Você não tem acesso à o ambiente atual. Deseja solicitar acesso?', {
                        action,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        persist: true
                    })
            }
        
        })
    },[])


    const action = snackbarId => (
        <>
          <Button appearance="primary" style={{backgroundColor: 'green', margin: 5}} onClick={() => { closeSnackbar(snackbarId)}}>
            Sim
          </Button>
          <Button appearance="primary" style={{backgroundColor: 'gray', margin: 5}} onClick={() => { closeSnackbar(snackbarId); navigate('/aplicativos')}}>
            Não
          </Button>
        </>
    );


    return (
        <div className="folder-content" style={{padding: 10}}>
            <SnackbarProvider/>
            <div className="folder-row-1 user-space">
                <div className="folder-child-01">
                    <Breadcrumb>
                        <Breadcrumb.Item as={NavLink} to='/aplicativos'>
                        APLICATIVOS
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{appName}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>    
            </div>
            <div className="folder-row-2">
                <div className='table-documents apps'>
                
                </div>
            </div>
        </div>
    );
}

export default GestaoOrcamento;