import './reservas.css';
import TableReserva from '../../components/tableReservas';
import { InputGroup, Input, IconButton, Popover, Whisper, Dropdown, Button } from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import PlusIcon from '@rsuite/icons/Plus';
import MenuIcon from '@rsuite/icons/Menu';
import { useState, useEffect } from 'react';
import DrawerReserva from '../../components/drawerReserva';
import { ModalConclused } from '../../components/modalReserva';
import { useAxios } from '../../utils/api/api';


const ReservasScreen = () => {
    const api = useAxios();
    const [documents, setDocuments] = useState([]);
    const[loading, setLoading] = useState(false);
    const[showDrawer, setShowDrawer] = useState(false);
    const[showModal, setShowModal] = useState(false);
    const[reserva_id, setReserva_id] = useState(null);
    const[filter, setFilter] = useState('is_user');
    const [search, setSearch] = useState('')

    useEffect(() => {
        getDocuments(filter)
    }, [search]);


    


    const getDocuments = async (filter) => {
        setLoading(true);
        try {
            const response = await api.get('/api/v1/flow/document-reserve/', {params: {[filter]: search === '' ? true : search}});
            if(response.status === 200){
                setLoading(false);
                setDocuments(response.data);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }


    const deleteDocument = async (id, dc) => {
        setLoading(true);
        try {
            const response = await api.delete(`/api/v1/flow/document-reserve/${id}/`);
            setLoading(false);
            getDocuments('is_user');
            if(dc){
                setShowModal(false);
            }
            
        }catch (error) {
            console.log(error);
            setLoading(false);
        }
    }



    const handleSearch = (value) => {
        if(value.length > 2){
            setFilter('search');
            setSearch(value);
        }else{
            setDocuments([]);
            setFilter('is_user');
            setSearch('');
        }    
    }



    const renderMenu = ({ rowData, onClose, left, top, className }, ref) => {
        const handleSelect = () => {
          onClose();
          
        };
    
        

    
        return (
          <Popover ref={ref} className={className} style={{ left, top }} full>
            <Dropdown.Menu>
                <Dropdown.Item onClick={()=>{onClose(); setSearch(''); getDocuments('is_user') }} eventKey={1}>
                    Minhas Reservas
                </Dropdown.Item>
                <Dropdown.Item eventKey={1} onClick={()=>{onClose(); setSearch(''); getDocuments('is_pending') }}>
                    Pendentes
                </Dropdown.Item>
                <Dropdown.Item eventKey={1} onClick={()=>{onClose(); setSearch(''); getDocuments('is_reprove') }}>
                    Reprovadas
                </Dropdown.Item>                
            </Dropdown.Menu>
          </Popover>
        );
      };
    



    return (
        <div className="folder-content">
            <div className="folder-row-2">
                <div className="input-table-folder-01">
                    
                    <IconButton style={{backgroundColor: '#00664e'}} id="reserva-btn" icon={<PlusIcon style={{color: '#fff', backgroundColor: '#00664e'}}/>} appearance='primary' onClick={()=>setShowDrawer(true)}>Iniciar reserva</IconButton>    
                    <div className='folder-row-3'>
                        <InputGroup style={{width: 250}}>
                            <Input placeholder='Pesquisar' id="reserva-input" onChange={(value)=>handleSearch(value.toUpperCase())}/>
                            <InputGroup.Addon style={{backgroundColor: '#53525200', fontSize: 8}}s>
                                <SearchIcon />
                            </InputGroup.Addon>
                        </InputGroup>
                        <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu}>
                        <IconButton style={{backgroundColor: '#00664e', marginLeft: 5}} icon={<MenuIcon style={{color: '#fff'}}/>} id="btn-filter" appearance='primary'></IconButton> 
                        </Whisper>
                        
                    </div>   
                </div>
                <div className='table-documents'>
                    <TableReserva loading={loading} documents={documents} setReserva_id={setReserva_id} setShowModal={setShowModal} deleteDocument={deleteDocument}/>
                </div>
                <DrawerReserva showDrawer={showDrawer} setShowDrawer={setShowDrawer} loader={loading} setLoading={setLoading} getDocuments={getDocuments} documents={documents}/>
                <ModalConclused showModal={showModal} tipo={'reserva'} setShowModal={setShowModal} id={reserva_id} getDocuments={getDocuments}/>           
            </div>
        </div>
    );
}
export default ReservasScreen;