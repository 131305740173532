import { Drawer, ButtonToolbar, Button, Placeholder } from 'rsuite';
import { useState, useEffect } from 'react';
import { BASE_URL } from '../../utils/api/base';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import moment from 'moment';
import ReviewRefuseIcon from '@rsuite/icons/ReviewRefuse';
import ReviewPassIcon from '@rsuite/icons/ReviewPass';
import WaitIcon from '@rsuite/icons/Wait';
import 'react-vertical-timeline-component/style.min.css';
import { MdEditDocument } from "react-icons/md";
import { GoWorkflow } from "react-icons/go";
import './time-line.css';
import { useSelector } from 'react-redux';

const TimeLine = ({ open, setOpen, document }) => {
    const [timeLine, setTimeLine] = useState([]);
    const auth = useSelector(state => state.auth);
    const [documentoDetails, setDocumentoDetails] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (document) {
            setLoader(true);
            setDocumentoDetails(null);
            axios({
                method: 'GET',
                url: `${BASE_URL}/api/v1/flow/document-workflow/?document_id=${document}`,
                headers: {
                    'Authorization': `Bearer ${auth.token.access}`,
                }
            })
            .then(response => {
                setLoader(false);
                const data = response.data[0];
                setDocumentoDetails(data.reserva);

                // Agrupar aprovações por nível, removendo duplicações de aprovações pendentes
                const groupedApprovals = data.approvals.reduce((acc, current) => {
                    if (!acc[current.nivel]) {
                        acc[current.nivel] = { ...current, users: [current.user] };
                    } else if (current.status === 'pendente') {
                        acc[current.nivel] = { ...current, users: [...acc[current.nivel].users, current.user] };
                    } else {
                        acc[current.nivel].users.push(current.user);
                    }
                    return acc;
                }, {});

                // Converter o objeto agrupado de volta em uma lista
                const approvalsList = Object.values(groupedApprovals);

                setTimeLine({ ...data, approvals: approvalsList });
            }).catch(error => {
                console.log(error);
                setLoader(false);
            })
        }
    }, [document]);

    const renderStatus = (item) => {
        return (
            <>
                {item.status === 'pendente' ? (
                    <span style={{marginLeft: 5}}>Aguardando a Aprovação</span>
                ) : item.status === 'aprovado' ? (
                    <span style={{marginLeft: 5}}>Aprovado</span>
                ) : item.status === 'reprovado' ? (
                    <>
                        <span style={{marginLeft: 5}} >Reprovado</span>
                        <span><br /> Motivo: <br /> - {item.obs}</span>
                    </>
                ) : null}
            </>
        )
    }

    const renderStatusIcon = (item) => {
        return (
            <>
                {item.status === 'pendente' ? (
                    <WaitIcon style={{ color: '#fff', fontSize: 20, }} />
                ) : item.status === 'aprovado' ? (
                    <ReviewPassIcon style={{ color: '#fff', fontSize: 20, }} />
                ) : item.status === 'reprovado' ? (
                    <ReviewRefuseIcon style={{ color: '#fff', fontSize: 20, }} />
                ) : null}
            </>
        )
    }

    const renderNivelTitle = (nivel_name) => {
        switch (nivel_name) {
            case 'Verificadores':
                return '- Departamento da Qualidade';
            case 'Gestor':
                return '- Gestor da Área';
            case 'Segurança':
                return '- Departamento de Segurança';
            case 'Jurídico':
                return '- Departamento Jurídico';
            case 'Autorizadores':
                return '- Diretoria Responsável';
            case 'Marketing':
                return '- Departamento de Marketing';   
            default:
                return '';
        }
    }

    return (
        <>
            <Drawer open={open} onClose={() => setOpen(false)}>
                <Drawer.Header>
                    <Drawer.Title style={{ fontSize: 15, color: '#fff' }}>TimeLine Do Documento</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body className='drawer-timeline'>
                    {loader ? (
                        <div style={{ width: '90%', padding: 10 }}>
                            <Placeholder.Paragraph />
                        </div>
                    ) : (
                        <VerticalTimeline style={{ width: '100%' }} lineColor={"#efefef"}>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: '#077844d5', color: '#fff' }}
                                iconStyle={{ background: '#077844d5', color: '#fff' }}
                                icon={<MdEditDocument />}
                            >
                                <div className='timeline-content'>
                                    <strong style={{ fontSize: 15 }}>
                                        - Autor
                                    </strong>
                                    <div className='timeline-content-01'>
                                        <span style={{ fontSize: 13, marginBottom: 2 }}>
                                            {timeLine?.reserva?.execultor?.first_name} {timeLine?.reserva?.execultor?.last_name}
                                        </span>
                                        <span>
                                            Reservado em: {moment(timeLine?.reserva?.data_reserva).format('L')}
                                        </span>
                                    </div>
                                </div>
                            </VerticalTimelineElement>

                            {timeLine?.reserva?.documento === null || timeLine?.reserva?.documento === undefined ? (
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: '#077844d5', color: '#fff' }}
                                    iconStyle={{ background: '#077844d5', color: '#fff' }}
                                    icon={<WaitIcon style={{ color: '#fff' }} />}
                                >
                                    <div className='timeline-content'>
                                        <strong style={{ fontSize: 15 }}>
                                            - Autor
                                        </strong>
                                        <div className='timeline-content-01'>
                                            <span style={{ fontSize: 13, marginBottom: 2 }}>
                                                {timeLine?.reserva?.execultor?.first_name} {timeLine?.reserva?.execultor?.last_name}
                                            </span>
                                            <span>
                                                Inicio: {moment(timeLine?.reserva?.data_reserva).format('L')}
                                            </span>
                                        </div>
                                    </div>
                                </VerticalTimelineElement>
                            ) : (
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: '#077844d5', color: '#fff' }}
                                    iconStyle={{ background: '#077844d5', color: '#fff' }}
                                    icon={<GoWorkflow style={{ color: '#fff' }} />}
                                >
                                    <div className='timeline-content'>
                                        <strong style={{ fontSize: 15 }}>
                                            - Autor
                                        </strong>
                                        <div className='timeline-content-01'>
                                            <span style={{ fontSize: 13, marginBottom: 2 }}>
                                                {timeLine?.reserva?.execultor?.first_name} {timeLine?.reserva?.execultor?.last_name}
                                            </span>
                                            <span>
                                                Entrada do documento: {moment(timeLine?.reserva?.data_reserva).format('L')}
                                            </span>
                                        </div>
                                    </div>
                                </VerticalTimelineElement>
                            )}

                            {timeLine?.approvals?.map((approvalGroup, index) => (
                                <VerticalTimelineElement
                                    key={index}
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: '#077844d5', color: '#fff' }}
                                    iconStyle={{ background: '#077844d5', color: '#fff' }}
                                    icon={renderStatusIcon(approvalGroup)}
                                >
                                    <div className='timeline-content'>
                                        <strong style={{ fontSize: 15 }}>
                                            {renderNivelTitle(approvalGroup.nivel_name)}
                                        </strong>
                                        <div className='timeline-content-01'>
                                            {approvalGroup.users.length > 1 && approvalGroup.status === 'pendente' ? (
                                                <span style={{ fontSize: 13, marginBottom: 2 }}>
                                                    Aguardando Aprovação da Aréa
                                                </span>
                                            ) : approvalGroup.users.map((user, index) => (
                                                <div key={index}>
                                                    <span style={{ fontSize: 13, marginBottom: 2 }}>
                                                        {user.first_name} {user.last_name}
                                                    </span>
                                                    <span>
                                                        {renderStatus(approvalGroup)}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </VerticalTimelineElement>
                            ))}
                        </VerticalTimeline>
                    )}
                </Drawer.Body>
            </Drawer>
        </>
    );
};

export default TimeLine;
