import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';

// Função para gerar um documento Word (.docx)
function generate(zip, tipo, codigo, titulo, emissao, classificacao) {
    var doc = new Docxtemplater(zip);
    doc.setData({
        "documento": tipo,
        "codigo": codigo,
        "titulo": titulo,
        "emissao": emissao,
        "classificacao": classificacao,
    });

    try {
        doc.render();
    } catch (error) {
        console.error('Erro ao renderizar o documento:', error);
        throw error;
    }

    var out = doc.getZip().generate({
        type: "blob",
        mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    saveAs(out, `${codigo}.docx`);
}

// Função para gerar um arquivo Excel (.xlsx)
async function generateExcel(codigo, fileLocation) {
    try {
        const response = await fetch(fileLocation);
        const blobOutput = await response.blob();
        saveAs(blobOutput, `${codigo}.xlsx`);
    } catch (error) {
        console.error('Erro ao buscar ou processar o arquivo Excel:', error);
    }
}

// Função para gerar arquivos BPM (.bpm)
async function generateBpm(codigo, fileLocation) {
    try {
        const response = await fetch(fileLocation);
        const blobOutput = await response.blob();
        saveAs(blobOutput, `${codigo}.bpm`);
    } catch (error) {
        console.error('Erro ao buscar ou processar o arquivo BPM:', error);
    }
}

// Função para decidir qual template usar com base no tipo e no template_type
const handleTemplateType = (template_type, tipo, fallbackType) => {
    let template = `template.${tipo.toLowerCase()}`;
    switch (template_type.toLowerCase()) {
        case 'map':
            template = 'map.bpm';
            break;
        case 'sla':
            template = 'sla.xlsx';
            break;
        case 'raci':
            template = 'raci.xlsx';
            break;
        case 'deip':
            template = 'deip.xlsx';
            break;
        default:
            template = `template_1.${fallbackType.toLowerCase()}`;
            break;
    }
    return template;
};

export const CreateTemplate = (tipo, codigo, titulo, type, emissao, classificacao, template_type) => {
    let template = handleTemplateType(template_type, tipo, type); // Usando 'type' como fallback
    let fileLocation = `/templates/${template}`;

    const classificacoes = ['Público', 'Restrita', 'Confidencial', 'Uso Interno'];
    classificacao = classificacoes[classificacao] || 'Desconhecida';

    if (template.includes('docx')) {
        PizZipUtils.getBinaryContent(fileLocation, function (error, content) {
            if (error) {
                console.error('Erro ao carregar o arquivo .docx:', error);
                throw error;
            }
            var zip = new PizZip(content);
            generate(zip, tipo, codigo, titulo, emissao, classificacao);
        });
    } else if (template.includes('xlsx')) {
        generateExcel(codigo, fileLocation);
    } else {
        generateBpm(codigo, fileLocation);
    }
};
