import './table-reservas.css';
import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Table, Pagination, IconButton, Popover, Whisper, Dropdown, Button, Modal } from 'rsuite';
import MoreIcon from '@rsuite/icons/legacy/More';
import RemindIcon from '@rsuite/icons/legacy/Remind';
import { useNavigate } from 'react-router-dom';
import TimeLine from '../drawerTimeLine';
import DocumentView from '../documentView';

const { Column, HeaderCell, Cell } = Table;

const TableReserva = ({ loading, documents, setReserva_id, setShowModal, deleteDocument }) => {
  const user = useSelector(state => state.auth);
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const	[showTimeLine, setShowTimeLine] = useState(false);
  const[documentId, setDocumentId] = useState(null);
  const[openDocument, setOpenDocument] = useState(false);
  const[selectedDocument, setSelectedDocument] = useState({
    id: null,
    title: null,
    name: null
  });

  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  const data = documents.slice(limit * (page - 1), limit * page);
  const ModalCancel = () => {
    const confirmDelete = () => {
      var dc = documents.filter(item => item.id === deleteId)
      deleteDocument(deleteId, dc[0].codigo_plataforma);
      setOpen(false);
    };

    return (
      <Modal backdrop="static" role="alertdialog" open={open} onClose={() => setOpen(false)} size="xs">
        <Modal.Body>
          <RemindIcon style={{ color: '#ffb300', fontSize: 30 }} />
          Atenção! Deseja realmente cancelar a reserva?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={confirmDelete} appearance="primary">
            Ok
          </Button>
          <Button onClick={() => setOpen(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderMenu = ({ rowData, onClose, left, top, className }, ref) => {
    const handleSelect = () => {
      onClose();
      setReserva_id(rowData.id);
      setShowModal(true);
    };

    const handleDelete = () => {
      setDeleteId(rowData.id);
      onClose();
      setOpen(true);
    };

    const openDocument = () =>{
      setSelectedDocument({
        id: rowData.documents.id,
        title: rowData.titulo,
        name: rowData.codigo
      })
      setOpenDocument(true);
      onClose();
    }





    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu>
          


          {parseInt(user.userId) === parseInt(rowData.execultor.id) && rowData?.status === '0' && (
            <>
              <Dropdown.Item eventKey={1} onClick={handleSelect}>
                Concluir
              </Dropdown.Item>
            </>
          )}
          {rowData?.documents?.id && parseInt(user.userId) === parseInt(rowData?.execultor.id)  && <Dropdown.Item onClick={()=>openDocument()} eventKey={1}>Abrir Documento</Dropdown.Item>}
          
          {rowData?.documents?.id && <Dropdown.Item onClick={()=>{onClose(); setShowTimeLine(true); setDocumentId(rowData?.documents?.id)}} eventKey={1}>Abrir TimeLine</Dropdown.Item>}
          
          {rowData?.documents?.status === '2' && parseInt(user.userId) === parseInt(rowData.execultor.id) && (
            <Dropdown.Item eventKey={7} onClick={handleSelect}>Reeviar Documento</Dropdown.Item>
          )}
          <>
          {parseInt(user.userId) === parseInt(rowData?.execultor?.id) && rowData?.status != '3' && (
            <Dropdown.Item eventKey={1} onClick={handleDelete}>
              Cancelar
            </Dropdown.Item>
          )}
          </>

        </Dropdown.Menu>
      </Popover>
    );
  };

  const ActionCell = ({ rowData, dataKey, ...props }) => {
    const renderMenuWithRowData = (menuProps, ref) => {
      return renderMenu({ ...menuProps, rowData }, ref);
    };

    return (
      <Cell {...props} className="link-group">
        <Whisper trigger="click" speaker={renderMenuWithRowData} placement="bottomEnd">
          <IconButton appearance="subtle" icon={<MoreIcon />} style={{ marginTop: -10 }} />
        </Whisper>
      </Cell>
    );
  };






  return (
    <div className="containter-table-documents">
      <Table loading={loading} height={520} data={data} bordered style={{ borderRadius: 5, padding: 5, fontFamily: 'sans-serif' }}>
        <Column width={200} fullText>
          <HeaderCell className="table-header-documents">CODIGO</HeaderCell>
          <Cell dataKey="codigo" />
        </Column>

        <Column  flexGrow={1} fullText>
          <HeaderCell className="table-header-documents">TITULO</HeaderCell>
          <Cell dataKey="titulo" />
        </Column>


        <Column width={150} align='center' fullText>
          <HeaderCell className="table-header-documents">AUTOR</HeaderCell>
          <Cell>
            {rowData => (
              <span>
                {rowData?.execultor?.first_name} {rowData?.execultor?.last_name}
              </span>
            )}
          </Cell>
        </Column>

        <Column width={200} align="center">
          <HeaderCell className="table-header-documents">DATA DA RESERVA</HeaderCell>
          <Cell>{rowData => moment(rowData.data_reserva).format('DD/MM/YYYY')}</Cell>
        </Column>

        <Column width={200} align="center">
          <HeaderCell className="table-header-documents">DATA DE ENTRADA</HeaderCell>
          <Cell>
            {rowData => (rowData.data_upload ? moment(rowData.data_upload).format('DD/MM/YYYY') : 'EM PROGRESSO')}
          </Cell>
        </Column>

        <Column width={200} align="center">
          <HeaderCell className="table-header-documents">STATUS</HeaderCell>
          <Cell>
            {rowData => {
              const color = rowData.status === '0' ? '#eec230' : rowData.status === '1' ? '#8cb7fd' : rowData.status === '2' ? 'red' : rowData.status === '3' ? 'green' : '';
              const text = rowData.status === '0' ? 'PENDENTE' : rowData.status === '1' ? 'EM APROVAÇÃO' : rowData.status === '2' ? 'REPROVADO' : rowData.status === '3' ? 'CONCLUIDO' : '';
              return <span style={{ color }}>{text}</span>;
            }}
          </Cell>
        </Column>

        <Column width={80}>
          <HeaderCell className="table-header-documents">...</HeaderCell>
          <ActionCell dataKey="id" />
        </Column>
      </Table>
      <ModalCancel />
      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['-', 'pager']}
          total={documents.length}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
      <DocumentView  open={openDocument} setOpen={setOpenDocument} id={selectedDocument.id} documentTitle={selectedDocument.title} documentName={selectedDocument.name} />
      <TimeLine open={showTimeLine} setOpen={setShowTimeLine} document={documentId}/>
    </div>
  );
};

export default TableReserva;
