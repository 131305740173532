import { useEffect } from "react";
import { BASE_URL } from "../../utils/api/base";
import { useSelector } from "react-redux";


const GestaoNaoConformidades = () => {
    const auth = useSelector(state => state.auth);


    const handleOpenApp = () => { 
        fetch(`${BASE_URL}/api/v2/modules/nonconformities/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth?.token?.access}`
            }
        })
    }

    useEffect(() => {
        handleOpenApp();
    }, []);


    

    
    return (
        <div className="folder-content">
            <div className="folder-row-2">
                <div className='table-documents main' style={{paddingLeft: '1.2%', paddingRight: '1.2%', paddingTop: 0}}>
                <iframe
                    src={`${BASE_URL}/api/v2/modules/nonconformities/`}
                    title="Non Conformit Module"
                    sandbox="allow-same-origin allow-scripts allow-forms"
                    style={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                    }}
                />
                </div>
            </div>
        </div>
    )
}
export default GestaoNaoConformidades;