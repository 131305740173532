import { Modal, Button, Uploader, Form, Progress, InputPicker } from "rsuite";
import { useState, useContext, useEffect } from "react";
import { BASE_URL } from "../../utils/api/base";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import './modal-reserva.css';
import axios from "axios";






const privacidade = [
    {
        label: 'Confidencial',
        value: 2
    },
    {
        label: 'Uso Interno',
        value: 3
    }
]


const tipoDocumento = [
    {
        label: 'DOCX',
        value: 1
    },
    {
        label: 'PDF',
        value: 2
    },
    {
        label: 'XLS',
        value: 3
    },
    {
        label: 'CSV',
        value: 4
    },
    {
        label: 'JPG',
        value: 5
    },
]


export const ModalConclused = ({ showModal, setShowModal, tipo, id, getDocuments }) => {
    const user = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState({
        file: [],
        anexo: []
    });
    const [loader, setLoader] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [formData, setFormData] = useState(null);
    const [step, setStep] = useState(0);
   
    
    useEffect(() => {
        if(tipo === 'documento'){
            setFormData({
                codigo: '',
                descricao: '',
                data_vencimento: '',
                privacidade: '',
                tipo_documento: ''
            })
        }
    }, []);
    



    const handleUpdateReserva = (id) => {
        const formMetaData = new FormData();
        
        if (fileList.file) {
            formMetaData.append('file', fileList.file[0].blobFile);
        }
        
        if (fileList.anexo) {
            fileList.anexo.forEach((file) => {
                formMetaData.append('anexos', file.blobFile);
            });
        }
        
        formMetaData.append('concluido', true);
        formMetaData.append('data', moment().format('YYYY-MM-DD'));
        setLoader(true);
        axios({
            method: 'PUT',
            url: `${BASE_URL}/api/v1/flow/document-reserve/${id}/`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${user.token.access}`,
            },
            data: formMetaData,
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted);
            },
        })
        .then((response) => {
            
            setTimeout(()=>{
                getDocuments('is_user');
                setFileList({ file: [], anexo: [] });
                setShowModal(false);
                setUploadProgress(0);
                setFormData(null);
                setLoader(false);
            },500)
        })
        .catch((error) => {
            console.log(error);
            setUploadProgress(0);
            setLoader(false);
        });
    };
    


        
    const handlePostDocument = (id) => {
        const formMetaData = new FormData();
        
        

        if (fileList.file) {
            formMetaData.append('file', fileList.file[0].blobFile);
        }
        
        if (fileList.anexo) {
            fileList.anexo.forEach((file) => {
                formMetaData.append('anexos', file.blobFile);
            });
        }
        
        formMetaData.append('folder', id);
        formMetaData.append('codigo', formData.codigo);
        formMetaData.append('descricao', formData.descricao);
        formMetaData.append('privacidade', formData.privacidade);
        formMetaData.append('tipo_documento', formData.tipo_documento);

        setLoader(true);
        axios({
            method: 'POST',
            url: `${BASE_URL}/api/v1/documento/create_document_by_folder/`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${user.token.access}`,
            },
            data: formMetaData,
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted);
            },
        })
        .then((response) => {
            
            setTimeout(()=>{
                getDocuments(id);
                setFileList({ file: [], anexo: [] });
                setShowModal(false);
                setUploadProgress(0);
                setLoader(false);
            },500)
        })
        .catch((error) => {
            console.log(error);
            setUploadProgress(0);
            setLoader(false);
        });
    };


    

    const renderUploadFile = () => (
        <>
        <Uploader
            fileList={fileList.file}
            accept=".docx,.csv,.xls,.pdf"
            draggable
            loading={true}
            autoUpload={false}
            removable={true}
            onRemove={(file) => setFileList({ ...fileList, file: [] })}
            onChange={(e) => { 
                if(fileList.file.length === 0){
                    setFileList({...fileList, file:[...e]})
                    const uploadedFile = e[0];
                    const fileExtension = uploadedFile.name.split('.').pop();
                    setFormData({...formData, tipo_documento: fileExtension})
                }
            }}
        >

            <div style={{ height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span>Adicionar documento</span>
            </div>
        </Uploader>

        <Uploader
            fileList={fileList.anexo}
            draggable
            accept="all"
            autoUpload={false}
            removable={true}
            onRemove={(file) => 
                fileList.anexo.filter((item) => item !== file).map((item) => setFileList({...fileList, anexo: item}))
            }
            onChange={(e) => { setFileList({...fileList, anexo:[...e]}) }}
        >

            <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span>Adicionar anexos</span>
            </div>
        </Uploader>
        </>
    )


    const handleSubmit = () => {
        if(tipo === 'reserva'){
            handleUpdateReserva(id)
        }else{
            if(step === 0){
                setStep(1)
            }else{
                handlePostDocument(id)
            }
        }
    }


    return (
        <Modal open={showModal} overflow={true} size="md" onClose={() => {setFormData(null); setShowModal(false)}}>
            <Modal.Header>
                <Modal.Title>{tipo === 'reserva' ? 'Concluir Reserva' : 'Novo Documento'}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', maxHeight: 400 }}>
                {loader && (
                    <div style={{position: 'absolute', left: 0, botton: 0, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <Progress.Line percent={uploadProgress} status={uploadProgress === 100 ? 'success' : 'active'} strokeColor={uploadProgress === 100 ? '#52c41a' : '#3385ff'} />
                    </div>
                )}
                <div style={{ width: '100%', padding: 5, marginTop: 20, overflowY: 'scroll' }} className="no-scrollbar">
                    {tipo === 'reserva' && (
                        <>
                        {renderUploadFile()}
                        </>
                    )}
                    {tipo === 'documento' && (
                        <>
                        {step === 0 && (
                            <>
                            <Form fluid>
                            <Form.Group controlId="name-1">
                            <Form.ControlLabel className="form-label">Titulo</Form.ControlLabel>
                            <Form.Control 
                                className="form-item" 
                                name="name" 
                                onChange={(e) => setFormData({...formData, codigo: e})}
                                value={formData?.codigo || ''}
                                />
                            </Form.Group>
                            <Form.Group controlId="desc">
                            <Form.ControlLabel className="form-label">Descrição</Form.ControlLabel>
                            <Form.Control 
                                className="form-item" 
                                name="descricao"
                                onChange={(e) => setFormData({...formData, descricao: e})}
                                value={formData?.descricao || ''} 
                                />
                            </Form.Group>
                            <Form.Group controlId="date">
                            <Form.ControlLabel className="form-label">Data de vencimento</Form.ControlLabel>
                            <Form.Control 
                                className="form-item"
                                type="date" 
                                name="date"
                                onChange={(e) => setFormData({...formData, data_vencimento: e})}
                                value={formData?.data_vencimento || ''} 
                                />
                            </Form.Group>
                            <Form.Group>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Form.ControlLabel className="form-label">
                                Privacidade do documento
                                </Form.ControlLabel>
                                <Form.HelpText tooltip className="helper-text" style={{zIndex: 3000}}>
                                Nivel de confidencialidade do documento
                                <br />
                                - Publico: Todos podem acessar
                                <br />
                                - Restrita: Apenas pessoas autorizadas
                                <br />
                                - Confidencial: Apenas pessoas pontuais
                                <br />
                                - Uso Interno: Todos da empresa

                                </Form.HelpText>
                            </div>
                            <Form.Control
                                name="privacidade"
                                className="form-item"
                                id="privacidade"
                                block
                                data={privacidade?.map(item => ({ label: item.label, value: item.value }))}
                                onSelect={(e)=>setFormData({...formData, privacidade: e})}
                                value={formData?.privacidade || ''}
                                accepter={InputPicker}
                                placeholder="Confidencialidade do documento"
                                menuStyle={{
                                maxHeight: "200px",
                                wordBreak: "break-word",
                                maxWidth: 200,
                                zIndex: 3000,
                                }}
                            />
                            </Form.Group>
                        </Form>
                            </>
                        )}
                        {step === 1 && (
                            <>
                            {renderUploadFile()}
                            </>
                        )}
                        </>
                    )}
                   
                </div>
            </Modal.Body>
            <Modal.Footer>
                {tipo === 'documento' && step === 1 && (
                    <Button appearance="primary" style={{backgroundColor: 'gray'}} onClick={() => setStep(0)}>
                        Voltar
                    </Button>
                )}
                <Button appearance="primary"  loading={loader} onClick={() => handleSubmit()}>
                    {tipo === 'reserva' ? 'Concluir Reserva' : step === 0 ? 'Próximo' : 'Criar Documento'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

